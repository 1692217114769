import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { ReactSVG } from 'react-svg';
import { documentStyles } from '../../../Styles/documentStyles';
import Images from '../../../Theme/Images';
import Rector from '../Rector';
import StaticRector from '../StaticRector';
import PoSoForm from './PoSoForm';

class CurrentDocumentPoSo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetImage: false,
    };
    this._areaElement = new Map();
    this._areaElementBack = new Map();
  }

  componentDidMount() {
    if (this.props.match.params['documentId'])
      this.props.documentStore.getDocumentDetails(
        this.props.match.params['documentId']
      );
    if (this.props.match.params['docId'])
      this.props.documentStore.getExtDocumentDetails(
        this.props.match.params['docId']
      );
    // if (this.props.location.documentId === undefined)
    //   this.props.history.push('/dashboard/drafts')
    this.props.documentStore.closeDocumentDrawer();
  }

  highlight = (event, index) => {
    const { currentDoc } = this.props.documentStore;
    this.fitPage();
    this.resetHighlight(false);
    // const node = this._areaElement.get(index);
    const { currentWidthImage, getAspectRatio } = this.props.imageStore;
    const imgHeight = currentWidthImage * getAspectRatio;
    this.props.imageStore.zoomIn();
    this.props.imageStore.startDrawPoint(
      currentDoc.nonTabularData[index].pts &&
        currentDoc.nonTabularData[index].pts[0] * currentWidthImage,
      currentDoc.nonTabularData[index].pts &&
        currentDoc.nonTabularData[index].pts[1] * imgHeight,
      currentDoc.nonTabularData[index].pts &&
        currentDoc.nonTabularData[index].pts[2] * currentWidthImage -
          currentDoc.nonTabularData[index].pts &&
        currentDoc.nonTabularData[index].pts[0] * currentWidthImage,
      currentDoc.nonTabularData[index].pts &&
        currentDoc.nonTabularData[index].pts[3] * imgHeight -
          currentDoc.nonTabularData[index].pts &&
        currentDoc.nonTabularData[index].pts[1] * imgHeight,
      {
        width:
          currentWidthImage - currentDoc.nonTabularData[index].pts &&
          currentDoc.nonTabularData[index].pts[0] * currentWidthImage,
        height:
          imgHeight - currentDoc.nonTabularData[index].pts &&
          currentDoc.nonTabularData[index].pts[1] * imgHeight,
      },
      currentDoc.nonTabularData[index].pts,
      { w: currentWidthImage, h: imgHeight },
      currentDoc.nonTabularData[index].confidence
    );
  };

  highlightTabularData = (event, index) => {
    const { currentPage } = this.props.documentStore;
    this.fitPage();
    this.resetHighlight(false);
    // const node = this._areaElement.get(index);
    const { currentWidthImage, getAspectRatio } = this.props.imageStore;
    const imgHeight = currentWidthImage * getAspectRatio;
    this.props.imageStore.zoomIn();
    this.props.imageStore.startDrawPoint(
      currentPage.tableData[index].pts[0] * currentWidthImage,
      currentPage.tableData[index].pts[1] * imgHeight,
      currentPage.tableData[index].pts[2] * currentWidthImage -
        currentPage.tableData[index].pts[0] * currentWidthImage,
      currentPage.tableData[index].pts[3] * imgHeight -
        currentPage.tableData[index].pts[1] * imgHeight,
      {
        width:
          currentWidthImage -
          currentPage.tableData[index].pts[0] * currentWidthImage,
        height: imgHeight - currentPage.tableData[index].pts[1] * imgHeight,
      },
      currentPage.tableData[index].pts,
      { w: currentWidthImage, h: imgHeight }
    );
  };

  highlightBack = (event, index) => {
    const { currentDoc } = this.props.documentStore;
    this.resetHighlight(false);
    const node = this._areaElementBack.get(index);
    const { currentWidthImage, getAspectRatioBack } = this.props.imageStore;
    const imgHeight = currentWidthImage * getAspectRatioBack;

    if (node.props.newlyCreated) {
      this.props.imageStore.startDrawPoint(
        currentDoc.nonTabularData[index].pts[0],
        currentDoc.nonTabularData[index].pts[1],
        currentDoc.nonTabularData[index].pts[2],
        currentDoc.nonTabularData[index].pts[3],
        { width: currentWidthImage, height: imgHeight },
        currentDoc.nonTabularData[index],
        { w: currentWidthImage, h: imgHeight }
      );
    } else {
      this.props.imageStore.startDrawPoint(
        currentDoc.nonTabularData[0] * currentWidthImage,
        currentDoc.nonTabularData[index].pts[1] * imgHeight,
        currentDoc.nonTabularData[index].pts[2] * currentWidthImage -
          currentDoc.nonTabularData[index].pts[0] * currentWidthImage,
        currentDoc.nonTabularData[index].pts[3] * imgHeight -
          currentDoc.nonTabularData[index].pts[1] * imgHeight,
        { width: currentWidthImage, height: imgHeight },
        currentDoc.nonTabularData[index],
        { w: currentWidthImage, h: imgHeight }
      );
    }
  };

  resetHighlight(reset) {
    this.setState({
      resetImage: reset,
    });
  }

  removeHighlight = (event, index) => {
    this.resetHighlight(true);
    // const node = this._areaElement.get(index);
    this.props.imageStore.eraseDrawPoint();
  };

  openDropDown = () => {
    this.props.imageStore.openDocumentDrawer();
  };

  zoomIn = () => {
    // if (this.props.documentStore.currentField._id !== undefined)
    this.props.imageStore.zoomIn();
  };

  zoomOut = () => {
    // if (this.props.documentStore.currentField._id !== undefined)
    this.props.imageStore.zoomOut();
  };

  fitPage = () => {
    if (this.props.documentStore.currentField._id !== undefined)
      this.props.imageStore.fitPage();
  };

  downloadDoc = () => {
    const { currentDoc } = this.props.documentStore;
    this.props.documentStore.downloadOriginalDoc(currentDoc._id);
  };
  render() {
    const { classes } = this.props;
    const { currentPage, currentDoc, inProgress, currentField } =
      this.props.documentStore;

    if (inProgress)
      return (
        <div className={classes.loaderDiv}>
          <Loader
            height={100}
            width={100}
            type="Oval"
            color={this.props.theme.palette.purple}
            className={classes.loading}
            timeout={100000}
          />
        </div>
      );

    return currentDoc.pageArray && currentDoc.pageArray.length ? (
      <div className={'fullHeight rowFlex'}>
        <PoSoForm
          resetImage={this.fitPage}
          highlight={this.highlight}
          removeHighlight={this.removeHighlight}
          highlightTabularData={this.highlightTabularData}
          fromDoc={this.props.match.params.docId}
        ></PoSoForm>
        <div className={classes.invoiceContainer}>
          {/* <DocumentNavbar
            type="poso"
            resetImage={this.fitPage}
          ></DocumentNavbar> */}
          <div
            className={classes.imageDiv}
            // style={{ height: "calc(100% - 4vh)" }}
          >
            <SizeMe>
              {({ size }) => (
                <div
                  className={classes.imageContainer}
                  id="rector-container"
                  style={{
                    height: 'inherit',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {/*<img style={{maxWidth: '100%'}} src={currentPage.pageImageLink}/>*/}
                  {currentField._id ? (
                    <Rector
                      type="poso"
                      resetImage={false}
                      imageContainerWidth={size.width}
                      imageContainerHeight={
                        (currentPage.dimension.height /
                          currentPage.dimension.width) *
                        size.width
                      }
                      width={currentPage.dimension.width}
                      height={currentPage.dimension.height}
                      aspectRatio={
                        currentPage.dimension.height /
                        currentPage.dimension.width
                      }
                      imageSrc={currentPage.pageImageLink}
                      onSelected={this.onSelected}
                    />
                  ) : (
                    <StaticRector
                      type="poso"
                      resetImage={false}
                      imageContainerWidth={size.width}
                      imageContainerHeight={
                        (currentPage.dimension.height /
                          currentPage.dimension.width) *
                        size.width
                      }
                      width={currentPage.dimension.width}
                      height={currentPage.dimension.height}
                      aspectRatio={
                        currentPage.dimension.height /
                        currentPage.dimension.width
                      }
                      imageSrc={currentPage.pageImageLink}
                      onSelected={this.onSelected}
                    />
                  )}
                  <div className={classes.pageNo}>
                    Page {currentPage.pageNo}
                  </div>

                  {currentField._id ? (
                    <div className={classes.zoomContainer}>
                      <ReactSVG
                        beforeInjection={(svg) => {
                          svg.classList.add(classes.svg);
                        }}
                        onClick={(e) => this.zoomIn()}
                        src={Images.zoomIn}
                      />
                      <ReactSVG
                        beforeInjection={(svg) => {
                          svg.classList.add(classes.svg);
                        }}
                        onClick={(e) => this.zoomOut()}
                        src={Images.zoomOut}
                      />
                      <ReactSVG
                        disabled
                        beforeInjection={(svg) => {
                          svg.classList.add(classes.svg);
                        }}
                        onClick={(e) => this.downloadDoc()}
                        src={Images.docDownload}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </SizeMe>
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default withStyles(documentStyles, { withTheme: true })(
  withRouter(
    inject('documentStore', 'imageStore')(observer(CurrentDocumentPoSo))
  )
);
