import React, { useEffect, useState } from 'react';
import finkraftLogo from '@/assets/logos/finkraft-logo.svg';
import back_arrow from '@/assets/images/arrow_back.svg';
import LoginImg from '@/assets/images/loginImg.png';
import './login.css';
import auth from '@/services/authService';
import { useHistory, useLocation } from 'react-router';
import { getSapOrganizationGstins } from '../../apis/gstin';
import { useStore } from 'react-redux';

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateMobile(phone) {
  const re = /^[1-9][0-9]{9}$/;
  return re.test(String(phone).toLowerCase());
}
const Step1 = (props) => {
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <div>
      <img src={finkraftLogo} alt="logo" />
      <h3>Hi, Welcome back!</h3>
      <p className="mb2">Please sign in to your Finkraft account.</p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          setLoading(true);
          let result;
          try {
            if (validateEmail(userName)) {
              result = await auth.emailLoginStart({ email: userName });
            }
            if (validateMobile(userName)) {
              result = await auth.smsLoginStart({ phoneNumber: userName });
            }
            if (!validateMobile(userName) && !validateEmail(userName)) {
              setLoading(false);
              alert('Enter valid email or mobile number');
            }
            if (result) {
              setLoading(false);
              props.setPhone(userName);
              props.setStep(2);
            }
          } catch (err) {
            setLoading(false);
            alert('Enter valid username');
          }
        }}
      >
        <div className="input-wrap">
          <label>Login ID</label>
          <input
            type="text"
            value={userName}
            placeholder="Email ID or Mobile Number"
            onChange={(e) => setUserName(e.target.value)}
          />
          {/* <div className="flex-center">
          <input type="checkbox" />
          <span className="terms">
            I Agree to the <span className="link">Terms and Conditions</span>
          </span>
        </div> */}
        </div>
        <br />
        <button className="proceed" disabled={loading} type="submit">
          {loading ? 'Processing...' : 'Proceed'}
        </button>
      </form>
      <br />
      <br />
      <p className="have-acc">Do not have a user Account?</p>
      <button
        className="signup-btn"
        onClick={() => {
          history.push('/signup');
        }}
      >
        SignUp
      </button>
    </div>
  );
};

const Step2 = (props) => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <div className="step2">
      <img src={finkraftLogo} alt="logo" />
      <h3>Verification Code</h3>
      <p className="mb2">
        Please type the verification code sent to your mobile number{' '}
        <strong>
          {validateEmail(props.phone) ? props.phone : '+91 ' + props.phone}
        </strong>
      </p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            let result;
            if (props.id && props.reqToken) {
              localStorage.setItem(
                'referrer',
                `/login?id=${props.id}&reqToken=${props.reqToken}&step=3`
              );
            }
            if (validateEmail(props.phone)) {
              result = await auth.emailLoginVerify({
                email: props.phone,
                verificationCode: otp,
              });
            } else {
              result = await auth.smsLoginVerify({
                phoneNumber: props.phone,
                verificationCode: otp,
              });
            }
            if (result) {
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
            alert('Invalid OTP');
          }
        }}
      >
        <div className="input-wrap">
          <label>Enter OTP</label>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        <br />
        <button className="proceed" disabled={loading} type="submit">
          {loading ? 'Processing...' : 'Submit'}
        </button>
      </form>
      <br />
      <br />
      <span
        className="changeMb"
        onClick={() => {
          props.setStep(1);
        }}
      >
        <img src={back_arrow} alt="back_arrow" />
        Change Mobile Number
      </span>
      {/* <br />
  <br />
  <p className="have-acc">Do not have a user Account?</p>
  <button className="signup-btn">SignUp</button> */}
    </div>
  );
};

const Step3 = (props) => {
  return (
    <div className="step2">
      <img src={finkraftLogo} alt="logo" />
      {props.success ? (
        <>
          <h3 className="mb2">
            Your account is successfully linked with the Finkraft account.
          </h3>
          <p className="mb2">
            Sit back and relax, Expenses will be automatically pushed to
            Finkraft portal for reconciliation and to generate claim report.
          </p>
        </>
      ) : (
        <h3 className="mb2">
          Sorry, there is an issue in connecting to your account. Please contact
          finkraft administrator.
        </h3>
      )}

      {props.success ? (
        <a
          href="https://qr.finkraft.ai"
          rel="noreferrer noopener"
          target="_blank"
          className="proceed flex-center"
        >
          Access Dashboard
        </a>
      ) : (
        <a
          href="https://finkraft.ai/contact-us"
          rel="noreferrer noopener"
          target="_blank"
          className="proceed flex-center"
        >
          Contact Us
        </a>
      )}
    </div>
  );
};
const Login = () => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [success, setSuccess] = useState('');
  const [invoice, setInvoice] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const store = useStore();
  const history = useHistory();

  useEffect(() => {
    if (query.get('id') && query.get('requestToken')) {
      let invoice = query.get('invoice');
      history.push('/sapLogin', {
        id: query.get('id'),
        reqToken: query.get('requestToken'),
        invoice: invoice ? invoice : false,
      });
    }
    if(query.get('invoice')){
      setInvoice(true)
    }
    const callApi = async () => {
      let auth = store.getState()?.auth;
      if (query.get('step')) {
        try {
          let invoice = query.get('invoice');
          let res = await getSapOrganizationGstins(
            invoice
              ? {
                  user_name: auth?.auth0User?.email,
                  sap_username: query.get('id'),
                  sap_password: query.get('reqToken'),
                  invoice,
                }
              : {
                  user_name: auth?.auth0User?.email,
                  sap_username: query.get('id'),
                  sap_password: query.get('reqToken'),
                }
          );
          localStorage.clear();
          if (res) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        } catch (err) {
          localStorage.clear();
          setSuccess(false);
        }
        setStep(3);
      }
    };

    callApi();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-grid login-sec">
      <div className="item1 mAuto">
        <div className="login-right">
          {step === 1 && <Step1 setStep={setStep} setPhone={setPhone} />}
          {step === 2 && (
            <Step2
              setStep={setStep}
              phone={phone}
              id={query.get('id')}
              reqToken={query.get('requestToken')}
            />
          )}
          {step === 3 && <Step3 success={success} />}
        </div>
      </div>
      <div className="item2">
        <div className="login-left">
          <div className="login-text">
            <h3>
              Finkraft GST Software – Simplify your GST Reconciliation and know
              about your ITC
            </h3>
            <div className="bar"></div>
            <p>
              {invoice ? `Finkraft.ai makes reconciliation simple by automatically matching your purchase transactions,
GST filing data and vendor invoices. All you have to do is to view the reconciliation data and
download the Credit Reports.`:`Finkraft.ai makes reconciliation simple by automatically matching your purchase transactions,
GST filing data and vendor expense invoices. All you have to do is to view the reconciliation
data and download the Credit Reports.`}
              {/* Finkraft GST makes reconciliation simple by automatically matching
              your purchase transactions with your GSTR 2A and validate the
              vendor e-invoices. All you have to do is to view the
              reconciliation data and download the Credit Reports. */}
            </p>
            <img src={LoginImg} alt={'login-img'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
