export const SET_LOGGEDIN_USER = 'SET_LOGGEDIN_USER';
export const SET_GSTN_USER = 'SET_GSTN_USER';
export const SET_SINGLE_GSTN_USER = 'SET_SINGLE_GSTN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const IS_VALID_TOKEN = 'IS_VALID_TOKEN';

export const setLoggedInUser = (payload) => ({
  type: SET_LOGGEDIN_USER,
  payload,
});

export const setGstnUser = (payload) => ({
  type: SET_GSTN_USER,
  payload,
});

export const setSingleGstnUser = (payload) => ({
  type: SET_SINGLE_GSTN_USER,
  payload,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const isValidToken = (bool) => ({
  type: IS_VALID_TOKEN,
  payload: bool,
});
