import { useReducer, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Formik, Form, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import useErrorToast from '@/hooks/useErrorToast';
import useSuccessToast from '@/hooks/useSuccessToast';

import { FlexContainer, FlexRowContainer } from '@/components/Themed';
import { FormContainer, FormRow } from '@/components/FormUIElements';
import { FormTextInput } from '@/components/FormComponents';
import EmailVerification from '@/components/signup/EmailVerification';
import MobileNumberVerification from '@/components/signup/MobileNumberVerification';
import GstinVerification from '@/components/signup/GstinVerification';
import ButtonsRow from '@/components/signup/ButtonsRow';

import { userSignupValidationSchema } from '@/utils/schemaValidations';
import { getOtp, verifyOtp, registerNewUser } from '@/apis/signup';
import { getGstinInfo } from '@/apis/gstin';

import finkraftLogo from '@/assets/logos/finkraft-logo.svg';

const SignupContainerWrapper = styled(FlexContainer.withComponent('div'))`
  justify-content: center;
  align-items: center;

  & #signup-card {
    & > h1 {
      width: 460px;
      height: auto;

      font-size: 34px;
      line-height: 44px;
      font-weight: 700;
      color: #1c1d21;
      white-space: pre-line;

      margin-left: 50px;
    }

    & > p {
      font-size: 14px;
      line-height: 21px;

      color: #8181a5;
      margin-left: 50px;
      margin-bottom: 20px;
    }
  }

  & ${FlexRowContainer} {
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  & ${FormRow} {
    justify-content: flex-start;
    align-items: flex-end;

    & > div {
      margin-right: 30px;
    }

    &#gstin-verification-row {
      margin-bottom: 40px;
    }

    &#buttons-row {
      justify-content: center;

      & > #submit-button {
        margin-right: 20px;
      }
    }
  }

  .bp3-button {
    margin-bottom: 2px;
  }
`;

const initialValues = {
  fullName: '',
  department: '',
  emailId: '',
  emailOtp: '',
  mobileNumber: '',
  smsOtp: '',
  organisationName: '',
  gstin: '',
};

const initialState = {
  uuid: uuidv4(),
  sendingEmailOtp: false,
  emailOtpSent: false,
  sendingSmsOtp: false,
  smsOtpSent: false,
  verifyingEmailOtp: false,
  emailOtpVerified: false,
  verifyingSmsOtp: false,
  smsOtpVerified: false,
  verifyingGstin: false,
  gstinVerified: false,
  submittingForm: false,
  formSubmitted: false,
  errorMsg: null,
};

const signupReducer = (state, action) => {
  switch (action.type) {
    case 'send-email-otp':
      return { ...state, sendingEmailOtp: true };

    case 'send-email-otp-success':
      return { ...state, sendingEmailOtp: false, emailOtpSent: true };

    case 'send-email-otp-failure':
      return { ...state, sendingEmailOtp: false, ...action.payload };

    case 'send-sms-otp':
      return { ...state, sendingSmsOtp: true };

    case 'send-sms-otp-success':
      return { ...state, sendingSmsOtp: false, smsOtpSent: true };

    case 'send-sms-otp-failure':
      return { ...state, sendingSmsOtp: false, ...action.payload };

    case 'verify-email-otp':
      return { ...state, verifyingEmailOtp: true };

    case 'verify-email-otp-success':
      return { ...state, verifyingEmailOtp: false, emailOtpVerified: true };

    case 'verify-email-otp-failure':
      return { ...state, verifyingEmailOtp: false, ...action.payload };

    case 'verify-sms-otp':
      return { ...state, verifyingSmsOtp: true };

    case 'verify-sms-otp-success':
      return { ...state, verifyingSmsOtp: false, smsOtpVerified: true };

    case 'verify-sms-otp-failure':
      return { ...state, verifyingSmsOtp: false, ...action.payload };

    case 'verify-gstin':
      return { ...state, verifyingGstin: true };

    case 'verify-gstin-success':
      return { ...state, verifyingGstin: false, gstinVerified: true };

    case 'verify-gstin-failure':
      return { ...state, verifyingGstin: false, ...action.payload };

    case 'submit-form':
      return { ...state, submittingForm: true };

    case 'submit-form-success':
      return { ...state, submittingForm: false, formSubmitted: true };

    case 'submit-form-failure':
      return { ...state, submittingForm: false, ...action.payload };

    case 'reset-form':
      return { ...initialState, emailId: state.emailId, uuid: uuidv4() };

    default:
      throw new Error();
  }
};

const SignupContainer = (props) => {
  const [state, dispatch] = useReducer(signupReducer, initialState);

  const { showToast: showErrorToast } = useErrorToast();
  const { showToast: showSuccessToast } = useSuccessToast();

  const {
    uuid,
    sendingEmailOtp,
    emailOtpSent,
    sendingSmsOtp,
    smsOtpSent,
    verifyingEmailOtp,
    emailOtpVerified,
    verifyingSmsOtp,
    smsOtpVerified,
    submittingForm,
    formSubmitted,
    verifyingGstin,
    gstinVerified,
    errorMsg,
  } = state;

  useEffect(() => {
    if (errorMsg) {
      showErrorToast({ message: errorMsg });
    }
  }, [errorMsg, showErrorToast]);

  const handleGetEmailOtp = async (emailId) => {
    try {
      dispatch({ type: 'send-email-otp' });
      const { data } = await getOtp({ emailId });
      if (data.message) {
        dispatch({ type: 'send-email-otp-success' });
      } else {
        throw new Error('OTP could not be sent');
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'send-email-otp-failure',
        payload: { errorMsg: error.message || error },
      });
    }
  };

  const handleVerifyEmailOtp = async (emailId, otp) => {
    try {
      dispatch({ type: 'verify-email-otp' });
      const { data } = await verifyOtp({ emailId, otp });
      if (data.isVerified) {
        showSuccessToast({ message: 'Email has been successfully verified' });
        dispatch({ type: 'verify-email-otp-success' });
      } else {
        throw new Error('Could not verify otp');
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'verify-email-otp-failure',
        payload: { errorMsg: error.message || error },
      });
    }
  };

  const handleGetSmsOtp = async (mobileNumber) => {
    try {
      dispatch({ type: 'send-sms-otp' });
      const { data, status } = await getOtp({ mobileNumber });
      if (status === 200) {
        dispatch({ type: 'send-sms-otp-success' });
      } else {
        throw new Error(JSON.stringify(data));
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'send-sms-otp-failure',
        payload: { errorMsg: error.message || error },
      });
    }
  };

  const handleVerifySmsOtp = async (mobileNumber, otp) => {
    try {
      dispatch({ type: 'verify-sms-otp' });
      const { data } = await verifyOtp({ mobileNumber, otp });
      if (data.isVerified) {
        showSuccessToast({
          message: 'Mobile Number has been successfully verified',
        });
        dispatch({ type: 'verify-sms-otp-success' });
      } else {
        throw new Error(JSON.stringify(data));
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'verify-sms-otp-failure',
        payload: { errorMsg: error.message || error },
      });
    }
  };

  const handleVerifyGstin = async (gstin) => {
    try {
      dispatch({ type: 'verify-gstin' });
      const { data } = await getGstinInfo({ gstin });
      if (data.status_code === 1) {
        dispatch({ type: 'verify-gstin-success' });
      } else {
        throw new Error(JSON.stringify(data));
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'verify-gstin-failure',
        payload: { errorMsg: error.message || error },
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      dispatch({ type: 'submit-form' });
      delete values.emailOtp;
      delete values.smsOtp;

      const updatedValues = {
        ...values,
      };
      await registerNewUser({ form: updatedValues });
      dispatch({ type: 'submit-form-success' });
      setTimeout(() => {
        dispatch({ type: 'reset-form' });
        props.history.push(`/login?emailId=${values.emailId}`);
      }, 500);
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'submit-form-failure',
        payload: { errorMsg: error.message || error },
      });
    }
  };

  return (
    <SignupContainerWrapper>
      <div id="signup-card">
        <div style={{ margin: "1rem 2.5rem" }}>
          <img src={finkraftLogo} alt="logo" />
          <h3>Welcome to FinKraft! Sign-up to Start Saving</h3>
          <p className="mb2">Enter your details to proceed further</p>
        </div>
        <Formik
          key={uuid}
          initialValues={initialValues}
          validationSchema={userSignupValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={handleSubmit}
        >
          {({ submitForm, resetForm }) => (
            <Form>
              <FormContainer>
                <FormRow>
                  <Field
                    name="fullName"
                    label="Full Name"
                    component={FormTextInput}
                  />
                  <Field
                    name="department"
                    label="Department"
                    component={FormTextInput}
                  />
                </FormRow>
                <EmailVerification
                  sendingEmailOtp={sendingEmailOtp}
                  emailOtpSent={emailOtpSent}
                  verifyingEmailOtp={verifyingEmailOtp}
                  emailOtpVerified={emailOtpVerified}
                  handleGetEmailOtp={handleGetEmailOtp}
                  handleVerifyEmailOtp={handleVerifyEmailOtp}
                />
                <MobileNumberVerification
                  sendingSmsOtp={sendingSmsOtp}
                  smsOtpSent={smsOtpSent}
                  verifyingSmsOtp={verifyingSmsOtp}
                  smsOtpVerified={smsOtpVerified}
                  handleGetSmsOtp={handleGetSmsOtp}
                  handleVerifySmsOtp={handleVerifySmsOtp}
                />
                <GstinVerification
                  id="gstin-verification-row"
                  verifyingGstin={verifyingGstin}
                  gstinVerified={gstinVerified}
                  handleVerifyGstin={handleVerifyGstin}
                />
                <ButtonsRow
                  id="buttons-row"
                  formSubmitted={formSubmitted}
                  submittingForm={submittingForm}
                  emailOtpVerified={emailOtpVerified}
                  smsOtpVerified={smsOtpVerified}
                  gstinVerified={gstinVerified}
                  submitForm={submitForm}
                  resetForm={resetForm}
                  dispatch={dispatch}
                />
              </FormContainer>
            </Form>
          )}
        </Formik>
      </div>
    </SignupContainerWrapper>
  );
};

export default SignupContainer;
