import { SHOW_LOADER, HIDE_LOADER } from '@/actions/loaderActions';

const loaderReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return true;

    case HIDE_LOADER:
      return false;

    default:
      return state;
  }
};

export default loaderReducer;
