import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from '../../Styles/commonStyles';
import InvoiceTable from './InvoiceTable';
import { ReactSVG } from 'react-svg';
import Images from '../../Theme/Images';
import SimpleButton from '../../UIComponents/SimpleButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { sortItems } from '../../utils/utils';
import UploadFilesDialog from '../InvoiceUpload.js/UploadFilesDialog';
import clsx from 'clsx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';

class InvoiceListing extends Component {
  state = {
    heading: '',
    showPreview: false,
    currentDoc: {},
    hideStatus: false,
    noDocMessage: '',
    selectAll: false,
    openDeleteDialog: false,
    docToDelete: {},
    pagination: {
      low: 0,
      high: 6,
      page: 0,
    },
    timeField: 'createdAt',
    scanStatus: { name: 'All', value: 'ALL' },
    eInvoice: { name: 'All', value: 'ALL' },
    confidenceScore: { name: 'All', value: 'ALL' },
    reviewStatus: { name: 'All', value: 'ALL' },
    searchQuery: '',
    sortField: 'updatedAt',
    sortDirection: '-1',
    sortAnchor: null,
    reviewAnchor: null,
  };

  renderFooter = () => {
    const { classes } = this.props;
    const { exportProgress, selectAllDocs, selectedDocs, deleteProgress } =
      this.props.dashboardStore;
    return (
      <div className={classes.tableFooter}>
        <div className="rowFlex">
          <div
            className={clsx({
              [classes.sortBy]: true,
              [classes.exportDisabled]:
                !selectAllDocs && selectedDocs.findIndex((x) => x === true) < 0,
            })}
            onClick={
              !exportProgress
                ? this.props.dashboardStore.exportDocuments
                : undefined
            }
          >
            <div className="rowFlex">
              <GetAppIcon fontSize="small" />
              &nbsp;&nbsp;
              <div className="colFlex flexCentered">
                {exportProgress ? 'Exporting...' : 'Export to Excel'}
              </div>
            </div>
          </div>
          <div
            className={clsx({
              [classes.sortBy]: true,
              [classes.exportDisabled]:
                !selectAllDocs && selectedDocs.findIndex((x) => x === true) < 0,
            })}
            onClick={
              !deleteProgress
                ? this.props.dashboardStore.deleteMultipleDocs
                : undefined
            }
          >
            <div className="rowFlex">
              <DeleteOutlineIcon fontSize="small" />
              &nbsp;&nbsp;
              <div className="colFlex flexCentered">
                {deleteProgress ? 'Deleting...' : 'Delete'}
              </div>
            </div>
          </div>
        </div>

        {this.renderPagination()}
      </div>
    );
  };

  onClickUpload = () => {
    this.props.dashboardStore.openUpload('', '', 'FINANCE');
  };

  renderPagination = () => {
    const { totalDocs, pagination } = this.props.dashboardStore;
    const { classes } = this.props;
    let count = totalDocs;
    let temp = pagination.high;
    if (count <= temp) temp = count;
    let pages = Math.ceil(totalDocs / 6);
    return (
      <div className={classes.pagination}>
        <div className="colFlex flexCentered">
          Showing {pagination.low + 1} to {temp} of {count}
        </div>
        &nbsp;&nbsp;
        <div className="rowFlex">
          <div className={classes.page}>
            <ReactSVG
              src={Images.prev}
              className={classes.pagnSymbols}
              onClick={(e) => this.getPage('prev')}
            />
          </div>
          {pagination.page - 1 >= 0 ? (
            <div
              className={`${classes.page} ${classes.pageNo}`}
              onClick={(e) => this.goToPage(pagination.page - 1)}
            >
              {pagination.page}
            </div>
          ) : null}
          <div
            className={`${classes.page} ${classes.pageNo} ${classes.currentPage}`}
            onClick={(e) => this.goToPage(pagination.page)}
          >
            {pagination.page + 1}
          </div>
          {pagination.page + 1 < pages ? (
            <div
              className={`${classes.page} ${classes.pageNo}`}
              onClick={(e) => this.goToPage(pagination.page + 1)}
            >
              {pagination.page + 2}
            </div>
          ) : null}
          {pagination.page - 1 < 0 && pagination.page + 2 < pages ? (
            <div
              className={`${classes.page} ${classes.pageNo}`}
              onClick={(e) => this.goToPage(pagination.page + 2)}
            >
              {pagination.page + 3}
            </div>
          ) : null}

          <div className={classes.page}>
            {' '}
            <ReactSVG
              src={Images.next}
              className={classes.pagnSymbols}
              onClick={(e) => this.getPage('next')}
            />
          </div>
        </div>
      </div>
    );
  };

  getPage = (type) => {
    const { totalDocs, pagination } = this.props.dashboardStore;

    if (type === 'prev' && pagination.page - 1 > -1) {
      const temp = Object.assign({}, pagination, {
        low: pagination.low - 6,
        high: pagination.high - 6,
        page: pagination.page - 1,
      });
      this.props.dashboardStore.setPageNumber(temp.page);
      this.props.dashboardStore.setPagination(temp);
      this.props.dashboardStore.getDocumentList();
    } else if (
      type === 'next' &&
      !(Math.ceil(totalDocs / 6) * 6 === pagination.high)
    ) {
      const temp = Object.assign({}, pagination, {
        low: pagination.low + 6,
        high: pagination.high + 6,
        page: pagination.page + 1,
      });
      this.props.dashboardStore.setPageNumber(temp.page);
      this.props.dashboardStore.setPagination(temp);
      this.props.dashboardStore.getDocumentList();
    }
  };

  goToPage = (pageNo) => {
    const { pagination } = this.props.dashboardStore;
    const temp = Object.assign({}, pagination, {
      low: (pageNo + 1) * 6 - 6,
      high: (pageNo + 1) * 6,
      page: pageNo,
    });
    this.props.dashboardStore.setPagination(temp);
    this.props.dashboardStore.setPageNumber(pageNo);
    this.props.dashboardStore.getDocumentList(this.state);
  };

  componentDidMount() {
    this.props.dashboardStore.resetSearch();
    this.setState({
      noDocMessage: 'No invoices found! Click on Upload to begin.',
    });
    this.props.dashboardStore.setPageNumber(0);

    // let gstn = this.props.location?.state?.singleGstin
    //   ? this.props.location?.state?.singleGstin
    //   : 'ALL';

    // this.props.dashboardStore.handleGstnFilterChange(gstn)
    this.props.dashboardStore.getDocumentList();
  }

  handleCloseMenu = (type, value) => {
    switch (type) {
      case 'sort':
        this.props.dashboardStore.setSortValues(
          'sort',
          null,
          value ? value : 'updatedAt'
        );
        this.props.dashboardStore.getDocumentList();
        break;
      case 'review':
        this.props.dashboardStore.setSortValues(
          'review',
          null,
          value ? value : 'updatedAt'
        );
        this.props.dashboardStore.getDocumentList();
        break;
      default:
        break;
    }
  };

  handleSearchKeyDown = (e) => {
    const { searchQuery } = this.props.dashboardStore;
    if (e.which === 13) {
      if (searchQuery.replace(/\s/g, '').length >= 3)
        this.props.dashboardStore.getDocumentList(this.state);
      else
        this.props.commonStore.handleOpenSnackBar(
          'Enter atleast three characters to search!',
          'info'
        );
    }
  };

  onClickSearch = () => {
    const { searchQuery } = this.props.dashboardStore;
    if (searchQuery.replace(/\s/g, '').length >= 3)
      this.props.dashboardStore.getDocumentList(this.state);
    else
      this.props.commonStore.handleOpenSnackBar(
        'Enter atleast three characters to search!',
        'info'
      );
  };

  onClickClearSearch = () => {
    this.props.dashboardStore.handleSearchChange('');
    this.props.dashboardStore.getDocumentList(this.state);
  };

  renderHeader = () => {
    const { classes } = this.props;
    const { sortAnchor, sortField, searchQuery } =
      this.props.dashboardStore;
    return (
      <div className={classes.header}>
        <div style={{ flex: '1' }}></div>
        <div className={classes.headerRight}>
          <SimpleButton
            handleClick={this.onClickUpload}
            label={
              <div className="rowFlex">
                +&nbsp;&nbsp;Upload Invoice&nbsp;&nbsp;
                <ReactSVG
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  src={Images.uploadDropDown}
                />
              </div>
            }
          />
          <div className={classes.sortBy} style={{ marginRight: '4px' }}>
            <div className={classes.searchContainer}>
              <div class="input-hidden-label" aria-hidden="true">
                Search by ID, Name, Status-ddd
              </div>
              <input
                onKeyDown={(e) => this.handleSearchKeyDown(e)}
                id="searchInputVision"
                value={searchQuery}
                type="text"
                className={classes.searchInput}
                placeholder="Search by ID, Name, Status"
                onChange={(e) =>
                  this.props.dashboardStore.handleSearchChange(e.target.value)
                }
              />
            </div>
            <div className={classes.searchIcon}>
              <ReactSVG
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
                src={
                  searchQuery.replace(/\s/g, '').length >= 3
                    ? Images.close
                    : Images.search
                }
                onClick={
                  searchQuery.replace(/\s/g, '').length >= 3
                    ? this.onClickClearSearch
                    : this.onClickSearch
                }
              />
            </div>
          </div>
          <div
            className={classes.sortBy}
            onClick={(e) =>
              this.props.dashboardStore.setSortValues('sort', e.currentTarget)
            }
          >
            <div>Sort By</div>&nbsp;&nbsp;
            <ReactSVG
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
              src={Images.sort}
            />
          </div>
          <Menu
            id="sort-menu"
            anchorEl={sortAnchor}
            keepMounted
            open={Boolean(sortAnchor)}
            onClose={(e) => this.handleCloseMenu('sort')}
          >
            {sortItems.map((filter, fIndex) => (
              <MenuItem
                className={
                  filter.value === sortField
                    ? classes.selectedMenuItem
                    : classes.menuItem
                }
                onClick={(e) => this.handleCloseMenu('sort', filter.value)}
                value={filter.value}
              >
                {filter.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    );
  };

  handleFilterChange = (type, value) => {
    this.props.dashboardStore.setFilter(type, value);
    this.props.dashboardStore.getDocumentList(this.state);
  };

  render() {
    const { confidenceScore, eInvoice, reviewStatus, totalDocs } =
      this.props.dashboardStore;
    const { classes } = this.props;
    return (
      <div className={classes.listContainer}>
        <div>
          {this.renderHeader()}
          <InvoiceTable
            scanStatus={this.state.scanStatus}
            confidenceScore={confidenceScore}
            eInvoice={eInvoice}
            reviewStatus={reviewStatus}
            handleFilterChange={this.handleFilterChange}
          />
        </div>
        {totalDocs > 0 ? this.renderFooter() : null}
        <UploadFilesDialog />
      </div>
    );
  }
}

export default withStyles(tableStyles, { withTheme: true })(
  withRouter(inject('dashboardStore', 'commonStore')(observer(InvoiceListing)))
);
