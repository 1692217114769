import React, { useEffect, useState } from 'react';
import './GstPage.css';
import Calendar from 'assets/images/calendar.svg';
import GstLogin from 'assets/images/gstlogin-modal-logo.png';
import Info from 'assets/images/information.png';
import Close from 'assets/images/close.svg';
import WhiteNote from 'assets/images/file_white.svg';
import Table from './Table';
import { Dialog } from '@material-ui/core';
import Input from './Input';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { axiosInstance2 } from '@/apis/axiosInstance';
import moment from 'moment';
import spinner from 'assets/images/spinner.svg';
import DownloadIcon from 'assets/images/download-icon.png';

const IconLabel = (props) => {
  return (
    <div className={`icon-label ${props.size ? props.size : ''}`}>
      <span>
        <img src={Calendar} alt="icon" />
      </span>
      <div className="labels">
        <label className="title">{props.title}</label>
        <label className="content">{props.content}</label>
      </div>
    </div>
  );
};

const GstPage = () => {
  const [gstStep, setGstStep] = useState(1);
  const [loadingIdx, setLoadingIdx] = useState(false);
  const [gstPopup, setGstPopup] = useState(false);
  useEffect(() => {
    if (!gstPopup) {
      let timeout = setTimeout(() => {
        setGstStep(1);
        clearTimeout(timeout);
      }, 200);
    }
  }, [gstPopup]);
  const columns = [
    {
      header: '',
      className: 'brd_color',
      statusBrder: true,
    },
    {
      header: 'Supplier GSTN',
      className: 'supplier_gstn',
      accessor: 'supplierGstin',
    },
    {
      header: 'Supplier Name',
      className: 'supplier_name',
      accessor: 'supplierName',
    },
    {
      header: 'Invoice No',
      className: 'invoice_no',
      accessor: 'invoiceNumber',
    },
    {
      header: 'Total Invoice Value',
      className: 'total_invoice',
      accessor: 'total_invoice',
    },
    {
      header: 'Taxable Value',
      className: 'taxable_val',
      accessor: 'totalTax',
    },
    {
      header: 'Central Tax',
      className: 'central_tax',
      accessor: 'centralTax',
    },
    {
      header: 'State Tax',
      className: 'state_tax',
      accessor: 'stateTax',
    },
    {
      header: 'Total Tax',
      className: 'total_tax',
      accessor: 'totalTax',
    },
    {
      header: 'Filing Status',
      className: 'filing_status',
      accessor: 'filingStatus',
    },
  ];

  const gstCols = [
    {
      header: 'Month',
      className: 'month',
      accessor: 'returnPeriod',
      cell: (row) => moment(row?.returnPeriod, 'MMYYYY').format('MMM YYYY'),
    },
    {
      header: 'Document Type',
      className: 'doc_type',
      accessor: 'doc_type',
      cell: (row) => 'Sales Invoices',
    },
    {
      header: 'Status',
      className: 'status',
      accessor: 'status',
      cell: (row) => (row?.b2bData ? 'Downloaded' : 'Not Downloaded'),
    },
    {
      header: 'Last Updated',
      className: 'last_updated',
      accessor: 'last_updated',
      cell: (row) => (
        <div className="td w-45">
          <span className="flex">
            Last Update on{' '}
            {moment(
              row.modifiedDate ? row.modifiedDate : row.createdDate
            ).format('Do MMM, yyyy HH:mm a')}
            {loadingIdx[row._id] ? (
              <img
                src={spinner}
                alt="download-icon"
                width={28}
                style={{ marginLeft: 5 }}
              />
            ) : (
              <img
                src={DownloadIcon}
                alt="download-icon"
                width={14}
                // height={16}
                style={{
                  maxWidth: 'none',
                  display: 'inline-block',
                  height: '16px',
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setLoadingIdx({ [row._id]: true });
                  axiosInstance2
                    .post('/master/getB2bGstrData/' + formik.values.gstinId, {
                      gstin_number: formik.values?.gstin_number,
                      gstinId: formik.values.gstinId,
                      retPeriod: row.returnPeriod,
                    })
                    .then((res) => {
                      console.log(res);
                      setLoadingIdx({ ...loadingIdx, [row._id]: false });
                    })
                    .catch(() => {
                      setLoadingIdx({ ...loadingIdx, [row._id]: false });
                    });
                }}
              />
            )}
          </span>
        </div>
      ),
    },
  ];

  const singleGstn = useSelector((state) => state?.auth?.singleGstn);
  const [downloadStatusData, setDownloadStatusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState('');

  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance2
      .get(
        '/gstinInvoices/getInvoicesByAssociatedGstin/611519be8c57642c28f2e3d9/012021'
      )
      .then((res) => {
        setData(res.data);
      });
  }, []);
  const formik = useFormik({
    initialValues: {
      gstin_number: '29AACCJ7038E1ZJ' || singleGstn?.gstinNumber,
      gstinId: '611519be8c57642c28f2e3d9' || singleGstn?._id,
      gstin_password: '',
      gstin_userName: 'Touring_Talkies',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      axiosInstance2
        .post('master/generateOtp/' + values.gstinId, values)
        .then((res) => {
          if (res) {
            if (res?.data?.message === 'OTP not required') {
              setGstStep(3);
            } else {
              setGstStep(2);
            }
          }
        })
        .catch((err) => {
          alert(JSON.stringify(err.response));
        });
    },
  });
  return (
    <div className="gstPage m-2">
      <section className="head">
        <div className="flex-between gst-wrap">
          <div className="gst2a-head">
            <label className="gst-title">{'GST2A > 36AA76487HG65'}</label>
            <IconLabel title={'Financial Year'} content="2020-2021" />
            <IconLabel title={'Month'} content="All" />
          </div>
          <div className="btns-filters">
            <button>Upload GST2A</button>
            <button onClick={() => setGstPopup(true)}>Download GST2A</button>
            <input placeholder="Search by ID, Name, Status" />
          </div>
        </div>

        <div className="flex-between main-div">
          <div className="flex-center skew-label">
            <img src={WhiteNote} alt="white-note" />
            <label>GST 2A Summary</label>
          </div>
          <div className="flex-between w90 stats">
            <IconLabel title="Total No of Records" content="120" />
            <IconLabel
              title="Total Transaction Amount"
              content="Rs 1,98,00,000"
            />
            <IconLabel title="Taxable Amount" content="Rs 1,54,00,800" />
            <IconLabel title="Total IGST" content="Rs 64,00,800" />
            <IconLabel title="Total SGST" content="Rs 14,00,800" />
            <IconLabel title="Total CGST" content="Rs 44,00,800" />
          </div>
        </div>
      </section>
      <div className="m1 mt2">
        <Table columns={columns} data={data} />
      </div>
      <Dialog
        open={gstPopup}
        onClose={() => {
          setGstPopup(false);
        }}
        classes={{
          paperWidthSm: gstStep === 4 ? 'paper-width gstPage' : 'gstPage',
        }}
      >
        {gstStep !== 4 && (
          <header>
            <div className="flex-center w100">
              <img src={GstLogin} alt="gst-login" width={200} />
            </div>
            <img
              src={Close}
              className="close cursor"
              onClick={() => {
                setGstPopup(false);
              }}
              alt="close"
            />
          </header>
        )}
        {gstStep === 1 && (
          <div className="dialog-content">
            <h6>
              Enter your GST portal login credentials to connect Finkraft.
            </h6>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label={'GSTIN'}
                value={formik.values.gstin_number}
                readOnly={true}
              />
              <Input label="User Name" value={formik.values.gstin_userName} />
              <Input label="Password" />
              <div className="btn-wrap">
                <button className="cancel">Cancel</button>
                <button className="submitBtn" type="submit" disabled={loading}>
                  Login
                </button>
              </div>
            </form>
            <div className="note">
              <span>Note</span>
              <span>
                1. You have to enable the API access in the GSTIN portal by
                entering the OTP received on registered mobile no.
                <br />
                2.Once Finkraft connected with GSTIN, we will automatically pull
                the transactions.
              </span>
            </div>
          </div>
        )}
        {gstStep === 2 && (
          <div className="dialog-content">
            <h6>
              OTP has been successfully sent to the registered mobile number
              with GST portal.
            </h6>
            <div className="flex-center flex-col">
              <div className="details">
                <div>
                  <span>GSTIN :</span>
                  <span>6AZ7YTD62438684324</span>
                </div>
              </div>
              <div className="details">
                <div>
                  <span>User Name :</span>
                  <span>Purpleplum2020</span>
                </div>
              </div>
              <br />
              <br />
              <Input
                label={'One time password'}
                onChange={(e) => {
                  setOTP(e.target.value);
                }}
              />
              <span className="resend-note">
                Didn’t receive OTP yet?{' '}
                <span className="resend">Resend OTP</span>
              </span>
              <div className="btn-wrap">
                <button
                  className="submitBtn"
                  onClick={() => {
                    setLoading(true);
                    axiosInstance2
                      .post('/master/verifyOtp/' + formik.values.gstinId, {
                        gstinOtp: otp,
                        gstin_number: formik.values.gstin_number,
                        gstin_userName: formik.values.gstin_userName,
                      })
                      .then((res) => {
                        if (res) {
                          setGstStep(3);
                        }
                        setLoading(false);
                      })
                      .then((err) => {
                        setLoading(false);
                        alert(JSON.stringify(err.response));
                      });
                  }}
                  disabled={loading}
                >
                  Verify Otp
                </button>
              </div>
            </div>
            <div className="note">
              <span>Note</span>
              <span>
                1. The session will be active for a period of one month as per
                GST portal Policies. <br />
                2.Post one month a new OTP request need to be initiated and
                re-connect the session.
              </span>
            </div>
          </div>
        )}

        {gstStep === 3 && (
          <div className="dialog-content">
            <h6>You have successfully connected with the GSTN Portal.</h6>
            <div className="flex-center flex-col">
              <div className="details">
                <div>
                  <span>GSTIN :</span>
                  <span>6AZ7YTD62438684324</span>
                </div>
              </div>
              <div className="details">
                <div>
                  <span>User Name :</span>
                  <span>Purpleplum2020</span>
                </div>
              </div>
              <br></br>
              <br></br>
              {/* <img /> */}
              <span className="sit-back">
                Sit Back and Relax, We will auto download the current and last
                financial year GST2A data into the portal.
                <br></br>
                <br></br>
                Connected On - 17th June 2021, 10:00 AM
              </span>
              <div className="btn-wrap">
                <button
                  className="submitBtn"
                  onClick={() => {
                    axiosInstance2
                      .get('/configure/getConfigureByGstin/29AACCJ7038E1ZJ')
                      .then((res) => {
                        setDownloadStatusData(res.data);
                        setGstStep(4);
                      });
                  }}
                >
                  View Download Status
                </button>
              </div>
            </div>
            <div className="note">
              <span>Note</span>
              <span>
                1. The session will be active for a period of one month as per
                GST portal Policies. <br />
                2.Post one month a new OTP request need to be initiated and
                re-connect the session.
              </span>
            </div>
          </div>
        )}

        {gstStep === 4 && (
          <div className="dialog-content gst-download-table">
            <img
              src={Close}
              className="close cursor"
              onClick={() => {
                setGstPopup(false);
              }}
              alt={'close'}
            />

            <div className="flex-between mb1">
              <div className="d-flex" style={{ alignItems: 'center' }}>
                <label className="gst-title sm">
                  {'GST2A > 36AA76487HG65'}
                </label>
                <span className="connected">
                  <img src={Info} alt={'info'} width={17} />
                  Connected with GST2A portal.
                </span>
              </div>
              <div>
                <IconLabel
                  title={'Financial Year'}
                  content="2020-2021"
                  size="sm"
                />
              </div>
            </div>
            <Table
              columns={gstCols}
              data={downloadStatusData}
              showPagination={false}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default GstPage;
