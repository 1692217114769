import { action, computed, makeAutoObservable } from 'mobx';
import documentStore from './documentStore';
import agent from '../agent';

let defaultImageWidth = 400;

export class ImageStore {
  isLoadingExtracted = false;

  pointsForCrop = {
    crop: { x: 0, y: 0 },
    zoom: 3,
    aspect: 4 / 3,
  };

  currentIndex = 0;

  customExtractedValue = null;

  staticImageWidth = defaultImageWidth;

  focusOnPoint = {
    allowFocus: false,
    x: -1,
    y: -1,
    w: -1,
    h: -1,
    points: [],
    size: {
      w: 0,
      h: 0,
    },
    resetRector: true,
    zoom: 1,
    crop: { x: 0, y: 0 },
    cropSize: { width: 0, height: 0 },
  };
  // selection area
  selectionArea = {
    x: -1,
    y: -1,
    w: -1,
    h: -1,
  };

  updatedCoordinates = [];

  // false - front side true- backside of the image
  imageFacingSide = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @computed get currentWidthImage() {
    return 400;
    //return documentStore.currentPage.staticImageWidth;
  }
  @computed get getCurrentIndex() {
    return this.currentIndex;
  }
  @computed get getAspectRatio() {
    return (
      documentStore.currentPage.dimension.height /
      documentStore.currentPage.dimension.width
    );
  }

  //@computed get getTemplateAspectRatio() {
  //return templateStore.currentPage.dimension.aspectRatio;
  //}

  /*@computed get getAspectRatioBack() {
    return this.mappedInfo[this.currentIndex].isFlipped ? this.mappedInfo[this.currentIndex].dimension2.aspectRatio : 1;
  }

  @computed get isFlippedImage() {
    return this.mappedInfo[this.currentIndex].isFlipped;
  } */

  selectAreaUpdate(rect) {
    this.selectionArea.x = rect.x;
    this.selectionArea.y = rect.y;
    this.selectionArea.w = rect.w;
    this.selectionArea.h = rect.h;
  }

  startDrawPoint(x, y, w, h, cropSize, coords, size, confidence) {
    this.pointsForCrop.crop.x = x;
    this.pointsForCrop.crop.y = y;
    this.focusOnPoint.allowFocus = true;
    this.focusOnPoint.x = x;
    this.focusOnPoint.y = y;
    this.focusOnPoint.w = w;
    this.focusOnPoint.h = h;
    this.focusOnPoint.points = coords;
    this.focusOnPoint.size = size;
    this.focusOnPoint.crop.x = x;
    this.focusOnPoint.crop.y = y;
    this.focusOnPoint.cropSize = cropSize;
    this.focusOnPoint.resetRector = false;
    this.focusOnPoint.confidence = confidence;
    //this.focusOnPoint.zoom = 1;
  }
  eraseDrawPoint() {
    this.focusOnPoint.allowFocus = false;
    this.focusOnPoint.resetRector = true;
    this.focusOnPoint.zoom = 1;
  }

  updateCoordinates(val) {
    this.updatedCoordinates = val;
  }

  toggleFlip() {
    this.imageFacingSide = !this.imageFacingSide;
  }

  zoomIn() {
    if (this.focusOnPoint.zoom === 3) {
      //do not zoom further
      return;
    }
    this.focusOnPoint.zoom += 1;
    this.staticImageWidth += defaultImageWidth;
  }

  fetchSnipped(imageURL, cordinates) {
    this.isLoadingExtracted = true;
    return agent.Upload.snippet(imageURL, cordinates)
      .then(
        action((response, body) => {
          documentStore.updateSnippedField(response, this.updatedCoordinates);
          return response;
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.isLoadingExtracted = false;
        })
      );
  }

  fetchSnippedPoso(imageURL, cordinates) {
    /* this.isLoadingExtracted = true;
    return agent.Documents.getSnippetTextPo(imageURL, cordinates)
      .then(action((response) => {
        console.log(response)
        documentStore.updateSnippedFieldPoso(response, this.updatedCoordinates)
        return response;
      }))
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors;
      }))
      .finally(action(() => {
        this.isLoadingExtracted = false;
      }))*/
  }

  zoomOut() {
    if (this.focusOnPoint.zoom === 1) return;
    this.focusOnPoint.zoom -= 1;
    this.staticImageWidth -= defaultImageWidth;
  }

  fitPage() {
    this.focusOnPoint.zoom = 1;
    this.staticImageWidth = defaultImageWidth;
  }
}
export default new ImageStore();
