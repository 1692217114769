import {
  isValidToken,
  setGstnUser,
  setSingleGstnUser,
} from '@/actions/authActions';
import { hideLoader, showLoader } from '@/actions/loaderActions';
import { getOrganizationGstins } from '@/apis/gstin';
import DashboardModal from '@/components/DashboardModal';
import authService from '@/services/authService';
import Dialog from '@material-ui/core/Dialog';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Iframe from '../components/Iframe';

// import * as Cookies from 'js-cookie';

const DashboardContainerWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 72px);
  position: relative;

  iframe {
    position: absolute;
    border: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const DashboardContainer = (props) => {
  const [showLoading, setShowLoading] = useState(false);
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  let callGstn = async () => {
    let loginMethod = localStorage.getItem('email');
    let gstn = localStorage.getItem('gstn');
    if (loginMethod && !gstn) {
      let gstn = await getOrganizationGstins(loginMethod);
      if (gstn.data.status) {
        dispatch(setGstnUser(gstn.data.response));
        dispatch(setSingleGstnUser(gstn?.data?.response?.[0]?.gstn));
        localStorage.setItem('gstn', JSON.stringify(gstn.data.response));
      }
    }
  };

  useEffect(() => {
    callGstn();
    dispatch(isValidToken(authService.isTokenValid()));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [showLoading, dispatch]);

  return (
    <DashboardContainerWrapper>
      <Iframe
        title="dashboard"
        // src="https://analytics.zoho.in/open-view/103074000004696425/96fb0e5e808d3dc64e1a2d1f74e9dd99?ZOHO_CRITERIA=%22workspace%22=%27Capgemini%27"
        src={`https://analytics.zoho.in/open-view/103074000010526569/7efdad82da211c3fc09986da3fff26dd?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`}
        onLoad={() => setShowLoading(false)}
      />
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <DashboardModal modalToggle={setOpen} />
      </Dialog>
    </DashboardContainerWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    gstn: state.auth.gstn,
    singleGstn: state.auth.singleGstn,
  };
};
export default connect(mapStateToProps)(DashboardContainer);
