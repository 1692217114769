import { action, makeAutoObservable } from 'mobx';
//import ReconnectingWebSocket from 'reconnecting-websocket';
import io from 'socket.io-client';
import constants from '../constants';
import commonStore from './commonStore';
import dashboardStore from './dashboardStore';

let WS_ROOT = constants.WS_ROOT;

// if (window.location.href.indexOf('10.78.0.27') > -1 || window.location.href.indexOf('localhost:8999') > -1){
//     //access via local ip
//     WS_ROOT = constants.WS_ROOT;
// }
//Process as follows:
/*
 * 1) Connect to Socket
 * 2) Authorize with token
 * 3) On response of initiateChat API, store the session id in store
 * 4) When customer types the msg -> customer typing event //TODO LATER
 * 5) Customer stops typing msg -> trigger event //TODO later
 * 6) Customer sends msg -> hit customer chatMsgFromClient event with data & session id
 * */

export class WebsocketStore {
  connected = false;
  disconnected = false;
  reconnecting = false;
  reconnectFailed = false;
  reconnected = false;

  constructor() {
    makeAutoObservable(this);
    this.socketHandle = null;
    this.maxReconnects = 10;
    this.pingInterval = 7000;
    this.pongInterval = 5000;
    this.pongReceived = false;
  }

  startPingPong = () => {
    this.clearPingPong();
    this.pingLoopHandle = window.setInterval(() => {
      if (this.connected) {
        this.pongReceived = false;
        this.socketHandle.emit('ping');
        window.setTimeout(() => {
          if (!this.pongReceived) {
            console.log('Pong not received within 5 seconds. WS disconnected');
            //this.socketHandle.close();
          }
        }, this.pongInterval);
      }
    }, this.pingInterval);
  };

  clearPingPong = () => {
    if (this.pingLoopHandle) {
      window.clearInterval(this.pingLoopHandle);
    }
  };

  register() {
    this.registerWithToken();
  }

  connect() {
    this.reconnectCount = 0;
    this.firstConnect = false;
    if (this.socketHandle) {
      return;
    }
    const socketUrl = `${WS_ROOT}`;
    console.log('trying to connect to server', socketUrl);

    this.socketHandle = io(socketUrl, {
      reconnection: true,
      transports: ['websocket'],
      reconnectionDelay: 1000,
      reconnectionAttempts: 10,
    });
    this.socketHandle.on(
      'connect',
      action(() => {
        console.log('Connection Completed>>>');
        if (commonStore.token && commonStore.token.length) {
          this.registerWithToken();
        }
        this.connected = true;
        console.log('Websocket connected');
        if (commonStore.noNet) {
          commonStore.resetNoInternet();
        }
        this.startPingPong();
        //if(this.firstConnect) {
        //console.log("Websocket reconnected ", this.reconnectCount);
        this.reconnecting = false;
        this.wsReconnected = true;
        this.reconnectCount = 0;
        // commonStore.hideOverlay();
        //}
        this.firstConnect = true;
      })
    );

    this.socketHandle.on(
      'disconnect',
      action(() => {
        //console.log("Websocket disconnected");
        this.connected = false;
        this.disconnected = true;
        if (this.reconnectCount < this.maxReconnects) {
          this.reconnectCount++;
          //console.log("Websocket reconnecting ", this.reconnectCount);
          this.reconnecting = true;
          //commonStore.showOverlay('reconnecting');
        } else {
          //console.log("Websocket reconnecting failed with tries = ", this.reconnectCount);
          this.reconnecting = false;
          this.reconnectFailed = true;
          //commonStore.showOverlay('reconnectfailed');
        }
      })
    );
    this.socketHandle.on(
      'file_uploaded',
      action((message) => {
        console.log(
          'message received on socket - from file uploaded ',
          message
        );
        dashboardStore.getDocumentList('socket');
      })
    );

    this.socketHandle.addEventListener(
      'refresh_list',
      action((message) => {
        console.log('message received on socket - refresh_list ', message);
        dashboardStore.getDocumentList('socket');
      })
    );

    this.socketHandle.addEventListener('error', (error) => {
      //console.log("WS Error ---", error);
      if (navigator.onLine === false) {
        commonStore.setNoInternet();
      } else {
        commonStore.resetNoInternet();
      }
    });
  }

  registerWithToken() {
    if (this.socketHandle && commonStore.token && commonStore.token.length) {
      this.socketHandle.emit(
        'finkraft_auth',
        { token: commonStore.token },
        (data) => {
          console.log('auth token callback', data); //callback data returned from server
        }
      );
    } else {
      console.log('commonStore.token', commonStore.token, this.socketHandle);
      const error = new Error('Web socket not connected');
      console.log('error', error);
    }
  }
}
export default new WebsocketStore();
