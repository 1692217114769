import { createContext } from 'react';

export const defaultToastProps = {
  type: 'def',
  view: 'outlined',
  usePortal: true,
  position: 'top',
  maxToasts: 1,
  withoutClose: false,
};

const ToastNoficationContext = createContext({
  toastRefs: null,
  setToastRefs: () => {},
});

export default ToastNoficationContext;
