import React from 'react';
import Img1 from 'assets/images/img1.png';
import Img2 from 'assets/images/img2.png';
import Img3 from 'assets/images/img3.png';
import Img4 from 'assets/images/img4.png';
// import Img5 from 'assets/images/img5.png';
import SideImg1 from 'assets/images/sideImg1.png';

import './home.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
const HomeCard = (props) => {
  const history = useHistory();
  return (
    <div
      className="home-card"
      onClick={() => {
        history.push(props.to);
      }}
    >
      <img src={props.img} alt="home-card-img" />
      <span>{props.title}</span>
    </div>
  );
};
const Home = (props) => {
  return (
    <div className="d-grid home-grid">
      <div className="item-1 d-grid cards-grid">
        <div className="item-1">
          <p className="title-sec">
            <label>ITR Acknowledgment</label>
            <span>
              Ensure you are not making double the TDS amounts by bringing all
              the ITR transactions over here and share acknowledgments
            </span>
          </p>
        </div>
        <div className="item-2">
          <div className="d-grid home-card-grid">
            <div className="item-1">
              <HomeCard
                img={Img1}
                title="View or update your ITR Filings"
                to={'/itrFilling'}
              />
            </div>
            <div className="item-2">
              <HomeCard
                img={Img2}
                title="Share ITR Acknowledgments with Clients"
              />
            </div>
            <div className="item-3">
              <HomeCard
                img={Img3}
                title="Get acknowledgements from Vendors"
                to={`/reports/D1a?metadata=${window.btoa(
                  `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
                )}`}
              />
            </div>
            <div className="item-4">
              <HomeCard img={Img4} title="Link your ITR, GSTR1 and GST2A" />
            </div>
          </div>
        </div>
      </div>
      <div className="item-2 d-grid cards-grid">
        <div className="item-1">
          <p className="title-sec">
            <label>Vendor E-Invoice Validation</label>
            <span>
              Real time identification of vendors eligibility for invoices and
              GSTR1, GSTR 3B filing records before making a payment.
            </span>
          </p>
        </div>
        <div className="d-grid home-card-grid">
          <div className="item-1">
            <HomeCard img={Img1} title="Check Vendor Eligibility" />
          </div>
          <div className="item-2">
            <HomeCard img={Img2} title="Validate E-Invoices" />
          </div>
          <div className="item-3">
            <HomeCard img={Img3} title="Check your Vendor Score" />
          </div>
          <div className="item-4">
            <HomeCard
              img={Img4}
              title="3- Way reconciliation with GST2A and PR"
            />
          </div>
        </div>
      </div>
      <div className="item-3">
        <div>
          <p>
            Avoid paying double the TDS amount by informing clients on time.
          </p>
          <img src={SideImg1} alt="side-img" />
          <span>
            Starting from July, some taxpayers may have to pay Tax Deducted At
            Source (TDS) at higher rates.
            <br />
            <br />
            Ensure you are not making double the TDS amounts by bringing all the
            ITR transactions over here and share acknowledgments
          </span>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isITR: state.isITR,
    gstn: state.auth.gstn,
    singleGstn: state.auth.singleGstn,
  };
};
export default connect(mapStateToProps)(Home);
