import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import UploadIcon from 'assets/images/invoice-icon1.png';
import moment from 'moment';

import 'components/styles/uploadlogs.css';
import Pagination from './Pagination';
const UploadLogs = (props) => {
  const [data, setData] = useState([]);
  // const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(false);
  const [sortKey, setSortKey] = useState('created_at');

  useEffect(() => {
    props.callOnMount &&
      props
        .callOnMount()
        .then((res) => {
          setData(res.data.response);
        })
        .catch((err) => {
          console.log('err', err);
        });
    //eslint-disable-next-line
  }, []);

  const status = {
    successful: 'Successful',
  };

  const statusColor = {
    successful: 'success',
  };

  return (
    <div className="upload-logs">
      <div className="table">
        <div className="thead">
          <div className="tr">
            <div className="th fileName">File Name</div>
            <div
              className="th dateTime cursor"
              onClick={() => {
                setSortKey('created_at');
                setSortBy((bool) => !bool);
              }}
            >
              {'Date & Time'}
            </div>
            <div className="th fileType">{'File Type'}</div>
            <div className="th remarks">{'Remarks'}</div>
            <div className="th status">Status</div>
            {props.value === 1 && <div className="th report">Report</div>}
          </div>
        </div>
        <div className="tbody">
          {data &&
            data.length > 0 &&
            _.orderBy(
              data.map((item) => ({
                ...item,
                created_at: new Date(item.created_at).getTime(),
              })),
              [sortKey],
              [sortBy ? 'asc' : 'desc']
            )
              .slice(0, 7)
              .map((item) => {
                return (
                  <div className="tr">
                    <div className="td fileName">{item.file_name}</div>
                    <div className="td dateTime">
                      {moment(item.created_at).format('MMM DD, YYYY HH:mm')}
                    </div>
                    <div className="td fileType">{item.doc_type}</div>
                    <div className="td remarks">{item.remarks}</div>
                    <div className={`td status ${statusColor[item.status]}`}>
                      {status[item.status]}
                    </div>
                    {props.value === 1 && (
                      <div className="td report">
                        <a
                          href={item.report_file}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {item.report_file ? (
                            <img
                              src={UploadIcon}
                              alt={'upload-icon'}
                              width={20}
                              height={20}
                            />
                          ) : (
                            '-'
                          )}
                        </a>
                      </div>
                    )}
                  </div>
                );
              })}
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default UploadLogs;
