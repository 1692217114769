import { action, makeAutoObservable } from 'mobx';
import agent from '../agent';
import commonStore from './commonStore';
import constants from '../constants';
import { tableHeaders } from '../utils/utils';
export class DashboardStore {
  selectAllDocs = false;
  selectedDocs = [];
  openActionSnackbar = false;
  documentList = [];
  countData = {};
  inProgress = false;
  inDocProgress = false;
  deleteProgress = false;
  files = [];
  statusText;
  errors;
  enterpriseData = {};
  totalDocs = 0;
  processingStatus = {
    totalDocs: 0,
    processedCount: 0,
  };
  openUploadDialog = false;
  openFileAssignDialog = false;
  uploadIcon = 'close';
  uploadedFiles = [];
  uploadedFolders = [];
  exportProgress = false;
  pageNo = 0;
  menuArray = [];
  currentId = undefined;
  searchText = undefined;
  stopUpdating = false;
  messageType = 'error';
  selectedDocType = '';
  gstnFilter = 'ALL';
  tableHeader = [
    {
      name: 'Name',
      type: 'originalFileName',
      sort: 1,
      category: ['drafts', 'reviewed', 'exported', 'ai pending'],
      userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
      allowSort: true,
      width: '16vw',
    },
    {
      name: 'Status',
      type: 'docStatus',
      sort: 1,
      category: ['drafts'],
      userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
      allowSort: false,
      width: '13vw',
    },
    //{ name: 'Assigned To', type: 'assignedTo', sort: 1, category: ['drafts', 'ai pending'], userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'], allowSort: false, width: '13vw' },
    {
      name: 'Reviewed By',
      type: 'reviewedBy',
      sort: 1,
      category: ['reviewed', 'exported'],
      userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
      allowSort: false,
      width: '13vw',
    },
    {
      name: 'Type',
      type: 'docType',
      sort: 1,
      category: [],
      allowSort: true,
      width: '13vw',
    },
    {
      name: 'Enterprise',
      type: 'enterpriseId',
      sort: 1,
      category: ['drafts', 'reviewed', 'exported', 'ai pending'],
      userType: ['SUPER_ADMIN'],
      allowSort: false,
      width: '13vw',
    },
    {
      name: 'Reviewed At',
      type: 'reviewedAt',
      sort: 1,
      category: ['reviewed'],
      userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
      allowSort: true,
      width: '13vw',
    },
    {
      name: 'Received At',
      type: 'createdAt',
      sort: 1,
      category: ['drafts', 'ai pending'],
      userType: ['ADMIN', 'SUPER_ADMIN'],
      allowSort: true,
      width: '13vw',
    },
    {
      name: 'Assigned At',
      type: 'assignedAt',
      sort: 1,
      category: ['drafts'],
      userType: ['AGENT'],
      allowSort: true,
      width: '13vw',
    },
    {
      name: 'Exported At',
      type: 'exportedAt',
      sort: 1,
      category: ['exported'],
      userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
      allowSort: true,
      width: '13vw',
    },
    {
      name: 'Sequence No.',
      type: 'sequence_no',
      sort: 1,
      category: ['drafts', 'reviewed', 'exported', 'ai pending'],
      userType: ['AGENT', 'ADMIN', 'SUPER_ADMIN'],
      allowSort: true,
      width: '13vw',
    },
  ];
  timeField = 'createdAt';
  scanStatus = { name: 'All', value: 'ALL' };
  eInvoice = { name: 'All', value: 'ALL' };
  confidenceScore = { name: 'All', value: 'ALL' };
  reviewStatus = { name: 'All', value: 'ALL' };
  searchQuery = '';
  sortField = 'updatedAt';
  sortDirection = '-1';
  sortAnchor = null;
  reviewAnchor = null;
  pagination = { low: 0, high: 6, page: 0 };
  filterAnchors = new Array(tableHeaders.length).fill(null);
  selectTotalDocs = false;

  constructor() {
    makeAutoObservable(this);
  }

  handleSelectAll(value) {
    this.selectAllDocs = value;
    this.selectedDocs = new Array(this.documentList.length).fill(value);
    this.openActionSnackbar = value;
  }

  handleTotalDocs(value) {
    this.selectTotalDocs = value;
  }

  setPageNumber(num) {
    this.pageNo = num;
  }

  resetSelectedDocuments() {
    this.selectAllDocs = false;
    this.closeActionSnackbar();
    this.selectedDocs = new Array(this.documentList.length).fill(false);
  }

  setSelectedDocs(index, value) {
    if (!value) {
      this.selectAllDocs = value;
      this.selectTotalDocs = value;
    }
    this.selectedDocs[index] = value;
    if (this.selectedDocs.indexOf(true) > -1) this.openActionSnackbar = true;
    else {
      this.closeActionSnackbar();
      this.selectedDocs = new Array(this.documentList.length).fill(value);
    }
  }

  closeActionSnackbar() {
    this.openActionSnackbar = false;
  }

  //File Actions - Export
  exportDocuments = () => {
    let obj = {};
    if (this.selectTotalDocs) {
      obj.fetchAll = true;
      if (this.scanStatus.value !== 'ALL')
        obj.scanStatus = this.scanStatus.value;
      if (this.eInvoice.value !== 'ALL') obj.eInvoice = this.eInvoice.value;
      if (this.confidenceScore.value !== 'ALL')
        obj.confidenceScore = this.confidenceScore.value;
      if (this.reviewStatus.value !== 'ALL')
        obj.reviewStatus = this.reviewStatus.value;
      if (this.gstnFilter !== 'ALL') obj.sellerGstin = this.gstnFilter;
      if (this.searchQuery !== '') obj.searchQuery = this.searchQuery;
    } else {
      let temp = [];
      for (let i = 0; i < this.selectedDocs.length; i++) {
        if (this.selectedDocs[i]) temp.push(this.documentList[i]._id);
      }
      obj.uploadIdArray = temp;
    }
    this.exportXls(obj);
  };

  //File Actions - Download
  downloadBulkDocs(filter) {
    let temp = [];
    for (let i = 0; i < this.selectedDocs.length; i++) {
      if (this.selectedDocs[i]) temp.push(this.documentList[i]._id);
    }
    this.downloadOriginalDoc(temp, filter);
  }

  exportXls(data) {
    this.statusText = '';
    this.errors = undefined;
    this.exportProgress = true;

    return agent.Documents.exportToExcel(data)
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.handleSelectAll(false);
            this.selectAllDocs = false;
            let link = document.createElement('a');
            link.href =
              constants.API_ROOT +
              `/upload/downloadXls?downloadToken=${response.data.downloadToken}&fileName=${response.data.fileName}`;
            link.click();
            commonStore.handleOpenSnackBar(
              `Invoice(s) exported successfully.`,
              'success'
            );
          } else commonStore.handleOpenSnackBar(response.message, 'error');
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.exportProgress = false;
        })
      );
  }

  //File Actions - delete
  deleteMultipleDocs = (filter) => {
    let temp = [];
    for (let i = 0; i < this.selectedDocs.length; i++) {
      if (this.selectedDocs[i]) temp.push(this.documentList[i]._id);
    }
    this.statusText = '';
    this.errors = undefined;
    this.deleteProgress = true;
    this.messageType = 'error';
    return agent.Documents.deleteDocument(temp)
      .then(
        action((response) => {
          //this.resetSelectedDocuments()
          // this.resetMenuArray()
          if (response.statusCode === 200) {
            this.messageType = 'success';
            this.getDocumentList('socket');
            /*if (userStore.user.userType === 'SUPER_ADMIN')
                        this.sortDocumentList(filter, 'createdAt', -1)
                    else
                        this.fetchDocumentList(filter)*/
          }
          this.getDocumentList();
          commonStore.handleOpenSnackBar(response.message, this.messageType);
        })
      )
      .catch(
        action((err) => {
          commonStore.handleOpenSnackBar(
            'Failed to delete invoices',
            this.messageType
          );
        })
      )
      .finally(
        action(() => {
          this.deleteProgress = false;
        })
      );
  };

  resetMenuArray() {
    this.menuArray = new Array(this.documentList.length).fill(false);
  }

  openMenu(e, index) {
    this.menuArray[index] = e.currentTarget;
  }

  deleteDocument(index, docId) {
    this.statusText = '';
    this.errors = undefined;
    this.deleteProgress = true;
    this.messageType = 'error';
    let temp = [docId];
    return agent.Documents.deleteDocument(temp)
      .then(
        action((response) => {
          //this.resetSelectedDocuments()
          // this.resetMenuArray()
          if (response.statusCode === 200) {
            this.messageType = 'success';
            this.getDocumentList();
            /*if (userStore.user.userType === 'SUPER_ADMIN')
                        this.sortDocumentList(filter, 'createdAt', -1)
                    else
                        this.fetchDocumentList(filter)*/
          }
          this.documentList.splice(index, 1);
          commonStore.handleOpenSnackBar(response.message, this.messageType);
        })
      )
      .catch(
        action((err) => {
          commonStore.handleOpenSnackBar(
            'Failed to delete invoice',
            this.messageType
          );
        })
      )
      .finally(
        action(() => {
          this.deleteProgress = false;
        })
      );
  }

  //Get document list
  fetchDocumentList(filter) {
    /*  if (userStore.user.userType !== 'SUPER_ADMIN') {
              if (filter === "REVIEW_PENDING")
                  this.getHomePageData();
              else {
                  if (filter == "REVIEW_COMPLETED")
                      this.getFilteredDocuments(filter)
                  else
                      this.getFilteredDocuments(filter)
              }
          }
          else*/
    this.getDocumentList(filter);
  }

  resetSearch = () => {
    this.searchText = undefined;
  };

  setCountData(countData) {
    this.countData = countData;
  }

  //Get document list according to export/reviewed statuses
  getFilteredDocuments(filter, sortField, sortDirection) {
    this.statusText = '';
    this.errors = undefined;
    this.noMatchFound = false;
    this.inProgress = true;
    let temp = { sortField, sortDirection };
    if (sortField === undefined) temp = this.getSortField(filter);
    return agent.Dashboard.getFilteredDocuments(
      filter,
      temp.sortField,
      temp.sortDirection,
      this.searchText
    )
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.documentList = response.data.documentList;
            this.resetSelectedDocuments();
            this.resetMenuArray();
            this.countData = response.data.countData;
            this.totalDocs = response.data.totalDocs;
            this.processingStatus = response.data.processingStatus;
          } else {
            this.documentList = [];
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  getPeriodicDocList(filter) {
    if (this.stopUpdating || this.selectedDocs.length) return;
    this.statusText = '';
    this.errors = undefined;
    this.noMatchFound = false;
    let temp = this.getSortField(filter);
    return agent.Dashboard.getFilteredDocuments(
      filter,
      temp.sortField,
      temp.sortDirection
    )
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.documentList = response.data.documentList;
            this.resetSelectedDocuments();
            this.resetMenuArray();
            this.enterpriseData = response.data.enterpriseData;
            this.processingStatus = response.data.processingStatus;
            this.countData = response.data.countData;
          } else {
            this.documentList = [];
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(action(() => {}));
  }

  getHomePageData(sortField, sortDirection) {
    this.statusText = '';
    this.errors = undefined;
    this.noMatchFound = false;
    this.inProgress = true;
    let temp = { sortField, sortDirection };
    if (sortField === undefined) temp = this.getSortField('REVIEW_PENDING');
    return agent.Dashboard.getHomePageData(
      temp.sortField,
      temp.sortDirection,
      this.searchText
    )
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.documentList = response.data.documentList;
            this.resetSelectedDocuments();
            this.resetMenuArray();
            this.enterpriseData = response.data.enterpriseData;
            this.processingStatus = response.data.processingStatus;
            this.countData = response.data.countData;
            this.totalDocs = response.data.totalDocs;
            //   if (response.data.isLocalTemplatePresent)
            // userStore.setLocalTemplate()
          } else {
            this.documentList = [];
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  setUploadFiles(files) {
    this.files = this.files.concat(Array.from(files));
    this.files.forEach(function (element) {
      element.uploadIcon = 'close';
    });
  }

  closeFileActions() {
    this.openFileActions = false;
  }

  closeUploadDialog() {
    this.openUploadDialog = false;
  }

  closeFileAssignDialog() {
    this.openFileAssignDialog = false;
    this.closeActionSnackbar();
    this.currentId = undefined;
    //   userStore.closeDialog()
  }

  openFileAssign(id) {
    this.currentId = id;
    this.openFileAssignDialog = true;
    this.closeActionSnackbar();
  }

  removeFile(index) {
    this.files.splice(index, 1);
  }

  openUpload(assignedUser, comment, docType) {
    //  let index = userStore.userList.findIndex(x => x.email === assignedUser.split(",")[1])
    //  this.assignedUser = userStore.userList[index];
    //  this.assignedUser = assignedUser
    // this.assignedUser.comment = comment;
    this.openFileAssignDialog = false;
    this.openUploadDialog = true;
    this.selectedDocType = docType;
  }

  sortDocumentList(filter, sortField, sortDirection) {
    let index = this.tableHeader.findIndex((x) => x.type === sortField);
    this.tableHeader.forEach((el) => {
      el.sort = 1;
    });
    this.tableHeader[index].sort = sortDirection;
    /* if (userStore.user.userType !== 'SUPER_ADMIN') {
             if (filter === "REVIEW_PENDING")
                 this.getHomePageData(sortField, sortDirection);
             else
                 this.getFilteredDocuments(filter, sortField, sortDirection)
         }
         else {*/
    this.getDocumentList(filter, sortField, sortDirection);
    // }
  }

  getSortField(filter) {
    let counts = this.tableHeader.reduce(
      (c, { sort: key }) => ((c[key] = (c[key] || 0) + 1)),
      {}
    );
    let sortDirection = counts[-1] === 1 ? -1 : 1;
    let index = this.tableHeader.findIndex((x) => x.sort === -1);
    if (index > -1)
      return {
        sortField: this.tableHeader[index].type,
        sortDirection: sortDirection
      };
    else {
      if (filter === 'REVIEW_PENDING') {
        let index = this.tableHeader.findIndex((x) => x.type === 'createdAt');
        this.tableHeader[index].sort = -1;
        return { sortField: 'createdAt', sortDirection: -1 };
      } else if (filter === 'REVIEW_COMPLETE') {
        let index = this.tableHeader.findIndex((x) => x.type === 'reviewedAt');
        this.tableHeader[index].sort = -1;
        return { sortField: 'reviewedAt', sortDirection: -1 };
      } else {
        let index = this.tableHeader.findIndex((x) => x.type === 'exportedAt');
        this.tableHeader[index].sort = -1;
        return { sortField: 'exportedAt', sortDirection: -1 };
      }
    }
  }

  resetFiles() {
    this.files = [];
    this.uploadIcon = 'close';
    this.openUploadDialog = false;
    this.uploadedFolders = [];
  }

  expandUploadedFolder(index) {
    this.uploadedFolders[index].expanded =
      !this.uploadedFolders[index].expanded;
  }

  cancelUpload() {
    this.closeUploadDialog();
    this.files = [];
    this.uploadedFolders = [];
    this.uploadIcon = 'close';
  }

  setupUploadResponse(uploadData) {
    let temp = [];
    for (let i = 0; i < this.files.length; i++) {
      if (
        uploadData.invalidFilesArray.length &&
        uploadData.invalidFilesArray.indexOf(this.files[i].name) > -1
      )
        this.files[i].uploadIcon = 'error';
      else if (
        uploadData.validFilesArray.length &&
        uploadData.validFilesArray.indexOf(this.files[i].name) > -1
      )
        this.files[i].uploadIcon = 'right';
      else this.files.splice(i, 1);
    }

    if (uploadData.extractedDataArray.length) {
      uploadData.extractedDataArray.forEach((folder) => {
        let obj = {};
        obj.folderName = folder.folderName;
        obj.files = [];
        obj.expanded = true;
        if (folder.filesArray.length) {
          folder.filesArray.forEach((file) => {
            let tempObj = {};
            tempObj.name = file;
            tempObj.uploadIcon = 'right';
            obj.files.push(tempObj);
          });
        }
        if (folder.invalidFilesArray.length) {
          folder.invalidFilesArray.forEach((file) => {
            let tempObj = {};
            tempObj.name = file;
            tempObj.uploadIcon = 'error';
            obj.files.push(tempObj);
          });
        }
        this.uploadedFolders.push(obj);
      });
    }
    this.uploadedFiles = temp;
  }

  uploadFiles() {
    this.resumeUpdating();
    this.statusText = '';
    this.errors = undefined;
    this.uploadProgress = true;
    this.openUploadDialog = true;
    this.uploadIcon = 'progress';
    return agent.Documents.uploadFiles(this.files)
      .then(
        action((response) => {
          this.uploadStatus = response;
          if (response.statusCode === 200) {
            this.uploadProgress = false;
            this.pageNo = 0;
            this.setupUploadResponse(response.uploadData);
            this.uploadIcon = '';
            //  setTimeout(() => { this.resetFiles() }, 500)
            //  this.resetFiles()
            commonStore.handleOpenSnackBar(response.message, 'success');
          } else {
            this.resetFiles();
            commonStore.handleOpenSnackBar(response.message, 'error');
          }
        })
      )
      .catch(
        action((err) => {
          this.uploadIcon = '';
          if (err.response && err.response.body && err.response.body.message) {
            if (
              err.response.body.message.includes(
                'Payload content length greater than maximum allowed'
              )
            ) {
              let temp = err.response.body.message.split(':');
              if (temp.length > 1) {
                let bytes = temp[1];
                let mb = Math.round(bytes / Math.pow(1024, 2)) + ' MB';
                commonStore.handleOpenSnackBar(temp[0] + ': ' + mb, 'error');
              }
            } else {
              commonStore.handleOpenSnackBar(
                err.response && err.response.body && err.response.body.message,
                'error'
              );
            }
          }
          this.errors =
            err.response && err.response.body && err.response.body.message;
        })
      )
      .finally(
        action(() => {
          this.uploadProgress = false;
        })
      );
  }

  resumeUpdating = () => {
    console.log('updating');
    this.stopUpdating = false;
  };

  searchDocuments(searchText, filter) {
    this.searchText = searchText;
    this.stopUpdating = true;
    /* if (userStore.user.userType === 'SUPER_ADMIN')
             this.getDocumentList(filter)
         else {*/
    if (filter === 'REVIEW_PENDING') this.getHomePageData();
    else this.getFilteredDocuments(filter);
    // }
  }

  //Get invoice list
  getDocumentList(from) {
    this.statusText = '';
    this.errors = undefined;
    this.noMatchFound = false;
    if (from !== 'socket') this.inDocProgress = true;
    if (this.pageNo === 0) this.pagination.page = 0;
    return agent.Documents.getDocumentList(this.pageNo, this)
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.documentList = response.data.documentList;
            this.resetSelectedDocuments();
            this.resetMenuArray();
            //  this.countData = response.data.countData;
            this.totalDocs = response.data.totalDocs;
            // this.processingStatus = response.data.processingStatus;
            this.inDocProgress = false;
          } else {
            this.documentList = [];
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.inDocProgress = false;
        })
      );
  }

  handleSearchChange(value) {
    this.searchQuery = value;
  }

  setPagination(value) {
    this.pagination = value;
  }

  setSortValues(type, anchor, value) {
    if (type === 'sort') this.sortAnchor = anchor;
    else this.reviewAnchor = anchor;
    this.sortField = value;
  }

  setFilter(type, value) {
    this[`${type}`] = value;
  }

  setFilterAnchor(value) {
    this.filterAnchors = value;
  }

  handleGstnFilterChange(value) {
    this.gstnFilter = value;
  }
}

export default new DashboardStore();
