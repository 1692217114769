import React, { useEffect, useState } from 'react';
// import _ from 'lodash';
import Pagination from '../Pagination';

import 'components/styles/uploadlogs.css';
import './GstPage.css';

const Table = (props) => {
  const [data, setData] = useState([]);
  // const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <div className="upload-logs">
      <div className="table">
        <div className="thead">
          <div className="tr">
            {props.columns.map((item) => {
              return (
                <div className={`th ${item.className}`}>{item.header}</div>
              );
            })}
          </div>
        </div>
        <div className="tbody">
          {data &&
            data.length > 0 &&
            data.map((item) => {
              return (
                <div className="tr">
                  {props.columns.map((ele) => {
                    return (
                      <div
                        className={`td ${ele.className} ${
                          ele.statusBrder ? 'successBorder' : ''
                        }`}
                      >
                        {ele.cell ? ele.cell(item) : item[ele.accessor]}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          {props.showPagination && <Pagination />}
        </div>
      </div>
    </div>
  );
};

export default Table;
