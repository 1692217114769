import React from 'react';
import Next from 'assets/images/next.svg';

const Pagination = (props) => {
  return (
    <div className="pagination">
      <label>Showing 1 to 10 of 20 entries</label>
      <button>
        <img src={Next} className="rotate" alt="prev" />
      </button>
      <button>1</button>
      <button>2</button>
      <button>3</button>
      <button>
        <img src={Next} alt="next" />
      </button>
    </div>
  );
};

export default Pagination;
