import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export function getPageLoadingDom() {
    return (
        <div className="loading-container">
            <CircularProgress size={150} thickness={2} style={{ color: '#0f7fd3' }} />
        </div>
    );
}

export const tableHeaders = [
    {
        name: "File Name",
        sort: false
    },

    {
        name: "Company Name",
        sort: false
    },
    {
        name: "Invoice No",
        sort: false
    },
    {
        name: "Invoice Date",
        sort: false
    },
    {
        name: "Seller GSTIN",
        sort: false
    },
    {
        name: "Amount",
        sort: false
    },
    {
        name: "Scan Status",
        sort: true,
        filter: 'scanStatus',
        filters: [
            /* 
             { name: 'Completed', value: 'COMPLETED' },
             { name: 'Failed', value: 'FAILED' },
             { name: 'In Progress', value: 'IN_PROGRESS' },*/
            { name: 'All', value: 'ALL' },
            { name: 'QR Pending', value: 'QR_PENDING' },
            { name: 'QR Completed', value: 'QR_COMPLETED' },
            { name: 'QR Failed', value: 'QR_FAILED' },
            { name: 'OCR Pending', value: 'OCR_PENDING' },
            { name: 'OCR Completed', value: 'OCR_COMPLETED' },
            { name: 'OCR Failed', value: 'OCR_FAILED' },
            { name: 'Multiple Invoices', value: 'MULTIPLE_INVOICES' }
        ]
    },
    /* {
         name: "E-Invoice",
         sort: true,
         filter: 'eInvoice',
         filters: [
             { name: 'All', value: 'ALL' },
             { name: 'Success', value: 'SUCCESS' },
             { name: 'Failed', value: 'FAILED' }
         ]
     },
     {
         name: "Confidence Score",
         sort: true,
         filter: 'confidenceScore',
         filters: [
             { name: 'All', value: 'ALL' },
             { name: 'High', value: 'HIGH' },
             { name: 'Low', value: 'LOW' }
         ]
     },*/
    {
        name: "Review Status",
        sort: true,
        filter: 'reviewStatus',
        filters: [
            { name: 'All', value: 'ALL' },
            { name: 'Review Pending', value: 'REVIEW_PENDING' },
            { name: 'Reviewed', value: 'REVIEWED' },
            { name: 'Attention', value: 'ATTENTION' },
            { name: 'Approved', value: 'APPROVED' }
        ]
    },
    {
        name: "QR Detected",
        sort: false
    },
    {
        name: "",
        sort: false
    }
]

export const sortItems = [
    { name: 'Company Name', value: 'companyName' },
    { name: 'Invoice No.', value: 'invoiceNo' },
    { name: 'Invoice Date', value: 'invoiceDate' },
    { name: 'Buyer GSTIN', value: 'buyerGstin' },
    { name: 'Amount', value: 'amount' },
    { name: 'Updated Date', value: 'updatedAt' },
]

export const colorCodes = {
    scanStatus: {
        //  COMPLETED: '#47B553',
        //  FAILED: '#FF1717',
        IN_PROGRESS: '#D1932F',
        QR_COMPLETED: '#47B553',
        OCR_COMPLETED: '#47B553',
        QR_FAILED: '#FF1717',
        OCR_FAILED: '#FF1717',
        QR_PENDING: '#D1932F',
        OCR_PENDING: '#D1932F',
        MULTIPLE_INVOICES: '#00000'
    },
    eInvoice: { SUCCESS: '#47B553', FAILED: '#FF1717' },
    confidenceScore: { HIGH: '#47B553', MEDIUM: '#FF1717', LOW: '#D1932F' },
    reviewStatus: { REVIEW_PENDING: 'initial', APPROVED: '#47B553', ATTENTION: '#D1932F' },
}

/*export const invoiceStages = [
    {}
]*/


export const scanStatus = {
    QR_PENDING: 'QR Pending',
    QR_FAILED: 'QR Failed',
    QR_COMPLETED: 'QR Completed',
    OCR_PENDING: 'OCR Pending',
    OCR_FAILED: 'OCR Failed',
    OCR_COMPLETED: 'OCR Completed',
    MULTIPLE_INVOICES: 'Multiple Invoices'
}

export const inProgressStatus = [
    'QR_PENDING', 'OCR_PENDING', 'IN-PROGRESS',
]