import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Invoice from '@/components/Invoice';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import BulkUpload from '@/components/BulkUpload';
import UploadLogs from '@/components/UploadLogs';
import Purchase from '../assets/FinkraftPurchases_Template.xlsx';
import GST from '../assets/FinkraftGst2A_Template.xlsx';
import VendorsMaster from '../assets/FinkraftVendorsMaster_template.xlsx';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router';

const UploadContainerWrapper = styled.div`
  width: 100vw;
  min-height: calc(100vh - 72px);
`;

const Container = styled.div`
  min-width: 1300px;
  max-width: 90%;
  height: 100%;
  padding-top: 20px;
  margin: 0 auto;

  .dropzone-container {
    padding: 20px;
    width: 100%;
    height: 150px;
  }

  .data-container {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    padding: 20px 0;

    & > div {
      margin: 0 20px;
    }
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .makeStyles-root-37 {
    height: 500px;
  }
  .MuiTab-wrapper {
    display: flex;
    justify-content: center;
    text-transform: capitalize;
  }

  .MuiTabs-indicator {
    left: 0;
    background: #000;
  }
  .tabs__container {
    width: 100%;
    min-height: 480px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 11px #0000000f;
    border: 1px solid #eaeaea;
    border-radius: 10px;
  }
  .MuiTabs-root {
    height: inherit;
    min-height: 480px;
    background: #f5f7fa;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 11%;
  }
  .tab_innercont {
    width: 85%;
  }
  .form-groups1 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 0 20px 0;
  }
  .inner_tabcont form {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .form-dwnGroup {
    margin: 0 0 10px 0;
  }
  .form-dwnGroup span.infodwn {
    margin: 0 0 10px 0;
    padding-left: 20px;
    font-size: 12px;
    line-height: 18px;
  }
  .paraTxt1 {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 10px 0;
  }
  .form-groups1A {
    display: flex;
    justify-content: flex-start;
  }
  .form-groups1B {
    display: flex;
    justify-content: flex-end;
  }
  .form-groups1B button {
    margin-right: 10px;
  }
  .bulk__inner {
    width: 100%;
    min-height: 360px;
    background: #fff;
    padding: 0px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .MuiTabs-scroller {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }
`;

const UploadContainer = () => {
  // const [qrCodeData, setQrCodeData] = useState(null);
  // const [file, setFile] = useState(null);
  // const [isFetching, setIsFetching] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(0);
  const [logs, setLogs] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.tab) {
      setValue(location?.state?.tab);
    }
    // eslint-disable-next-line
  }, []);

  // const handleDrop = async (acceptedFiles) => {
  //   try {
  //     setIsFetching(true);
  //     setQrCodeData(null);
  //     setIsError(false);
  //     setFile(acceptedFiles[0]);

  //     const formData = new FormData();
  //     formData.append('file', acceptedFiles[0]);

  //     const { data: uploadResponse } = await Axios.post(
  //       'https://staging-qa-apis.finkraft.ai/upload',
  //       formData
  //     );
  //     console.log('Upload Repsonse', uploadResponse);

  //     if (uploadResponse) {
  //       setQrCodeData(uploadResponse);
  //       setIsFetching(false);
  //     } else {
  //       setIsFetching(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setIsFetching(false);
  //     setFile(null);
  //     setIsError(true);
  //     alert(JSON.stringify(error) || error);
  //   }
  // };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        className="tab_innercont"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {value === 0 && (
              <ul className="upload-tabs">
                <li
                  className={!logs ? 'active' : ''}
                  onClick={() => {
                    setLogs(false);
                  }}
                >
                  {'Invoice Upload'}
                </li>
                {/* <li
                  className={logs ? 'active' : ''}
                  onClick={() => {
                    setLogs(true);
                  }}
                >
                  Upload logs
                </li> */}
              </ul>
            )}
            <br />
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      borderRadius: '31px',
      // height: 254,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

  const classes = useStyles();

  return (
    <UploadContainerWrapper>
      <Container>
        <div className="tabs__container">
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={(e, v) => {
                setLogs(false);
                setValue(v);
                history.push('/upload');
              }}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab
                icon={<DescriptionOutlinedIcon />}
                label="Invoice"
                id="vertical-tab-0"
              />
              <Tab
                icon={<FileCopyOutlinedIcon />}
                label="Purchase"
                id="vertical-tab-1"
              />
              <Tab
                icon={<FileCopyOutlinedIcon />}
                label="GST 2A"
                id="vertical-tab-2"
              />
              <Tab
                icon={<FileCopyOutlinedIcon />}
                label="Vendor Masters"
                id="vertical-tab-3"
              />
              <Tab
                icon={<FileCopyOutlinedIcon />}
                label="Upload Logs"
                id="vertical-tab-4"
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              {logs ? (
                <UploadLogs
                // callOnMount={async () => {
                //   return await axiosInstance.get('/documents');
                // }}
                />
              ) : (
                <Invoice />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BulkUpload
                url={Purchase}
                label={'Purchase'}
                fileType={'purchase_register'}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BulkUpload url={GST} label={'GST 2A'} fileType={'gst2a'} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <BulkUpload
                url={VendorsMaster}
                label={'Vendor Master'}
                fileType={'vendor_master'}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <UploadLogs
                callOnMount={async () => {
                  return await axios.get(
                    'https://api.finkraft.ai/api/documents',
                    {
                      params: { page: 1 },
                    }
                  );
                }}
                value={value}
              />
            </TabPanel>
          </div>
        </div>
      </Container>
    </UploadContainerWrapper>
  );
  // return (
  //   <UploadContainerWrapper>
  //     <Container>
  //       <div className="dropzone-container">
  //         <DropZone handleDrop={handleDrop} />
  //       </div>
  //       <div className="data-container">
  //         <FilePreview file={file} />
  //         <JsonViewer
  //           fileName={file?.name}
  //           isFetching={isFetching}
  //           isError={isError}
  //           jsonObj={qrCodeData}
  //         />
  //       </div>
  //     </Container>
  //   </UploadContainerWrapper>
  // );
};

export default UploadContainer;
