export const buttonStyles = theme => ({
    delete: {
        backgroundColor: `${theme.palette.darkRed} !important`
    },
    button: {
        background: theme.palette.purple,
        margin: '0vh 4px',
        color: theme.palette.white,
        fontWeight: 'normal',
        minHeight: '30px',
        objectFit: 'contain',
        fontSize: '13px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0vh 1vw',
        '&:hover': {
            backgroundColor: theme.palette.purple,
        }
    },
    disabled: {
        color: `${theme.palette.white} !important`,
        cursor: 'not-allowed !important',
        backgroundColor: `${theme.palette.warmGray} !important`,
        pointerEvents: 'initial !important',
        '&:hover': {
            backgroundColor: `${theme.palette.warmGray} !important`
        }
    },
    cancelButton: {
        color: '#354052',
        border: '1px solid #CED0DA',
        background: 'transparent linear-gradient(180deg, #F2F4F7 0%, #FFFFFFA8 100%) 0% 0% no-repeat padding-box',
        '&:hover': {
            background: 'transparent linear-gradient(180deg, #F2F4F7 0%, #FFFFFFA8 100%) 0% 0% no-repeat padding-box'
        }
    }

})

export const selectStyles = theme => ({
    disabled: {
        cursor: 'not-allowed !important'
    },
    formControl: {
        marginBottom: '1vh',
        border: `solid 1px ${theme.palette.lightGray2}`,
        borderRadius: '3px',
        width: '100%',
        height: '6.9vh',
        marginTop: '1vh'
    },
    label: {
        transform: 'translate(9px, 2px) scale(0.75) !important',
        color: theme.palette.warmGray2,
        fontSize: '14px',
        paddingTop: '4px',
        '&:focus': {
            color: theme.palette.warmGray2
        }
    },
    select: {
        paddingLeft: '8px',
        '&:focus': {
            backgroundColor: 'transparent',
        },
        borderRadius: '3px'
    },
    expandIcon: {
        color: theme.palette.silver,
        marginRight: '1vw'
    },
})

export const tooltipStyles = theme => ({
    tooltip: {
        fontSize: '12px !important',
        whiteSpace: 'pre-line',
        textAlign: 'left'
    }
})

export const textFieldStyles = theme => ({
    /*Styles for input*/
    textFieldRoot: {
        border: 'solid 1px #bfc5d2'
    },

    textFieldInput: {
        fontSize: '14px !important'
    },

    textFieldInputSansLabel: {
        padding: '1vh 12px !important',
        fontSize: '15px'
    },

    label: {
        fontSize: ' 14px !important'
    },

    inputMargin: {
        marginTop: '1vh!important',
        marginBottom: ' 1vh!important'
    },

    disabledText: {
        backgroundColor: '#dedede !important',
        borderRadius: '5px!important',
        cursor: 'not-allowed !important'
    }

})