/*
 UploadFilesDialog component
 url match: / or /login
 Displays email id and password field
 with UploadFilesDialog button
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { uploadStyles } from '../../Styles/commonStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FileDrop } from 'react-file-drop';
import UploadedFiles from './UploadedFiles';
import SimpleButton from '../../UIComponents/SimpleButton';
import CloseIcon from '@material-ui/icons/Close';

const acceptedFiles = [
  'image/png',
  'image/jpeg',
  'application/zip',
  'application/pdf',
  'application/x-zip-compressed',
  'image/tiff',
  '.tif',
];

class UploadFilesDialog extends React.Component {
  state = {
    showUploadStatus: false,
  };
  constructor() {
    super();
    this.inputOpenFileRef = React.createRef();
    this.zipInputFileRef = React.createRef();
  }

  closeDialog = () => {
    this.props.dashboardStore.closeUploadDialog();
    this.props.dashboardStore.resetFiles();
    this.setState({
      showUploadStatus: false,
    });
  };

  openFileModal = (zip) => {
    if (zip) this.zipInputFileRef.current.click();
    else this.inputOpenFileRef.current.click();
  };
  handleDrop = (files, event) => {
    event.preventDefault();
    if (!files.length) return;
    if (!this.validateFiles(files)) {
      this.props.commonStore.handleOpenSnackBar(
        'Only zip, PDF and image files are allowed!',
        'info'
      );
      return;
    }
    this.props.dashboardStore.setUploadFiles(files);

    this.setState({
      showUploadStatus: true,
    });
  };

  removeFile = (index) => {
    this.props.dashboardStore.removeFile(index);
  };

  uploadFiles = () => {
    if (this.props.dashboardStore.files.length > 10) {
      this.props.commonStore.handleOpenSnackBar(
        'A maximum of 10 files is allowed for each upload!!',
        'info'
      );
      return;
    }
    this.props.dashboardStore.uploadFiles(false).then((response) => {
      // if (!this.props.dashboardStore.uploadProgress)
      //   this.props.history.push('/');
    });
  };

  validateFiles = (files) => {
    let temp = [];
    temp = temp.concat(Array.from(files));
    let valid = true;

    temp.forEach((file) => {
      if (acceptedFiles.indexOf(file.type.toLowerCase()) < 0) valid = false;
    });
    return valid;
  };

  onChangeFile = (event, type) => {
    event.stopPropagation();
    event.preventDefault();
    var files = event.target.files;
    if (!files.length) return;
    if (!this.validateFiles(files)) {
      this.props.commonStore.handleOpenSnackBar(
        'Only zip, PDF and image files are allowed!',
        'info'
      );
      return;
    }
    this.props.dashboardStore.setUploadFiles(files);
    this.setState({
      showUploadStatus: true,
    });
  };

  cancelUpload = () => {
    this.props.dashboardStore.cancelUpload();
  };

  render() {
    const {
      files,
      openUploadDialog,
      uploadProgress,
      uploadIcon,
      uploadedFolders,
    } = this.props.dashboardStore;
    const { classes } = this.props;
    const { showUploadStatus } = this.state;
    return (
      <Dialog
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        classes={{ paper: classes.dialog }}
        open={openUploadDialog}
        onClose={this.closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <div className={classes.uploadDialog}>
            <div className={classes.uploadTitle}>
              <div>Upload Invoices</div>
              <CloseIcon
                onClick={(e) => this.cancelUpload()}
                fontSize="small"
              />
            </div>
            {uploadIcon === 'close' ? (
              <div className={classes.fileDropContainer}>
                <FileDrop onDrop={this.handleDrop}>
                  <div style={{ fontSize: '16px' }}>Drop Files Here</div>
                  <div style={{ lineHeight: '2.5' }}> or</div>
                  <div className="rowFlex flexCentered">
                    <SimpleButton
                      style={{ backgroundColor: '#4C3CFC', padding: '1vh 2vw' }}
                      handleClick={(e) => this.openFileModal(false)}
                      label="Choose Files"
                    />
                    <input
                      multiple
                      accept=".zip,.pdf,image/*"
                      type="file"
                      style={{ display: 'none' }}
                      ref={this.inputOpenFileRef}
                      onChange={(e) => this.onChangeFile(e, false)}
                    />
                  </div>
                </FileDrop>
              </div>
            ) : null}
            <br />
            {true && (files.length || uploadedFolders.length) ? (
              <UploadedFiles></UploadedFiles>
            ) : null}
            {showUploadStatus && files.length ? (
              !uploadProgress && uploadIcon === 'close' ? (
                <div className={classes.buttonContainer}>
                  <SimpleButton
                    label="Upload"
                    handleClick={(e) => this.uploadFiles()}
                  />
                </div>
              ) : null
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
export default withStyles(uploadStyles, { withTheme: true })(
  withRouter(
    inject('dashboardStore', 'commonStore')(observer(UploadFilesDialog))
  )
);
