import styled from 'styled-components/macro';
import { Icon } from '@blueprintjs/core';

import { FlexContainer } from '@/components/Themed';

const VerifiedWrapper = styled(FlexContainer.withComponent('div'))`
  max-width: 150px;
  height: 40px;
  align-items: center;
  color: var(--green60);
`;

const Verified = () => {
  return (
    <VerifiedWrapper>
      <Icon icon="tick-circle" iconSize={24} />
      &nbsp;&nbsp;Verified
    </VerifiedWrapper>
  );
};

export default Verified;
