import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setSingleGstnUser } from '@/actions/authActions';
import { useEffect, useRef } from 'react';
// import Notification from 'assets/images/notification.png';
import DownArrow from 'assets/images/down_arrow.png';
import Icon from 'assets/images/upload-icon1.png';
import { useState } from 'react';
import MenuItem from '@setproduct-ui/Menu/MenuItem';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

const NavMenus = ({
  open,
  setOpen,
  items,
  optionVal,
  title,
  userMenu,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showOpts, setShowOpts] = useState(false);

  const itemsRef = useRef();

  const handleClickOutside = (event) => {
    if (event.target && event.target.className) {
      if (
        (itemsRef.current && itemsRef.current.contains(event.target)) ||
        event.target.className === 'user-menu' ||
        event.target.className === 'ham' ||
        event.target.className === 'select-wrap' ||
        (event.target.className.includes &&
          event.target.className.includes('cursor')) ||
        (event.target.className.includes &&
          event.target.className.includes('down-arrow'))
      ) {
        return;
      }
      if (open) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [open]);

  return (
    open && (
      <div className="user-menu" ref={(ref) => (itemsRef.current = ref)}>
        <div className="head-sec flex-center">
          <span className="flex-circle">{title?.[0]}</span>
          <span className="grey">{title}</span>
          <div className="flex-between grey w70">
            {userMenu ? (
              <>
                <span>{optionVal}</span>
                {/* <img src={DownArrow} alt="down-arrow" width="10" height="6" /> */}
              </>
            ) : (
              <div className="flex-between w100 p-relative">
                <span
                  className="cursor d-block w100"
                  onClick={() => {
                    setShowOpts((bool) => !bool);
                  }}
                >
                  {optionVal}
                </span>
                <img
                  className="cursor"
                  src={DownArrow}
                  alt="down-arrow"
                  width="10"
                  height="6"
                  onClick={() => {
                    setShowOpts((bool) => !bool);
                  }}
                />
                <ul className={showOpts ? 'gst-opts' : 'd-none'}>
                  {props?.gstn &&
                    props?.gstn?.map((item) => {
                      return (
                        <li
                          className="cursor"
                          onClick={() => {
                            dispatch(setSingleGstnUser(item.gstn));
                            setOpen(false);
                            setShowOpts(false);
                          }}
                        >
                          {item.gstn}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="content-sec">
          <ul>
            {items.map((ele) => {
              return !ele.component ? (
                <li
                  className="cursor"
                  onClick={() => {
                    if (ele.reportId === 'OCR') {
                      history.push('/report/OCR', {
                        singleGstn: props.singleGstn,
                      });
                      return;
                    }
                    if (ele.nmLink) {
                      history.push(ele.to);
                    } else {
                      history.push(
                        `/reports/${ele.reportId}?metadata=${window.btoa(
                          ele.reportUrl
                        )}`,
                        {
                          reportUrl: ele.reportMainUrl,
                          reportId: ele.reportId,
                        }
                      );
                    }
                    setOpen(false);
                  }}
                >
                  <img
                    src={ele.src ? ele.src : Icon}
                    width={20}
                    height={20}
                    alt={'icon'}
                  />{' '}
                  {ele.label}
                </li>
              ) : (
                <span className={ele.className} onClick={props.onLogoutClick}>
                  {ele.component}
                </span>
              );
            })}
          </ul>
        </div>
      </div>
    )
  );
};

const userItems = [
  // {
  //   label: 'Organisation Information',
  //   src: Icon,
  //   to: '/dashboards',
  //   nmLink: true,
  // },
  // {
  //   label: 'Users and Access',
  //   to: '/dashboards',
  //   nmLink: true,
  //   src: Icon,
  // },
  // {
  //   label: 'Settings',
  //   nmLink: true,
  //   to: '/dashboards',
  //   src: Icon,
  // },
  {
    className: 'bottom',
    component: (
      <MenuItem
        type="def"
        view="smooth"
        color="danger"
        icon="log-out"
        text="Log out"
      />
    ),
  },
];

const gstnItems = [
  {
    label: 'Bulk Upload',
    to: '/upload',
    src: Icon,
    nmLink: true,
  },
  {
    label: 'Link To GST2A',
    to: '/gstPage',
    src: Icon,
    nmLink: true,
  },
];

const NavLinks = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showGstMenu, setShowGstMenu] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    axios
      .get('http://65.2.101.143:8080/products')
      .then((res) => {
        if (res.data) {
          setProducts(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (localStorage.getItem('accessToken')) {
      let metaData = jwt_decode(localStorage.getItem('accessToken'))?.[
        'https://shopelect.gstinput.com/user_metadata'
      ];
      setMetadata(metaData);
    }
  }, []);

  useEffect(() => {
    if (props.singleGstn) {
      if (location.pathname === '/reports/D1') {
        history.push(
          `/reports/D1?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000010528407/4954011c2b5faf011e3b6c2e10840e2d?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      } else if (location.pathname === '/reports/D4') {
        history.push(
          `/reports/D4?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      } else if (location.pathname === '/reports/D3a') {
        history.push(
          `/reports/D3a?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000010539738/830821592c941aa9d4a9980bfd593068?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      } else if (location.pathname === '/reports/D1a' && props.isITR) {
        history.push(
          `/reports/D1a?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      } else if (location.pathname === '/reports/D1a') {
        history.push(
          `/reports/D1a?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000010540197/45a29e34021355b09ee40da40adf74d1?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      } else if (location.pathname === '/reports/SAP-Invoices') {
        history.push(
          `/reports/D1a?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000011672628/95948388ba085058441d543451a9d826?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      } else if (location.pathname === '/reports/SAP-Expense') {
        history.push(
          `/reports/D1a?metadata=${window.btoa(
            `https://analytics.zoho.in/open-view/103074000011207333/98b59bba11d7f4fd189c462899bb2617?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
          )}`
        );
      }
    }
    // eslint-disable-next-line
  }, [props.singleGstn]);

  const [reports, setReports] = useState([
    {
      reportId: 'D2',
      label: 'GSTR-2A',
      reportName: 'GSTR-2A',
      reportUrl: `https://analytics.zoho.in/open-view/103074000010528473/28ef5f5a5f63183ba45a80846bf4334f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
    },
    {
      reportId: 'D5a',
      label: 'E-way Bills',
      reportName: 'E-way Bills',
      reportUrl: `https://analytics.zoho.in/open-view/103074000010982350/1126eda44fd93152ae8edc603e5f38ce?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
      reportMainUrl: `https://analytics.zoho.in/open-view/103074000010982350/1126eda44fd93152ae8edc603e5f38ce?ZOHO_CRITERIA="Customer_GSTIN"='`,
    },
    {
      reportId: 'D3',
      label: 'Purchase Register',
      reportName: 'Purchase Register',
      reportUrl: `https://analytics.zoho.in/open-view/103074000010538714/b2f512c7856e60b32942c903df2da823?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
    },
    {
      reportId: 'OCR',
      label: 'OCR Invoices',
      reportName: 'OCR Invoices',
      reportUrl: `https://ocr.finkraft.ai/`,
    },
    {
      reportId: 'OCR',
      label: 'Staging OCR Invoices',
      reportName: 'Staging OCR Invoices',
      reportUrl: `https://stagingocr.finkraft.ai?gstn=${props?.singleGstn}`,
    },
    {
      reportId: 'SAP-Expense',
      label: 'SAP Expenses',
      reportName: 'SAP Expenses',
      reportUrl: `https://analytics.zoho.in/open-view/103074000011207333/98b59bba11d7f4fd189c462899bb2617?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
    },
    {
      reportId: 'D4',
      label: 'Vendors',
      reportName: 'Vendors',
      reportUrl: `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
    },
  ]);

  useEffect(() => {
    setReports([
      {
        reportId: 'D2',
        label: 'GSTR-2A',
        reportName: 'GSTR-2A',
        reportUrl: `https://analytics.zoho.in/open-view/103074000010528473/28ef5f5a5f63183ba45a80846bf4334f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        reportMainUrl: `https://analytics.zoho.in/open-view/103074000010528473/28ef5f5a5f63183ba45a80846bf4334f?ZOHO_CRITERIA="Customer_GSTIN"='`,
      },
      {
        reportId: 'D5a',
        label: 'E-way Bills',
        reportName: 'E-way Bills',
        reportUrl: `https://analytics.zoho.in/open-view/103074000010982350/1126eda44fd93152ae8edc603e5f38ce?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        reportMainUrl: `https://analytics.zoho.in/open-view/103074000010982350/1126eda44fd93152ae8edc603e5f38ce?ZOHO_CRITERIA="Customer_GSTIN"='`,
      },
      {
        reportId: 'D3',
        label: 'Purchase Register',
        reportName: 'Purchase Register',
        reportUrl: `https://analytics.zoho.in/open-view/103074000010538714/b2f512c7856e60b32942c903df2da823?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        reportMainUrl: `https://analytics.zoho.in/open-view/103074000010538714/b2f512c7856e60b32942c903df2da823?ZOHO_CRITERIA="Customer_GSTIN"='`,
      },

      {
        reportId: 'D1',
        label: 'OCR Invoices',
        reportName: 'OCR Invoices',
        reportUrl: `https://analytics.zoho.in/open-view/103074000010528407/4954011c2b5faf011e3b6c2e10840e2d?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
      },
      {
        reportId: 'OCR',
        label: 'Staging OCR Invoices',
        reportName: 'Staging OCR Invoices',
        reportUrl: `https://stagingocr.finkraft.ai?gstn=${props?.singleGstn}`,
      },
      {
        reportId: 'SAP-Expense',
        label: 'SAP Expenses',
        reportName: 'SAP Expenses',
        reportUrl: `https://analytics.zoho.in/open-view/103074000011207333/98b59bba11d7f4fd189c462899bb2617?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
      },
      {
        reportId: 'SAP-Invoices',
        label: 'SAP Invoices',
        reportName: 'SAP Invoice',
        reportUrl: `https://analytics.zoho.in/open-view/103074000011672628/95948388ba085058441d543451a9d826?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
      },
      {
        reportId: 'D4',
        label: 'Vendors',
        reportName: 'Vendors',
        reportUrl: `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        reportMainUrl: `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='`,
      },
    ]);
  }, [props?.singleGstn]);

  const finkraftNav = [
    {
      to: '/dashboards',
      label: 'Dashboard',
    },
    {
      to: `/reports/OCR?metadata=${window.btoa(
        `https://stagingocr.finkraft.ai?gstn=${props?.singleGstn}`
      )}`,
      label: 'Invoices',
    },
    {
      to: `/reports/D3a?metadata=${window.btoa(
        `https://analytics.zoho.in/open-view/103074000010539738/830821592c941aa9d4a9980bfd593068?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
      )}`,
      isDropdown: true,
      options: [
        {
          reportId: 'D3a',
          reportName: 'Invoices with PR and GSTR-2A',
          reportUrl: `https://analytics.zoho.in/open-view/103074000010526792/c781cbf68b2fef116ec60549abcf3c49?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        },
        {
          reportId: 'D3a',
          reportName: 'GSTR-2A with PR and Invoices',
          reportUrl: `https://analytics.zoho.in/open-view/103074000010538310/4b15aaf4a944da7a5a68b7408c22d56a?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        },
        {
          reportId: 'D3a',
          reportName: 'PR with Invoices and GSTR-2A',
          reportUrl: `https://analytics.zoho.in/open-view/103074000010539738/830821592c941aa9d4a9980bfd593068?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`,
        },
      ],
      label: 'Reconciliation',
    },
    {
      to: `/reports/D4a?metadata=${window.btoa(
        `https://analytics.zoho.in/open-view/103074000010540197/45a29e34021355b09ee40da40adf74d1?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
      )}`,
      label: 'Escalations',
    },
  ];

  const itrNav = [
    {
      to: '/itrFilling',
      label: 'ITR Filing',
    },
    {
      to: '/clients',
      label: 'Clients',
    },
    {
      to: `/reports/D1a?metadata=${window.btoa(
        `https://analytics.zoho.in/open-view/103074000010528764/9555756d2ba8a432c63cfb174e44387f?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
      )}`,
      label: 'Vendors',
    },
  ];
  const [showOpts, setShowOpts] = useState(false);

  const handleClickOutside = (event) => {
    if (
      event.target.className &&
      event.target.className.includes &&
      (event.target.className.includes('link-item') ||
        event.target.className.includes('btn-recon'))
    ) {
      return;
    }
    if (showOpts) {
      setShowOpts(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [showOpts]);

  return (
    <div className="flex-between w100">
      <div className="links">
        {props.isITR
          ? itrNav.map((item) => (
              <NavLink to={item.to} exact activeClassName="selected">
                {item.label}
              </NavLink>
            ))
          : finkraftNav.map((item) =>
              item.isDropdown ? (
                <div className="link-dropdown">
                  <button
                    className={`p-relative btn-recon ${
                      location.pathname.includes('/reports/D3a')
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => {
                      setShowOpts((bool) => !bool);
                    }}
                  >
                    {item.label}
                  </button>
                  <ul className={showOpts ? 'gst-opts' : 'd-none'}>
                    {item.options.map((item) => {
                      return (
                        <li
                          className="cursor link-item"
                          onClick={() => {
                            history.push(
                              `/reports/${item.reportId}?metadata=${window.btoa(
                                item.reportUrl
                              )}`,
                              {
                                reportName: item.reportName,
                              }
                            );
                            setShowOpts(false);
                          }}
                        >
                          {item.reportName}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <NavLink
                  to={item.to}
                  exact
                  activeClassName="selected"
                  onClick={() => {
                    setShowOpts(false);
                  }}
                >
                  {item.label}
                </NavLink>
              )
            )}
      </div>
      <div className="flex-around">
        {/* <div className="notification-wrap">
          <img src={Notification} width={10} height={12} alt="notify" />
        </div> */}
        {Cookies.get('isSSOLogin') && (
          <div
            className="sso-menu"
            onClick={() => {
              setShowProducts((bool) => !bool);
            }}
          >
            <span className="border-tl"></span>
            <span className="border-tr"></span>
            <span className="border-bl"></span>
            <span className="border-br"></span>
            {showProducts && (
              <div className="user-menu">
                {products &&
                  products.length > 0 &&
                  products.map((ele) => {
                    return metadata?.productAccess
                      ?.split(',')
                      .includes(ele.productCode) ? (
                      <div>
                        <span
                          className="title"
                          onClick={() => {
                            if (ele.siteUrl) {
                              window.open(ele.siteUrl, '_blank');
                            }
                          }}
                        >
                          {ele.productName}
                        </span>
                        <span>{ele.productDescription}</span>
                      </div>
                    ) : null;
                  })}
              </div>
            )}
          </div>
        )}
        <div className="select-wrap p-relative gstn-sec">
          <div
            className="flex-center cursor"
            onClick={() => {
              setShowUserMenu(false);
              setShowGstMenu((bool) => !bool);
            }}
          >
            <span className="ham">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span
              className="cursor"
              style={{ paddingRight: '15px', paddingLeft: '10px' }}
            >
              {props.singleGstn}
            </span>
          </div>

          <NavMenus
            open={showGstMenu}
            setOpen={setShowGstMenu}
            title={localStorage.getItem('email')}
            optionVal={props.singleGstn}
            items={[...reports, ...gstnItems]}
            {...props}
          />
        </div>
        <div className="select-wrap p-relative">
          <span
            className="ham cursor"
            style={{ paddingBottom: '3px' }}
            onClick={() => {
              setShowUserMenu((bool) => !bool);
              setShowGstMenu(false);
            }}
          >
            {localStorage.getItem('email')?.[0]}
          </span>
          <span
            className="name cursor"
            style={{ marginBottom: '3px' }}
            onClick={() => {
              setShowUserMenu((bool) => !bool);
              setShowGstMenu(false);
            }}
          >
            {localStorage.getItem('email')}
          </span>
          <img
            className="down-arrow"
            src={DownArrow}
            alt="down-arrow"
            width={10}
            height={7}
            onClick={() => {
              setShowUserMenu((bool) => !bool);
              setShowGstMenu(false);
            }}
          />

          <NavMenus
            open={showUserMenu}
            setOpen={setShowUserMenu}
            items={userItems}
            title={localStorage.getItem('email')}
            // optionVal={'PAN - FLPSF7483O'}
            userMenu={true}
            onLogoutClick={props.onLogoutClick}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isITR: state.isITR,
    gstn: state.auth.gstn,
    singleGstn: state.auth.singleGstn,
  };
};
export default connect(mapStateToProps)(NavLinks);
