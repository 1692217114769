import React from 'react';
import './input.css';

const Input = (props) => {
  return (
    <div className="input-wrap">
      <label>{props.label}</label>
      <input type={props.type} {...props} />
    </div>
  );
};

export default Input;
