import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { showLoader, hideLoader } from '@/actions/loaderActions';
import { setLoggedInUser } from '@/actions/authActions';

import auth from '@/services/authService';

const useSilentLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async function () {
      if (auth.isTokenValid()) {
        dispatch(showLoader());
        const localAccessToken = localStorage.getItem('accessToken');
        const user = await auth.getUserInfo({
          accessToken: localAccessToken,
        });
        dispatch(
          setLoggedInUser({
            didSilentLogin: true,
            accessToken: localAccessToken,
            emailId: user.email,
            auth0Result: {},
            auth0User: user,
          })
        );
        if (location.pathname === '/' || location.pathname === '/login') {
          history.push('/dashboards');
        }
        dispatch(hideLoader());
      } else {
        const referrer = localStorage.getItem('referrer');
        localStorage.clear();
        if (referrer) {
          localStorage.setItem('referrer', referrer);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useSilentLogin;
