import { Suspense, useState, useMemo, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  // useHistory,
  useLocation,
  BrowserRouter as Router,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Loader } from '@/components/Loader';
import ProtectedRoute from '@/components/ProtectedRoute';
import ToastNotification from '@/components/ToastNotification';
import Navbar from '@/components/Navbar';
import NavLinks from './components/NavLinks';
import LoginContainer from './components/login_new/Login';
import CallbackContainer from '@/containers/CallbackContainer';

import ToastNoficationContext from './contexts/ToastNotificationContext';

import SilentLogin from './components/SilentLogin';
import DashboardContainer from './containers/DashboardContainer';
import UploadContainer from './containers/UploadContainer';
// import ReportsListContainer from './containers/ReportsListContainer';
import ReportContainer from './containers/ReportContainer';
import DownloadGst2aData from './containers/DownloadGst2aData';
import {
  isValidToken,
  setGstnUser,
  setSingleGstnUser,
} from './actions/authActions';
import SignUp from './components/login_new/SignUp';
import Home from './components/home/Home';
import GstPage from './components/gstPage/GstPage';
import ItrFilling from './components/itrFilling/ItrFilling';
import auth from '@/services/authService';
import * as Cookies from 'js-cookie';
import './index.css';
import SapLogin from './components/sap/SapLogin';
import SapLoginAfter from './components/sap/SapLoginAfter';
import GBCode from './gb_code';
import authService from '@/services/authService';
import SapPDF from './components/sap/SapPDF';

const queryClient = new QueryClient();

const App = () => {
  const [toastRefs, setToastRefs] = useState({
    errorToastRef: null,
    successToastRef: null,
    warningToastRef: null,
  });

  const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  const showLoader = useSelector((state) => state.showLoader);

  const toastProviderValues = useMemo(
    () => ({
      toastRefs,
      setToastRefs,
    }),
    [toastRefs]
  );

  useEffect(() => {
    let gstns = localStorage.getItem('gstn')
      ? JSON.parse(localStorage.getItem('gstn'))
      : '';
    if (gstns) {
      dispatch(setGstnUser(gstns));
      dispatch(setSingleGstnUser(gstns?.[0]?.gstn));
    }
    if (
      Cookies.get('accessToken') &&
      Cookies.get('email') &&
      Cookies.get('expiresAt') &&
      Cookies.get('isSSOLogin')
    ) {
      localStorage.setItem('accessToken', Cookies.get('accessToken'));
      localStorage.setItem('email', Cookies.get('email'));
      localStorage.setItem('expiresAt', Cookies.get('expiresAt'));
      // history.push(`/dashboards`);
    }
    dispatch(isValidToken(authService.isTokenValid()));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    if (Cookies.get('isSSOLogin') == 'false') {
      authService.logOut();
      // history.push('/login');
    }
    dispatch(isValidToken(authService.isTokenValid()))

    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <ToastNoficationContext.Provider value={toastProviderValues}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <SilentLogin />
              {/* <Navbar> */}
              {!location.pathname.includes('login') && (
                <Navbar>
                  <div>
                    <NavLinks
                      onLogoutClick={() => {
                        auth.logOut();
                        Cookies.remove('accessToken', {
                          domain: '.finkraft.ai',
                        });
                        Cookies.remove('email', {
                          domain: '.finkraft.ai',
                        });
                        Cookies.remove('expiresAt', {
                          domain: '.finkraft.ai',
                        });
                        Cookies.set('isSSOLogin', false, {
                          domain: '.finkraft.ai',
                        });
                      }}
                    />
                  </div>
                </Navbar>
              )}
              {/* </Navbar> */}
              <Switch>
                <Redirect from="/" exact to="/login" />
                <Route path="/login" exact component={LoginContainer} />
                <Route path="/callback" exact component={CallbackContainer} />
                <Route path="/signup" exact component={SignUp} />
                <Route path="/sapLogin" exact component={SapLogin} />
                <Route path="/SAPConcurbrochure" exact component={SapPDF} />
                <Route
                  path="/sapDashboard/:gstin_id"
                  exact
                  component={SapLoginAfter}
                />
                <ProtectedRoute
                  path="/itrFilling"
                  exact
                  component={ItrFilling}
                />
                <ProtectedRoute
                  path="/dashboards"
                  exact
                  component={DashboardContainer}
                />
                <ProtectedRoute path="/gstPage" exact component={GstPage} />
                <ProtectedRoute path="/home" exact component={Home} />
                <ProtectedRoute
                  path="/upload"
                  exact
                  component={UploadContainer}
                />
                {/* <ProtectedRoute
                  exact
                  path="/document/:documentId"
                  component={GBCode}
                /> */}
                <Route exact path="/extDocument/:docId" component={GBCode} />
                {/* <ProtectedRoute exact path="/report/OCR" component={GBCode} /> */}

                <ProtectedRoute
                  path="/reports/:id"
                  exact
                  component={ReportContainer}
                />
                <ProtectedRoute
                  path="/downloads/gst2a"
                  exact
                  component={DownloadGst2aData}
                />
              </Switch>
              <ToastNotification />
            </Router>
          </QueryClientProvider>
        </ToastNoficationContext.Provider>
      </Suspense>
      {showLoader && <Loader />}
    </>
  );
};

export default App;
