import { useState, useContext, useEffect, useCallback } from 'react';

import ToastNotificationContext from '@/contexts/ToastNotificationContext';

const useSuccessToast = () => {
  const [successToastRef, setSuccessToastRef] = useState(null);

  const { toastRefs } = useContext(ToastNotificationContext);

  useEffect(() => {
    if (toastRefs.successToastRef) {
      setSuccessToastRef(toastRefs.successToastRef);
    }
  }, [toastRefs]);

  const showToast = useCallback(
    ({ message }) => {
      successToastRef.show({ message });
    },
    [successToastRef]
  );

  return {
    showToast,
  };
};

export default useSuccessToast;
