import styled from 'styled-components/macro';
import tickImg from '../assets/images/tick.png';
// import { Icon } from '@blueprintjs/core';
import Button from '@setproduct-ui/Button';
import { Link, useHistory } from 'react-router-dom';

const DashboardModalContainer = styled.div`
  background: #fff;
  padding: 2% 12%;
  width: 100%;
  height: auto;
`;

const DashboardModalIntroCont = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 20px 0;
  h2 {
    width: 32%;
    margin: 0;
    color: #474186;
    font-size: 28px;
    line-height: 34px;
  }
  p {
    width: 40%;
    margin: 0;
    color: #2a254f;
    font-size: 10px;
    line-height: 16px;
  }
`;

const CloseBtn = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  right: 4%;
  top: 2%;
  cursor: pointer;
  .close__Btn {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 22px;
    position: absolute;
    right: 4%;
    top: 2%;
    cursor: pointer;
    background: none;
    color: #000;
    box-shadow: none;
  }
  .close__Btn:hover {
    background: none;
    color: #000;
    box-shadow: none;
  }
`;

const DashboardModalCardCont = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  padding: 20px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid #ddd;
  .step__infoHead {
    width: 15%;
    margin: 0;
    color: #474186;
  }
  .step__infoHead h3 {
    font-size: 26px;
    line-height: 32px;
  }
  .step__infoCont {
    width: 70%;
    margin: 0;
    color: #2a254f;
  }
  .step__infoCont h5 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }
  .step__infoBtns {
    width: 15%;
    margin: 0;
    color: #2a254f;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .fill__button {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 8px;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000;
    background: #b2f3dc;
    border: 1px solid #b2f3dc;
    border-radius: 0;
    cursor: pointer;
    box-shadow: none;
  }
  .fill__button:hover {
    background: #fff;
    border-color: #000;
  }
  .oline__button {
    width: 100%;
    margin: 0;
    padding: 8px;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0;
    cursor: pointer;
    box-shadow: none;
  }
  .oline__button:hover {
    background: #b2f3dc;
    border-color: #b2f3dc;
  }
  .fill__button span {
    padding: 0;
    font-size: 10px;
    line-height: 14px;
  }
  .oline__button span {
    padding: 0;
    font-size: 10px;
    line-height: 14px;
  }
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;
  }
  .imgBox {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #b2f3dc;
    padding: 2px;
    margin: 0;
  }
  .imgBox img {
    width: 10px;
    object-fit: contain;
    object-position: center center;
  }
  .imgBox2 {
    background: #9e9daa;
    color: #fff;
    font-size: 10px;
  }
  span {
    font-size: 12px;
    line-height: 18px;
    padding: 0 0 0 10px;
  }
  .anch_link {
    text-decoration: none;
    border-radius: 0;
  }
`;

const DashboardModal = (props) => {
  const { modalToggle } = props;
  const history = useHistory();
  return (
    <DashboardModalContainer>
      <DashboardModalIntroCont>
        <h2>Welcome to Finkraft !</h2>
        <p>
          Forward/Upload Invoices, Purchase Register and Connect your GST2A
          account to do the real time reconciliations and Verification of
          E-Invoices
        </p>
      </DashboardModalIntroCont>
      <DashboardModalCardCont>
        <div className="step__infoHead">
          <h3>STEP 1</h3>
        </div>
        <div className="step__infoCont">
          <h5>Upload or forward your Invoices to portal</h5>
          <ul>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>Know who are eligible vendor for e-invoicing</span>
            </li>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>
                Decrypted purchase data with NIC sign who have send invoices
                with QR Code
              </span>
            </li>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>Know which all vendors defaulted in filing last GSTR</span>
            </li>
            <li>
              <div className="imgBox imgBox2">i</div>
              <span>
                Forward your invoices to our i…@finkraft.ai (Subscribe to
                Enterprise Plan)
              </span>
            </li>
          </ul>
        </div>
        <div className="step__infoBtns">
          <Link to="/upload" className="anch_link">
            <Button
              type="default"
              className="fill__button"
              dense={true}
              text="Upload Invoices"
            />
          </Link>
          {/* <Link to="/upload" className="anch_link">
            <Button
              type="default"
              className="oline__button"
              dense={true}
              text="Enter Credentials"
            />
          </Link> */}
          {/* <button className="fill__button">Upload Invoices</button>
          <button className="oline__button">Enter credentials</button> */}
        </div>
      </DashboardModalCardCont>
      <DashboardModalCardCont>
        <div className="step__infoHead">
          <h3>STEP 2</h3>
        </div>
        <div className="step__infoCont">
          <h5>Upload vendor Master - GSTIN, Vendor Name and other details</h5>
          <ul>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>Send Auto email to default vendor</span>
            </li>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>Follow-up on missing and match invoice</span>
            </li>
          </ul>
        </div>
        <div className="step__infoBtns">
          <Button
            type="default"
            className="fill__button"
            dense={true}
            text="Upload"
            onClick={() => {
              history.push('/upload', {
                tab: 3,
              });
            }}
          />
        </div>
      </DashboardModalCardCont>
      <DashboardModalCardCont>
        <div className="step__infoHead">
          <h3>STEP 3</h3>
        </div>
        <div className="step__infoCont">
          <h5>Link your Finkraft to GST Portal for GST2A data</h5>
          <ul>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>
                Set-up automatic download of GST2A data from GST portal
              </span>
            </li>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>Ensure you claim ITC</span>
            </li>
          </ul>
        </div>
        <div className="step__infoBtns">
          <Button
            type="default"
            className="fill__button"
            dense={true}
            text="Upload"
            onClick={() => {
              history.push('/upload', {
                tab: 2,
              });
            }}
          />

          {/* <button className="oline__button">Link to GST Portal</button> */}
        </div>
      </DashboardModalCardCont>
      <DashboardModalCardCont>
        <div className="step__infoHead">
          <h3>STEP 4</h3>
        </div>
        <div className="step__infoCont">
          <h5>Upload ERP Purchase Data</h5>
          <ul>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>Reconcile your invoices with the ERP Purchase data</span>
            </li>
            <li>
              <div className="imgBox">
                <img src={tickImg} alt="tick" />
              </div>
              <span>3-Way Reconciliation between GST2A, PR and Invoices</span>
            </li>
          </ul>
        </div>
        <div className="step__infoBtns">
          <Button
            type="default"
            className="fill__button"
            dense={true}
            text="Upload"
            onClick={() => {
              history.push('/upload', {
                tab: 1,
              });
            }}
          />

          <button
            className="oline__button"
            onClick={() => {
              history.push(
                `/reports/D3a?metadata=${window.btoa(
                  `https://analytics.zoho.in/open-view/103074000010539738/830821592c941aa9d4a9980bfd593068?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
                )}`
              );
            }}
          >
            View Report
          </button>
        </div>
      </DashboardModalCardCont>
      <CloseBtn>
        <Button
          type="default"
          onClick={() => modalToggle(false)}
          dense={true}
          Icon
          className="close__Btn"
        >
          {/* <Icon icon="close" iconSize={16} /> */}X
        </Button>
      </CloseBtn>
    </DashboardModalContainer>
  );
};

export default DashboardModal;
