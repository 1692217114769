import {
  SET_LOGGEDIN_USER,
  LOGOUT_USER,
  SET_GSTN_USER,
  SET_SINGLE_GSTN_USER,
  IS_VALID_TOKEN,
} from '@/actions/authActions';

const initialState = {
  showLoginModal: false,
  isUserLoggedIn: false,
  didSilentLogin: false,
  accessToken: '',
  emailId: '',
  auth0Result: {},
  auth0User: {},
  error: '',
  isValid: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GSTN_USER: {
      return { ...state, gstn: action.payload };
    }
    case SET_SINGLE_GSTN_USER: {
      return { ...state, singleGstn: action.payload };
    }
    case SET_LOGGEDIN_USER:
      return Object.assign({}, state, {
        isUserLoggedIn: true,
        didSilentLogin: action.payload.didSilentLogin,
        accessToken: action.payload.accessToken,
        emailId: action.payload.emailId,
        auth0Result: action.payload.auth0Result,
        auth0User: action.payload.auth0User,
      });

    case LOGOUT_USER:
      return { ...state, ...initialState };
    case IS_VALID_TOKEN:
      return {
        ...state,
        isValid: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
