import Axios from 'axios';
import config from '@/utils/config';
const isProductionServer = window.location.hostname !== 'localhost';
export const axiosInstance = Axios.create({
  baseURL: config.apiBaseUrl,
  timeout: config.apiTimeout,
});

export const axiosInstance2 = Axios.create({
  baseURL: isProductionServer
    ? 'http://20.198.2.30:3001'
    : 'http://localhost:3001',
  timeout: config.apiTimeout,
});
