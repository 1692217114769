import { makeAutoObservable } from 'mobx';

export class CommonStore {
  snackbarOptions = {
    open: false,
    message: '',
  };
  token;
  unauthorized = false;

  constructor() {
    makeAutoObservable(this);
    this.token = window.localStorage.getItem('accessToken');
  }

  setToken(token) {
    this.token = token;
    if (token === undefined) this.unauthorized = true;
    else this.unauthorized = false;
  }

  handleOpenSnackBar(message, type) {
    this.snackbarOptions.open = true;
    this.snackbarOptions.message = message;
    this.snackbarOptions.type = type;
  }

  handleCloseSnackBar() {
    this.snackbarOptions.open = false;
    this.snackbarOptions.message = '';
  }

  modifyText = (text) => {
    let temp = [];
    text.split('_').length &&
      text.split('_').forEach((word) => {
        word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        temp.push(word);
      });
    return temp.join(' ');
  };
}
export default new CommonStore();
