import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { hideLoader, showLoader } from '../actions/loaderActions';
import { useMemo } from 'react';

const ReportContainerWrapper = styled.div`
  width: 100vw;
  min-height: calc(100vh - 72px);

  display: grid;
  place-items: center;

  iframe {
    padding-top: 20px;
    position: absolute;
    border: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
  }
`;

const ReportContainer = (props) => {
  const [showLoading, setShowLoading] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  useEffect(() => {
    if (showLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [showLoading, dispatch]);

  useEffect(() => {
    if (!query.get('metadata')) {
      history.push('/reports');
      dispatch(hideLoader());
    }
  }, [query, history, dispatch]);

  useEffect(() => {
    if (query.get('qrMatchedWithGst')) {
      history.push(
        `/reports/D3a?metadata=${window.btoa(
          `https://analytics.zoho.in/open-view/103074000010526792/c781cbf68b2fef116ec60549abcf3c49?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
        )}`
      );
    }

    if (query.get('escalations')) {
      history.push(
        `reports/D4a?metadata=${window.btoa(
          `https://analytics.zoho.in/open-view/103074000010540197/45a29e34021355b09ee40da40adf74d1?ZOHO_CRITERIA="Customer_GSTIN"='${props?.singleGstn}'`
        )}`
      );
    }

    if (query.get('invoiceRedirect')) {
      history.push(`/reports/OCR?metadata=${window.btoa(
        `https://ocr.finkraft.ai?gstn=${props?.singleGstn}`
      )}`);
    }
    if (
      window.atob(query.get('metadata')).includes('https://ocr.finkraft.ai') ||
      window
        .atob(query.get('metadata'))
        .includes('https://stagingocr.finkraft.ai')
    ) {
      if (
        window
          .atob(query.get('metadata'))
          .includes('https://stagingocr.finkraft.ai')
      ) {
        history.push(
          `/reports/OCR?metadata=${window.btoa(
            `https://stagingocr.finkraft.ai?gstn=${props?.singleGstn}`
          )}`
        );
      } else {
        history.push(
          `/reports/OCR?metadata=${window.btoa(
            `https://ocr.finkraft.ai?gstn=${props?.singleGstn}`
          )}`
        );
      }
    }

    if (location?.state?.reportUrl) {
      history.push(
        `/reports/${location?.state?.reportId}?metadata=${window.btoa(
          `${location?.state?.reportUrl}${props.singleGstn}'`
        )}`,
        location.state
      );
    }
    // eslint-disable-next-line
  }, [props.singleGstn]);

  return (
    <div>
      <ReportContainerWrapper>
        <iframe
          title="report"
          src={window.atob(query.get('metadata'))}
          style={{
            marginTop: '60px',
          }}
          onLoad={() => {
            if (
              window
                .atob(query.get('metadata'))
                .includes('https://ocr.finkraft.ai') ||
              window
                .atob(query.get('metadata'))
                .includes('https://stagingocr.finkraft.ai')
            ) {
              let win =
                document.getElementsByTagName('iframe')[0].contentWindow;
              let token = localStorage.getItem('accessToken');
              let gstn = props.singleGstn;
              win.postMessage({ token, gstn }, '*');
              console.log('loaded');
            }

            setShowLoading(false);
          }}
        />
      </ReportContainerWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    singleGstn: state?.auth?.singleGstn,
  };
};
export default connect(mapStateToProps)(ReportContainer);
