import styled from 'styled-components/macro';

import downloadGst2aDataImage from '../assets/placeholders/download-gst2a-data.png';

const DownloadGst2aDataWrapper = styled.div`
  width: 100%;
  height: calc(100vh-72px);

  img {
    width: 100%;
    height: 100%;
  }
`;

const DownloadGst2aData = () => {
  return (
    <DownloadGst2aDataWrapper>
      <img alt="" src={downloadGst2aDataImage} />
    </DownloadGst2aDataWrapper>
  );
};

export default DownloadGst2aData;
