import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { documentStyles } from '../../../Styles/documentStyles';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ExpansionPanel } from '@material-ui/core';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import clsx from 'clsx';
class TabularData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationErrors: {},
    };
    this._areaElement = new Map();
    this._areaElementBack = new Map();
  }

  handleFormFieldChange = (value, index, fieldId) => {
    this.props.documentStore.updateTabularField(value, index, fieldId);
  };

  getFormFields = (row) => {
    const { validationErrors } = this.state;
    const { pageIndex } = this.props;
    const { currentDoc } = this.props.documentStore;
    return currentDoc.pageArray[pageIndex].tableData.map((item, index) =>
      item.row === row ? (
        <ListItem key={index} style={{ paddingTop: 0, marginTop: 0 }}>
          <TextField
            coords={item.pts.slice()}
            ref={(c) => this._areaElement.set(index, c)}
            onClick={(e) => this.setCurrentField(e, index, item)}
            color="primary"
            style={{ paddingTop: 0, marginTop: 0 }}
            required
            id={item.aiKey}
            key={item._id}
            label={item.aiKey}
            type={item.type}
            margin="normal"
            variant="filled"
            fullWidth
            multiline
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {
                    currentDoc.pageArray[pageIndex].tableData[index]
                      .progressIcon
                  }
                </InputAdornment>
              ),
            }}
            value={
              item.editedValue !== '' && item.editedValue !== undefined
                ? item.editedValue
                : item.aiValue
            }
            onChange={(event) =>
              this.handleFormFieldChange(
                event.target.value,
                index,
                item._id,
                item.editedValue !== '' ? 'editedValue' : 'aiValue'
              )
            }
          />

          {validationErrors[item.label] && (
            <FormHelperText id={item.label}>
              {validationErrors[item.label]}
            </FormHelperText>
          )}
        </ListItem>
      ) : null
    );
  };

  getHeaders = (item, index) => {
    // const { validationErrors } = this.state;
    const { classes } = this.props;
    // const { currentDoc } = this.props.documentStore;
    return item.aiKey === 'ItemCode' ? (
      <ListItem key={index} style={{ paddingTop: 0, marginTop: 0 }}>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          >
            <div>
              {item.editedValue !== '' && item.editedValue !== undefined
                ? item.editedValue
                : item.aiValue}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={clsx(classes.fullWidth, 'colFlex')}>
              {this.getFormFields(item.row)}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ListItem>
    ) : null;
  };

  getWriterCorpHeaders = (item, index) => {
    // const { validationErrors } = this.state;
    const { classes } = this.props;
    // const { currentDoc, tableHeaders } = this.props.documentStore;
    return item.rowHeader !== undefined ? (
      <ListItem key={index} style={{ paddingTop: 0, marginTop: 0 }}>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          >
            <div>{item.rowHeader}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={clsx(classes.fullWidth, 'colFlex')}>
              {this.getFormFields(item.row)}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ListItem>
    ) : null;
  };

  setCurrentField = (e, index, item) => {
    this.setState({
      currentField: index,
    });
    this.props.documentStore.setCurrentField(item, index);
    this.props.documentStore.setCurrentPage(this.props.pageIndex);
    this.props.highlight(e, index);
  };

  render() {
    const { pageIndex, docType } = this.props;
    const { currentDoc, disableCropping } = this.props.documentStore;
    return (
      <List>
        {currentDoc.pageArray[pageIndex].tableData.map((item, index) => {
          if (
            docType === 'WRITERCORP' ||
            disableCropping.findIndex((x) => x === docType) > -1
          )
            return this.getWriterCorpHeaders(item, index);
          else return this.getHeaders(item, index);
        })}
      </List>
    );
  }
}
export default withStyles(documentStyles, { withTheme: true })(
  withRouter(inject('documentStore', 'dashboardStore')(observer(TabularData)))
);
