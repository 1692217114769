import React from 'react';
import CustomSnackbar from './Common/CustomSnackbar';
import Home from './Components/Home';
import './App.css';


function App() {
  return (
    <div id="app-root">
      <Home />
      <CustomSnackbar></CustomSnackbar>
    </div>
  );
}

export default App;
