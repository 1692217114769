/*
  LabelField component
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { inputStyles } from '../../../Styles/documentStyles';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class LabelField extends React.Component {
  render() {
    const {
      classes,
      label,
      options,
      value,
      endAdornment,
      onChange,
      from,
    } = this.props;

    return (
      <div className="fullWidth">
        <div className={classes.invoiceLabel}>{label}</div>
        <div
          className={clsx({
            [`${classes.inputContainer}`]: true,
            [`${classes.fieldIpContainer}`]: from === 'dialog',
          })}
        >
          <Select
            value={value}
            fullWidth
            disableUnderline
            classes={{ root: classes.select }}
            onChange={onChange}
          >
            {options.map((row, index) => (
              <MenuItem
                key={'role' + index}
                className={classes.menuItems}
                value={row}
              >
                {' '}
                <div className={classes.item}>
                  <div className={classes.vertCenter}>{row}</div>
                </div>
              </MenuItem>
            ))}
          </Select>
          {endAdornment ? (
            <div className="colFlex flexCentered">
              {' '}
              <ReactSVG src={endAdornment} />{' '}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default withStyles(inputStyles, { withTheme: true })(
  withRouter(observer(LabelField))
);
