import { axiosInstance } from './axiosInstance';

export const checkUserExists = ({ email = null, mobileNumber = null }) => {
  const urlSearchParams = new URLSearchParams({
    ...(email && { email }),
    ...(mobileNumber && { mobileNumber }),
  }).toString();
  return axiosInstance.get(`/users/search/all?${urlSearchParams}`);
};

export const checkContactExists = ({ email = null, mobileNumber = null }) => {
  const urlSearchParams = new URLSearchParams({
    ...(email && { email }),
    ...(mobileNumber && { mobileNumber }),
  }).toString();
  return axiosInstance.get(`/users/search/contacts?${urlSearchParams}`);
};

export const checkAdminUserExists = ({ email = null, mobileNumber = null }) => {
  const urlSearchParams = new URLSearchParams({
    ...(email && { email }),
    ...(mobileNumber && { mobileNumber }),
  }).toString();
  return axiosInstance.get(`/users/search/admin-users?${urlSearchParams}`);
};
