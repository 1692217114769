import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
//import { isObjectEmpty } from '../../utils';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { commonStyles } from '../../Styles/commonStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Loader from 'react-loader-spinner';

class SubmitDialog extends React.Component {
  onClickOk = () => {
    this.props.submit();
    this.props.cancelSubmit();
  };

  onClickCancel = () => {
    this.props.cancelSubmit();
  };

  render() {
    const { updateProgress } = this.props.documentStore;
    const { classes } = this.props;
    return (
      <Dialog
        disableBackdropClick={false}
        disableEscapeKeyDown={true}
        classes={{ paper: classes.submitDialog }}
        open={this.props.open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Typography className={classes.dialogHeading}>
            Submit Review
          </Typography>
          <Typography className={classes.dialogSubHeading}>
            Are you sure you want to submit the review?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{ backgroundColor: '#acb4c6' }}
              variant="contained"
              className={classes.button}
              type="submit"
              onClick={(e) => this.onClickCancel()}
            >
              Cancel
            </Button>
            {updateProgress ? (
              <Button
                variant="contained"
                className={classes.button}
                type="submit"
              >
                <Loader
                  height={30}
                  width={30}
                  color={this.props.theme.palette.white}
                />
              </Button>
            ) : (
              <Button
                variant="contained"
                className={classes.button}
                type="submit"
                onClick={(e) => this.onClickOk()}
              >
                Submit
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
export default withStyles(commonStyles, { withTheme: true })(
  withRouter(inject('documentStore')(observer(SubmitDialog)))
);
