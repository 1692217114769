import { axiosInstance } from './axiosInstance';

export const getOtp = ({ emailId = null, mobileNumber = null }) => {
  const body = {
    ...(emailId && { channel: 'email' }),
    ...(emailId && { emailId }),
    ...(mobileNumber && { channel: 'sms' }),
    ...(mobileNumber && { mobileNumber }),
  };
  return axiosInstance.post('/twilio/start', body);
};

export const verifyOtp = ({
  emailId = null,
  mobileNumber = null,
  otp = null,
}) => {
  const body = {
    ...(emailId && { channel: 'email' }),
    ...(emailId && { emailId }),
    ...(mobileNumber && { channel: 'sms' }),
    ...(mobileNumber && { mobileNumber }),
    code: otp,
  };

  return axiosInstance.post('/twilio/verify', body);
};

export const registerNewUser = ({ form }) => {
  return axiosInstance.post('/register', form);
};
