import { withRouter } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { dialogStyles } from '../Styles/dialogStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
// import Loader from 'react-loader-spinner'
import SimpleButton from '../UIComponents/SimpleButton';
class DeleteDialog extends React.Component {
  onClickOk = (e) => {
    e.stopPropagation();
    this.props.delete();
    this.props.cancelDelete();
  };

  onClickCancel = (e) => {
    e.stopPropagation();
    this.props.cancelDelete();
  };

  render() {
    const { classes, title, message, inProgress } = this.props;
    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        classes={{ paper: classes.submitDialog }}
        open={this.props.open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <Typography className={classes.dialogHeading}>{title}</Typography>
          <Typography className={classes.dialogSubHeading}>
            {message}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className="rowFlex flexCentered fullWidth">
            <SimpleButton
              variant="cancel"
              label="Cancel"
              handleClick={(e) => this.onClickCancel(e)}
            />
            <SimpleButton
              label="OK"
              handleClick={(e) => this.onClickOk(e)}
              progress={inProgress}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

DeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  cancelDelete: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
export default withStyles(dialogStyles, { withTheme: true })(
  withRouter(observer(DeleteDialog))
);
