import React from 'react';
import SAPBrocher from '../../assets/Finkraft_Concur_Boucher.pdf';
const SapPDF = () => {
  return (
    <div className="sap_brocher">
      <embed src={SAPBrocher} />;
    </div>
  );
};

export default SapPDF;
