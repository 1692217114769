import LoginImg from '@/assets/images/loginImg.png';
// import finkraftLogo from '@/assets/logos/finkraft-logo.svg';
import SignupContainer from '@/containers/SignupContainer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './login.css';
import back_arrow from '@/assets/images/arrow_back.svg';

const SignUp = () => {
  const history = useHistory();
  return (
    <div className="d-grid signup-sec">
      <div className="item1 mAuto">
        <div className="login-right">
          <div>
            <SignupContainer />
            <span
              className="changeMb m-2"
              onClick={() => {
                history.push('/login');
              }}
            >
              <img src={back_arrow} alt="back_arrow" />
              Back To Login
            </span>
          </div>
        </div>
      </div>
      <div className="item2">
        <div className="login-left">
          <div className="login-text">
            <h3>
              Finkraft GST Software – Simplify your GST Reconciliation and know
              about your ITC
            </h3>
            <div className="bar"></div>
            <p>
              Finkraft GST makes reconciliation simple by automatically matching
              your purchase transactions with your GSTR 2A and validate the
              vendor e-invoices. All you have to do is to view the
              reconciliation data and download the Credit Reports.
            </p>
            <img src={LoginImg} alt={'login-img'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
