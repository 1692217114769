import { fade } from '@material-ui/core/styles';

export const commonStyles = theme => ({
  errorMsg: {
    fontWeight: 'bold',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  }
})

export const tableStyles = theme => ({
  selectAll:{
    cursor: 'pointer',
    color: theme.palette.purple,
    fontWeight: 'bold'
  },
  delIcon:{
    color: '#4d4d4d',
    cursor: 'pointer',
    marginRight: '1vw'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 1vh',
  },
  emptyList: {
    textAlign: 'center',
    borderBottom: 'none !important'
  },
  tableHeader: {
    backgroundColor: theme.palette.lightGray,
    color: theme.palette.tableHeader,
    padding: '1vh 16px',
    fontFamily: 'proxima_nova_rgregular',
    font: 'normal normal 600 14px/22px Proxima Nova',
    border: 'none',
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px'
    },
    '&:last-child': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px'
    }
  },
  tableHeaderRow: {
    boxShadow: '0px 3px 11px #0000000F',
    //  borderTop: '1px solid #EAEAEA',
    borderRadius: '12px'
  },
  tableRow: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 11px #0000000F',
    borderRadius: '12px',
  },
  tableData: {
    cursor: 'pointer',
    border: 'none',
    borderTop: '1px solid #EAEAEA',
    padding: '1vh 16px',
    fontFamily: 'proxima_nova_rgregular',
    borderBottom: '1px solid #EAEAEA',
    font: 'normal normal normal 14px/22px Proxima Nova',
    '&:first-child': {
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
      borderLeft: '1px solid #EAEAEA',
    },
    '&:last-child': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
      borderRight: '1px solid #EAEAEA',
    },
  },
  companyData: {
    maxWidth: '15vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fileName: {
    maxWidth: '10vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  checkbox: {
    fontSize: '1rem !important',
    color: '#BFBFBF !important'
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5vh 0vw'
  },
  pagination: {
    fontSize: '13px',
    color: '#525353',
    display: 'flex',

  },
  page: {
    borderRadius: '4px',
    border: '1px solid #B2B2B2',
    background: 'white 0% 0% no-repeat padding-box',
    width: '30px',
    height: '30px',
    margin: '0vh 0.2vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  currentPage: {
    backgroundColor: 'lightGray !important'
  },
  pageNo: {
    background: '#F5F7FA 0% 0% no-repeat padding-box'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1.5vh'
  },
  headerRight: {
    display: 'flex',
    flex: '2',
    justifyContent: 'flex-end'
  },
  sortBy: {
    display: 'flex',
    letterSpacing: '0.17px',
    fontSize: '13px',
    color: '#4B5660',
    border: '1px solid #D9D9D9',
    padding: '1vh 1vw',
    cursor: 'pointer',
    borderRadius: '4px',
    margin: '0vh 4px',
    width: 'fit-content',
  },
  searchPlaceholder: {
    color: '#7C7C7C',
    cursor: 'text'
  },
  searchContainer: {
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  docStatus: {
    borderLeft: 'solid',
    marginLeft: 'calc(-16px - 1vw)',
    marginRight: '16px'
  },
  searchIcon: {
    width: '12px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  selectedMenuItem: {
    color: '#58666E',
    fontSize: '14px',
    backgroundColor: '#C7D7FF',
    '&:hover': {
      backgroundColor: '#C7D7FF',
    },
  },
  menuItem: {
    color: '#58666E',
    fontSize: '14px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#C7D7FF',
    },
  },
  exportDisabled: {
    backgroundColor: 'lightGray',
    cursor: 'not-allowed'
  },
  filterMenuContainer: {
    minWidth: '5vw',

  },
  root: {
    width: 221,
    fontSize: 13,
  },
  button: {
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: '#586069',
    fontWeight: 600,
    '&:hover,&:focus': {
      color: '#0366d6',
    },
    '& span': {
      width: '100%',
    },
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: '.15em 4px',
    fontWeight: 600,
    lineHeight: '15px',
    borderRadius: 2,
  },
  popper: {
    boxShadow: '4px 4px 6px #00000029',
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  popperBorder: {
    border: '1px solid #C3C3C3'
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #C9C9C9',
      fontSize: 14,
      '&:focus': {
        border: '1px solid #C9C9C9',
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 14,
  },
  option: {
    minHeight: 'auto',
    padding: '0px',
    alignItems: 'flex-start',
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      // backgroundColor: theme.palette.action.hover,
    },
  },
  selectedOption: {
    backgroundColor: '#C7D7FF',
    width: '100%',
    padding: '1vh 1vw'
  },
  nOption: {
    padding: '1vh 1vw'
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },

})

export const snackbarStyles = theme => ({
  snackbar: {
    fontSize: '13px',
    borderRadius: '0px'
  },
  success: {
    backgroundColor: theme.palette.turquoise,
    borderRadius: '0px',
  },
  info: {
    backgroundColor: theme.palette.primaryGray,
    borderRadius: '0px',
  },
  error: {
    backgroundColor: theme.palette.red,
    borderRadius: '0px',

  }
})

export const uploadStyles = theme => ({
  fileDropContainer: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    padding: '20px 0vw',
    margin: '15px 0vw 0vh 0vw',
    color: '#2F2D51',
    lineHeight: '2'
  },
  fileCount:{
    color: '#2F2D51',
    fontSize: '12px',
    textAlign: 'left'
  },
  uploadTable:{
   
    overflowY: 'scroll'
  },
  uploadTitle: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#1A4671'
  },
  svg: {
    display: 'block',
    margin: 'auto',
    marginBottom: '3vh',
    marginTop: '5vh',
  },
  tableData: {
    fontSize: '13px',
    padding: '1vh',
    paddingLeft: '0px',
    color: '#2F2D51',
    borderColor: 'rgba(149,168,210, 0.12)',
  },
  docTable: {
    maxWidth: '85vw',
    backgroundColor: 'transparent',
  },
  dialog: {
    minWidth: '23vw',
    width: '55vw',
    maxHeight: '100vh',
    boxShadow: 'none'
  },
  backdrop: {
    background: '#000000 0% 0% no-repeat padding-box',
    opacity: '0.77 !important'
  },
  uploadDialog: {
    color: theme.palette.black,
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5vh 0 0 0'
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
});

