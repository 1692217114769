import React from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavbarSource from '@setproduct-ui/Navbar';
import NavbarGroup from '@setproduct-ui/Navbar/Group';

import finkraftLogowhite from '@/assets/logos/finkraftLogo-white.png';

const NavbarWrapper = styled.div`
  .bp3-navbar {
    padding: 0 60px;
    background: #2a254f;
    border-radius: 0;
    margin-right: 0;
  }

  a {
    text-decoration: none;
  }

  .logo-wrapper img {
    height: 35px;
  }
`;

const Navbar = ({ children }) => {
  // const isUserLoggedIn = useSelector((state) => state?.auth?.isUserLoggedIn);
  const location = useLocation();
  const { isValid: isUserLoggedIn } = useSelector((state) => state.auth);

  return isUserLoggedIn || location.pathname.includes('extDoc') ? (
    <NavbarWrapper>
      <NavbarSource type="desktop" view="smooth">
        <NavbarGroup className={'logo-wrapper'}>
          <Link to="/home">
            <img src={finkraftLogowhite} alt="finkraft-logo" width={130} />
          </Link>
        </NavbarGroup>

        {isUserLoggedIn && (
          <NavbarGroup className="links-wrapper">{children}</NavbarGroup>
        )}
      </NavbarSource>
    </NavbarWrapper>
  ) : null;
};

export default Navbar;
