import styled from 'styled-components/macro';

import { FlexColumnContainer, FlexRowContainer } from '@/components/Themed';

export const FormContainer = styled(FlexColumnContainer.withComponent('div'))`
  margin: 0 auto;
  width: 90%;
  height: auto;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
`;

export const FormRow = styled(FlexRowContainer.withComponent('div'))`
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

export const FormColumn = styled(FlexColumnContainer.withComponent('div'))`
  width: 100%;
  justify-content: center !important;
  align-items: flex-start;
  position: relative;
`;

export const FormRowAlt = styled(FlexRowContainer.withComponent('div'))`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;

  & > div.input-wrapper {
    margin-right: 20px;
  }
`;
