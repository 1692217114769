import Button from '@setproduct-ui/Button';

import { FormRow } from '@/components/FormUIElements';

const ButtonsRow = ({
  formSubmitted,
  submittingForm,
  emailOtpVerified,
  smsOtpVerified,
  gstinVerified,
  submitForm,
  resetForm,
  dispatch,
  ...props
}) => {
  return (
    <FormRow {...props}>
      <Button
        id="submit-button"
        icon={formSubmitted ? 'tick-circle' : null}
        type="default"
        view="smooth"
        color={formSubmitted ? 'success' : 'primary'}
        loading={submittingForm}
        onClick={() =>
          emailOtpVerified && smsOtpVerified && gstinVerified
            ? submitForm()
            : null
        }
        disabled={
          !emailOtpVerified ||
          !smsOtpVerified ||
          !gstinVerified ||
          formSubmitted
        }
      >
        {formSubmitted ? 'SUCCESS' : 'SIGN UP'}
      </Button>
      <Button
        type="default"
        view="smooth"
        color="default"
        rightIcon="reset"
        onClick={() => {
          resetForm();
          dispatch({ type: 'reset-form' });
        }}
        disabled={submittingForm}
      >
        Reset
      </Button>
    </FormRow>
  );
};

export default ButtonsRow;
