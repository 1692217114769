export const documentStyles = (theme) => ({
  formContainer: {
    width: 'calc(40% - 0vw)',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #EDF0F5',
  },
  errorContainer: {
    padding: '2vh 2vw',
    background: '#feecec 0% 0% no-repeat padding-box',
    border: '1px solid #EDF0F5',
    fontSize: '13px',
  },
  formSubmitContainer: {
    display: 'flex',
    margin: '2vh 1vw',
  },
  stageContainer: {
    display: 'flex',
    padding: '2vh 1vw',
    fontSize: '15px',
    background: '#F9FAFC 0% 0% no-repeat padding-box',
  },
  stageName: {
    display: 'flex',
    marginRight: '1vw',
  },
  accContainer: {
    margin: '0vh -1vw 1vh -1vw',
  },
  accRoot: {
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    background: '#F9FAFC 0% 0% no-repeat padding-box',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  accSummary: {
    border: '1px solid #EDF0F5 !important',
    minHeight: '64px !important',
  },
  accDetails: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 11px #0000000F',
    overflowY: 'scroll',
  },
  accFormContainer: {
    padding: '2vh 1vw',
    width: 'calc(100% - 2vw)',
    overflowX: 'hidden',
  },
  accHeading: {
    // padding: "0vh 1vw",
    color: '#121212',
    fontWeight: '600',
  },
  formStyle: {
    flex: '1',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  invoiceContainer: {
    display: 'flex',
    background: '#F9FAFC 0% 0% no-repeat padding-box',
    border: '1px solid #EDF0F5',
    borderRadius: '10px',
    marginLeft: '2vw',
    width: '65%',
    marginRight: '1vw',
    overflowY: 'auto',
  },
  imageDiv: {
    width: 'calc(100% - 4vw)',
    fontSize: '18px',
    height: '100vh',
    margin: '2vh 1vw',
  },
  zoomContainer: {
    position: 'fixed',
    top: '90%',
    right: '25%',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '0.5vh 1vw',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 12px #00000029',
    border: '1px solid #95989A',
  },
  imageContainer: {
    height: '100vh',
    width: 'calc(50% - 2vw)',
    position: 'fixed',
    backgroundColor: theme.palette.white,
    // overflowY: "scroll",
  },
  svg: {
    cursor: 'pointer',
    margin: '1vh 0.5vw',
  },

  //////
  expansionPanel: {
    width: '100%',
    backgroundColor: theme.palette.darkBlue,
    color: theme.palette.white,
    boxShadow: '0 2px 6px 0 rgba(16, 119, 255,  0.7)',
  },

  expandIcon: {
    color: theme.palette.white,
  },
  fullWidth: {
    width: '100%',
  },
  tableKey: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.brownishGray,
  },
  formField: {
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  tableHeader: {
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
    pointerEvents: 'none',
  },
  headerField: {
    backgroundColor: theme.palette.coolGray2,
    fontWeight: 'bold',
  },
  table: {
    width: '100%',
    padding: '0vh 1vw',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '16%',
      flexShrink: 0,
    },
  },
  thumbnailContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white,
    justifyContent: 'center',
    borderRadius: '5.3px',
    maxHeight: '80px',
  },
  thumbnailLabel: {
    color: '#2A254F',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '2',
  },

  pageAction: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5vh',
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  sidebarFlex: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    color: theme.palette.white,
    fontSize: '16px',
    fontFamily: 'Roboto',
    backgroundColor: theme.palette.blue,

    [theme.breakpoints.between('sm', 'md')]: {
      width: '20.6vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '11.6vw',
    },
    marginBottom: '2vh',
    marginLeft: '1vw',
    height: '44px',
  },

  submitButton: {
    color: theme.palette.white,
    fontSize: '16px',
    fontFamily: 'Roboto',
    backgroundColor: theme.palette.blue,

    [theme.breakpoints.between('sm', 'md')]: {
      width: '20.6vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '11.6vw',
    },
    marginBottom: '2vh',
    marginLeft: '1vw',
    height: '44px',
    marginTop: '4vh',
  },
  cancelButton: {
    color: theme.palette.coolGray,
    fontSize: '16px',
    fontFamily: 'Roboto',
    backgroundColor: theme.palette.dark,

    [theme.breakpoints.between('sm', 'md')]: {
      width: '20.6vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '11.6vw',
    },
    marginBottom: '2vh',
    marginLeft: '1vw',
    height: '44px',
    elevation: 0,
  },
  sidebarFooter: {
    justifyContent: 'space-between',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.white,
    fontSize: '12px',
  },
  outerLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px',
  },
  progress: {
    color: theme.palette.red,
  },
  images: {
    width: '100%',
    transformOrigin: '0 0',
  },
  thumbnails: {
    height: 'auto',
    width: '50px',
    maxHeight: '58px',
  },
  imageName: {
    fontSize: '10px',
    color: theme.palette.white,
    textAlign: 'center',
  },

  activeThumbnail: {
    height: '64px',
    width: '50px',
    border: '4px solid #9097a2',
    borderRadius: '5.3px',
  },
  docHeader: {
    color: theme.palette.white,
    fontSize: '18px',
  },
  navbarContainer: {},

  pageNo: {
    position: 'absolute',
    top: '5%',
    right: '10%',
    width: '40px',
    height: '20px',
    fontSize: '10px',
    backgroundColor: 'rgba(62, 70, 78, 0.6)',
    opacity: '0.9',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: theme.palette.white,
  },
  dropDownIcon: {
    color: theme.palette.coolGray2,
    position: 'absolute',
  },

  thumbnailIcons: {
    position: 'absolute',
    top: '50%',
    left: '33%',
  },
  closeIcon: {
    display: 'flex',
    flexDirection: 'row-reverse',
    color: theme.palette.coolGray,
  },
  loading: {
    top: '40%',
    right: '45%',
    position: 'fixed',
  },

  loaderDiv: {
    backgroundColor: theme.palette.dark,
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1vw',
  },

  navIcons: {
    marginRight: '2vw',
    cursor: 'pointer',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
    borderRadius: '4px',
    border: `solid 1px ${theme.palette.battleShipGray}`,
  },
});

export const inputStyles = (theme) => ({
  inputContainer: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    padding: '0.5vh 0vw 0.5vh 1vw',
    fontSize: '15px',
    margin: '1vh 0vw 2.3vh 0vw',
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  lowConfidence: {
    border: '1px solid #ff1717',
  },
  fieldIpContainer: {
    width: 'calc(100% - 2.5vw) !important',
  },
  invoiceLabel: {
    color: '#2A254F',
    fontSize: '15px',
    fontWeight: '500',
  },
  input: {
    width: 'calc(92% - 1vw)',
    paddingRight: '1vw',
  },
  select: {
    backgroundColor: 'white !important',
    '&:focus': {
      backgroundColor: 'yellow',
    },
  },
});

export const formStyles = (theme) => ({
  fieldDiv: {
    background: '#FDFEFF 0% 0% no-repeat padding-box',
    border: '1px solid #EDF0F5',
    borderRadius: '16px',
    position: 'absolute',
    padding: '1.5vh 1.5vw',
    flexDirection: 'column',
  },
  multiline: {
    padding: '0',
  },
  fieldLabel: {
    fontSize: '11px',
  },
  hiddenText: {
    color: theme.palette.cloudyBlue,
    fontSize: '18px',
    whiteSpace: 'pre-line',
    visibility: 'hidden',
    height: '2px',
  },
  textFieldInput: {
    borderRadius: '4px',
    padding: '4px 8px',
    height: '24px',
  },
  fieldIconContainer: {
    display: 'flex',
    marginTop: '0vh',
    justifyContent: 'flex-end',
  },
});

export const qrDataStyles = (theme) => ({
  dataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '15px',
  },
  data: {
    width: 'fit-content',
    minWidth: '50%',
    marginBottom: '2.5vh',
    maxWidth: '100%',
    overflowWrap: 'break-word',
    lineHeight: '1.5',
  },
  dataLabel: {
    color: '#9492a7',
    lineHeight: '2',
  },
});
