import React from 'react';
import ItrImg from 'assets/images/itrImg.png';
import Input from '../gstPage/Input';

import './itr.css';

const ItrFilling = () => {
  return (
    <div className="itr-sec">
      <div className="steps">
        <span>ITR Filing</span>
        <span>Add Clients</span>
        <span>Confirm</span>
      </div>
      <div className="d-grid itr-filling">
        <div className="item1">
          <img src={ItrImg} alt="itr-img" />
          <h3>Update your ITR filling information</h3>
          <p>
            By simply login into our TDS product. We ensure you not to make
            double TDS amounts by bringing all the ITR transactions over here
            and you can acknowledge clients by digitally signing and sharing.
          </p>
        </div>
        <div className="item2">
          <p>Enter your Filing Information</p>
          <div className="d-flex mb2">
            <Input label="Financial Year" type="text" />
            <Input label="Acknowledgement No" type="text" />
            <Input label="Date of Filing" type="date" />
          </div>

          <label>
            Have you filed the ITR for current assessment year?{' '}
            <span>
              <button>Yes</button> or <button>No</button>
            </span>
          </label>
          <button className="saveBtn">Save and Proceed</button>
        </div>
      </div>
    </div>
  );
};

export default ItrFilling;
