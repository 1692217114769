import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
//import Cropper from 'react-easy-crop'
//import Loader from 'react-loader-spinner'
import FormEntryDialog from './FormEntryDialog';
import sizeMe from 'react-sizeme';
import { Scrollbars } from 'react-custom-scrollbars';

class Rector extends Component {
  static defaultProps = {
    width: 691.22,
    height: 977.2,
    strokeStyle: '#1077ff',
    lineWidth: 2,
    cursorIcons: {
      upDown: 'ns-resize',
      leftRight: 'ew-resize',
      default: 'grab',
      grab: 'grab',
      grabbing: 'grabbing',
    },
    onSelected: () => {},
  };
  state = {
    closeEnough: 5,
    rectangleAlreadyPresent: false,
    backgroundPosition: '0px 0px',
    initialBackgroundPosition: '0px 0px',
    backgroundSize: '100%',
    imageContainerWidth: 0,
    cursorIcon: 'grab',
    incomingFieldFromClick: false,
    tryingToExpandRectangle: false,
    originalPointsForCurrentField: {},
    differenceFactorBetweenResolutions: 0,
    mouseCurrentConfig: {
      mouseX: 0,
      mouseY: 0,
    },
    mouseStartConfig: {
      mouseX: 0,
      mouseY: 0,
    },
    rectangleConfig: {
      startX: 0,
      startY: 0,
      w: 0,
      h: 0,
    },
    rectangleDragConfig: {
      dragTop: false,
      dragBottom: false,
      dragLeft: false,
      dragRight: false,
    },
    cropAreaClassName: 'cropAreaClassName',
    cropperStyle: {
      opacity: 1,
    },
    containerStyle: {
      width: '100%',
      margin: 0,
      transform: 'none',
      position: 'initial',
    },
    mediaStyle: {
      transform: 'none',
    },
    container: {
      w: 100,
      h: 100,
    },
    image: '',
    crop: {
      x: 0,
      y: 0,
    },
    size: {
      w: 300,
      h: 533,
    },
    cropSize: {
      width: 100,
      height: 100,
    },
    zoom: 1,
    aspect: 1.77,
    top: 0,
    right: 0,
  };

  canvas = null;
  ctx = null;
  isDirty = false;
  isDrag = false;
  startX = -1;
  startY = -1;
  curX = -1;
  curY = -1;
  element = null;

  constructor(props) {
    super(props);
    this.state.image = this.props.imageSrc;
    if (this.props.imageContainerWidth) {
      //ok
      this.state.imageContainerWidth = this.props.imageContainerWidth;
    } else {
      this.state.imageContainerWidth = this.props.size.width;
    }
    //this.state.imageContainerHeight = this.props.imageContainerHeight;
    console.log('props', this.props);
    console.log(
      'this.state.props.imageContainerWidth',
      this.props.imageContainerWidth
    );
    console.log(
      'this.state.props.imageContainerHeight',
      this.props.imageContainerHeight
    );

    window.changeComponentAspect = (stateObject) => {
      this.setState({
        aspect: stateObject,
      });
    };
    window.resetToDefault = (stateObject) => {
      this.setState({
        zoom: 1,
        crop: { x: 0, y: 0 },
      });
    };
    window.changeComponentStateZoom = (stateObject) => {
      this.setState({
        zoom: stateObject,
      });
    };
    window.changeComponentStateSize = (stateObject) => {
      this.setState({
        size: {
          width: stateObject.w,
          height: stateObject.h,
        },
      });
    };
    window.setTopRight = (top, right) => {
      this.setState({
        top: top,
        right: right,
      });
    };

    window.changeComponentStateCrop = (stateObject) => {
      this.setState({
        crop: {
          x: stateObject.x,
          y: stateObject.y,
        },
      });
    };
    window.showvalues = () => {};
  }

  resetRector = () => {
    this.setState({
      zoom: 1,
      crop: { x: 0, y: 0 },
      cropperStyle: {
        opacity: 0,
      },
      rectangleAlreadyPresent: false,
      backgroundPosition: '0px 0px',
      initialBackgroundPosition: '0px 0px',
      backgroundSize: '100%',
    });
    let cropperDiv =
      (document.querySelectorAll('[data-testid="cropper"]') &&
        document.querySelectorAll('[data-testid="cropper"]')[0]) ||
      null;
    if (cropperDiv) {
      cropperDiv.style.width = 0 + 'px';
      cropperDiv.style.height = 0 + 'px';
    }
    console.log('resetting rector');

    if (this.ctx)
      this.ctx.clearRect(
        0,
        0,
        this.state.imageContainerWidth,
        this.props.height
      );
  };

  componentDidMount(props) {
    this.setState({
      rectangleAlreadyPresent: false,
      size: {
        h: this.props.height,
        w: this.props.width,
      },
      crop: {
        x: 0,
        y: 0,
      },
    });
    this.resetRector();
    this.ctx = this.canvas.getContext('2d');
    this.ctx.strokeStyle = this.props.strokeStyle;
    this.ctx.lineWidth = this.props.lineWidth;
    this.ctx.fillStyle = 'rgba(4, 114, 76, 0.36)';

    this.addMouseEvents();
  }

  componentDidUpdate(prevProps) {
    //console.log('this.props.imageStore.focusOnPoint.zoom ',this.props.imageStore.focusOnPoint.zoom )
    if (
      this.state.zoom !== this.props.imageStore.focusOnPoint.zoom &&
      this.props.imageStore.focusOnPoint.zoom >= 1 &&
      this.props.imageStore.focusOnPoint.zoom < 4
    ) {
      this.setState({
        zoom: this.props.imageStore.focusOnPoint.zoom,
      });
      this.calculateResizedValues(
        prevProps,
        this.props.imageStore.focusOnPoint.zoom
      );
      //zoom level changed,update points
    }
  }

  calculateResizedValues = (nextProps, zoomLevel) => {
    let x1Cordinate =
      nextProps.imageStore.focusOnPoint.points &&
      nextProps.imageStore.focusOnPoint.points[0]; // original points coming from AI x1
    let y1Cordinate =
      nextProps.imageStore.focusOnPoint.points &&
      nextProps.imageStore.focusOnPoint.points[1]; // original points coming from AI y1
    let widthCordinate =
      nextProps.imageStore.focusOnPoint.points &&
      nextProps.imageStore.focusOnPoint.points[2]; // original points coming from AI width point
    let heightCordinate =
      nextProps.imageStore.focusOnPoint.points &&
      nextProps.imageStore.focusOnPoint.points[3]; // original points coming from AI height point
    let x2Cordinate =
      nextProps.imageStore.focusOnPoint.points &&
      nextProps.imageStore.focusOnPoint.points[2] *
        nextProps.imageStore.focusOnPoint.size.w; // original points coming from AI x2
    let y2Cordinate =
      nextProps.imageStore.focusOnPoint.points &&
      nextProps.imageStore.focusOnPoint.points[3] *
        nextProps.imageStore.focusOnPoint.size.h; // original points coming from AI y2
    console.log(nextProps);
    // console.log('original points',x1Cordinate * this.props.width)
    // console.log('original points',y1Cordinate * this.props.height)
    // console.log('original points',widthCordinate * this.props.width)
    // console.log('original points',heightCordinate * this.props.height)
    this.setState({
      originalPointsForCurrentField: {
        x1: x1Cordinate * this.props.width,
        y1: y1Cordinate * this.props.height,
        x2: widthCordinate * this.props.width,
        y2: heightCordinate * this.props.height,
      },
    });
    // //console.log('points',nextProps.imageStore.focusOnPoint.points)
    // console.log('image widht and heigh',this.props.width,this.props.height)

    let centerPointToZoomX =
      x1Cordinate * nextProps.imageStore.focusOnPoint.size.w;
    let centerPointToZoomY =
      y1Cordinate * nextProps.imageStore.focusOnPoint.size.h;
    let differenceInX = (x2Cordinate - centerPointToZoomX) / 2;
    let differenceInY = (y2Cordinate - centerPointToZoomY) / 2;

    // let newAspect =
    //   nextProps.imageStore.focusOnPoint.size.h /
    //   nextProps.imageStore.focusOnPoint.size.w;

    let imageDiv =
      (document.querySelectorAll('#imageDiv-rector') &&
        document.querySelectorAll('#imageDiv-rector')[0]) ||
      null;

    let cropperWidth = differenceInX * 4;
    let cropperHeight = differenceInY * 4;
    //getting original X1 Y1 X2 Y2 points as per original width given by AI, for V01.pdf its 1241 px width

    let originalX1 = x1Cordinate * this.props.width;
    let originalX2 = widthCordinate * this.props.width;
    // let originalY1 = y1Cordinate * this.props.height;
    // let originalY2 = heightCordinate * this.props.height;

    let differenceInXPoint = (originalX2 - originalX1) / 2;
    // let differenceInYPoint = (originalY2 - originalY1) / 2;

    let centerPointX = originalX1 + differenceInXPoint; // center point between X1 & X2, total from left
    // let centerPointY = originalY1 + differenceInYPoint; //center points between Y1 & Y2, total from top

    let midPointOfContainer = this.state.imageContainerWidth / 2; // image container width is 52%, usually 726px

    //Resized values of above variables

    let staticImageWidth = 400 * zoomLevel; // resize the image
    console.log('this.props.width', this.props.width);
    this.setState({
      differenceFactorBetweenResolutions:
        ((this.props.width - staticImageWidth) / this.props.width) * 100, // in percentage
    });
    let originalAspectRatio = this.props.height / this.props.width;
    console.log('originalAspectRatio', originalAspectRatio);
    let staticImageHeight = staticImageWidth * originalAspectRatio;

    let resizedX1 = x1Cordinate * staticImageWidth;
    let resizedX2 = widthCordinate * staticImageWidth;
    let resizedY1 = y1Cordinate * staticImageHeight;
    // let resizedY2 = heightCordinate * staticImageHeight;

    let differenceInXPointResized = (resizedX2 - resizedX1) / 2;
    // let differenceInYPointResized = (resizedY2 - resizedY1) / 2;
    //console.log('differenceInYPointResized',differenceInYPointResized)

    let centerPointXResized = resizedX1 + differenceInXPointResized;
    // let centerPointYResized = resizedY1 + differenceInYPointResized;

    if (imageDiv && imageDiv.offsetWidth) {
      //console.log('setint backgroundSize')
      this.setState({
        backgroundSize: `${staticImageWidth}px`,
      });
    }

    //when zoom level changes :
    console.log('this.state.zoom', zoomLevel);

    //console.log('staticImageHeight',staticImageHeight)
    //console.log('revised values resizedY1,resizedY2,centerPointYResized',resizedY1,resizedY2,centerPointYResized)

    //console.log('originalX1',originalX1,'originalX2',originalX2,'centerPointX',centerPointX,'this.state.imageContainerWidth',this.state.imageContainerWidth);
    //console.log('originalY1',originalY1,'originalY2',originalY2,'centerPointY',centerPointY,'this.state.imageContainerHeight',this.props.imageContainerHeight);
    //red color highlighter rectangle points
    let rectangle = {
      x: midPointOfContainer - differenceInXPointResized, //static point of horizontal mid
      //y : (window.innerHeight/4) + ((heightCordinate * this.props.height) - (y1Cordinate * this.props.height))/2,
      y: window.innerHeight / 4, // static point vertically (25% from top)
      w: widthCordinate * staticImageWidth - x1Cordinate * staticImageWidth, //rectangle width should always be based on imageContainer width, not image width
      h: heightCordinate * staticImageHeight - y1Cordinate * staticImageHeight,
    };
    let quadPosition = 'TL'; // top left
    //if X point falls between 0 to MID image container width, then TL
    // console.log('differenceInXPoint',differenceInXPoint)
    //let centerPointY = (rectangle.y + rectangle.h)/2

    if (centerPointX < this.state.imageContainerWidth / 2) {
      quadPosition = 'TL';
    } else if (centerPointX >= this.state.imageContainerWidth / 2) {
      quadPosition = 'TR';
    }
    //console.log('quad position',quadPosition)
    //console.log('this.state.imageContainerWidth',this.state.imageContainerWidth,'rectangle.w',rectangle.w)

    let bgX = 0;
    //top 25% point as base
    //console.log('base',window.innerHeight/4,'offset',originalY1)
    let bgY = window.innerHeight / 4 - resizedY1; //25% + top offset of y1
    //let bgY =  ((window.innerHeight)/4);
    //check quad position
    if (quadPosition === 'TR') {
      //  console.log('originalX1',originalX1,'centerPointX',centerPointX,'originalX1 - centerPointX)',originalX1 - centerPointX,'midPointOfContainer',midPointOfContainer)
      bgX = centerPointXResized - midPointOfContainer;
      bgX = -bgX; //DO NOT CHANGE THIS
      //bgY = -bgY;
    } else if (quadPosition === 'TL') {
      bgX = midPointOfContainer - centerPointXResized;
      //bgX =  ((this.state.imageContainerWidth/2 - (rectangle.w * 0.75)))
      //bgX = -bgX;
      //bgY = -bgY;
    }

    let bgPosition = `${bgX}px ${bgY}px`;
    //console.log('bgPosition',bgPosition)

    setTimeout(() => {
      this.updateCanvasForSelectedField(
        rectangle,
        bgPosition,
        nextProps.imageStore.focusOnPoint.confidence === 'L'
          ? 'rgba(255, 23,23 , 0.32)'
          : 'rgba(4, 114, 76,0.36)'
      );
    }, 100);
    //  this.updateCanvasForSelectedField(rectangle, bgPosition, nextProps.imageStore.focusOnPoint.confidence === 'L' ? 'rgba(255, 23,23 , 0.32)' : 'rgba(4, 114, 76,0.36)')
    //let cropperDiv = document.querySelectorAll('[data-testid="cropper"]') && document.querySelectorAll('[data-testid="cropper"]')[0] || null;

    //console.log(imageDiv.offsetWidth, cropperWidth, "mine")
    if (cropperWidth < 200) {
      cropperWidth = 200;
    }
    /*if (this.props.type === "template")
            this.props.templateStore.setFieldMeasure(cropperHeight + 'px', cropperWidth + 'px', 'flex')
        else*/
    this.props.documentStore.setFieldMeasure(
      cropperHeight + 'px',
      cropperWidth + 'px',
      'flex'
    );

    let temp = (this.state.imageContainerWidth - cropperWidth) / 2;
    //console.log('this.state.imageContainerWidth',this.state.imageContainerWidth)
    //  temp = '0px'
    //console.log('cropperDiv.offsetTop&cropperHeight',cropperDiv.offsetTop,cropperHeight)
    //console.log('cropperDiv.offsetTop&temp', temp)
    console.log(rectangle.y, cropperHeight);
    /*   if (this.props.type === "template")
               this.props.templateStore.setPosition((rectangle.y + cropperHeight + 10) + 'px', temp);
           else*/
    this.props.documentStore.setPosition(
      rectangle.y + cropperHeight + 10 + 'px',
      temp
    );

    //following code is causing loop !!warning
    // this.setState({
    //     crop: {
    //         x: centerPointToZoomX,
    //         y: centerPointToZoomY,
    //     },
    //     cropperStyle: {
    //         opacity: 1,
    //         borderColor: 'red'
    //     },
    //     //crop: nextProps.imageStore.focusOnPoint.crop,
    //     size: nextProps.imageStore.focusOnPoint.size,
    //     //cropSize: nextProps.imageStore.focusOnPoint.size,
    //     aspect: newAspect,
    //     zoom: 1
    //     //cropSize: nextProps.imageStore.focusOnPoint.cropSize
    // })
  };

  componentWillUnmount() {
    this.removeMouseEvents();
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      'nextProps.imageStore.focusOnPoint.resetRector',
      nextProps.imageStore.focusOnPoint.resetRector
    );
    if (nextProps.imageStore.focusOnPoint.resetRector) {
      return this.resetRector();
    }
    this.calculateResizedValues(
      nextProps,
      nextProps.imageStore.focusOnPoint.zoom
    );
  }

  // componentWillReceiveProps(nextProps) {
  //     console.log('nextProps.imageStore.focusOnPoint.resetRector',nextProps.imageStore.focusOnPoint.resetRector)
  //     if (nextProps.imageStore.focusOnPoint.resetRector) {
  //         return this.resetRector()
  //     }
  //
  //     let x1Cordinate = nextProps.imageStore.focusOnPoint.points[0]; // original points coming from AI x1
  //     let y1Cordinate = nextProps.imageStore.focusOnPoint.points[1]; // original points coming from AI y1
  //     let widthCordinate = nextProps.imageStore.focusOnPoint.points[2]; // original points coming from AI width point
  //     let heightCordinate = nextProps.imageStore.focusOnPoint.points[3]; // original points coming from AI height point
  //     let x2Cordinate = nextProps.imageStore.focusOnPoint.points[2] * nextProps.imageStore.focusOnPoint.size.w; // original points coming from AI x2
  //     let y2Cordinate = nextProps.imageStore.focusOnPoint.points[3] * nextProps.imageStore.focusOnPoint.size.h; // original points coming from AI y2
  //
  //     // console.log('original points',nextProps.imageStore.focusOnPoint.x)
  //     // console.log('original points',nextProps.imageStore.focusOnPoint.y)
  //     // console.log('original points',nextProps.imageStore.focusOnPoint.w)
  //     // console.log('original points',nextProps.imageStore.focusOnPoint.h)
  //     // //console.log('points',nextProps.imageStore.focusOnPoint.points)
  //     // console.log('image widht and heigh',this.props.width,this.props.height)
  //
  //
  //     let centerPointToZoomX = x1Cordinate * nextProps.imageStore.focusOnPoint.size.w;
  //     let centerPointToZoomY = y1Cordinate * nextProps.imageStore.focusOnPoint.size.h;
  //     let differenceInX = (x2Cordinate - centerPointToZoomX) / 2;
  //     let differenceInY = (y2Cordinate - centerPointToZoomY) / 2;
  //
  //
  //     let newAspect = (nextProps.imageStore.focusOnPoint.size.h / nextProps.imageStore.focusOnPoint.size.w);
  //
  //
  //
  //     let cropperWidth = differenceInX * 4;
  //     let cropperHeight = differenceInY * 4;
  //
  //     //getting original X1 Y1 X2 Y2 points as per original width given by AI, for V01.pdf its 1241 px width
  //
  //     let originalX1 = x1Cordinate * this.props.width;
  //     let originalX2 = widthCordinate * this.props.width;
  //     let originalY1 = y1Cordinate * this.props.height;
  //     let originalY2 = heightCordinate * this.props.height;
  //
  //     let differenceInXPoint = (originalX2 - originalX1) / 2;
  //     let differenceInYPoint = (originalY2 - originalY1) / 2;
  //
  //     let centerPointX = originalX1 + differenceInXPoint; // center point between X1 & X2, total from left
  //     let centerPointY = originalY1 + differenceInYPoint; //center points between Y1 & Y2, total from top
  //
  //     let midPointOfContainer = this.state.imageContainerWidth / 2; // image container width is 52%, usually 726px
  //
  //     //Resized values of above variables
  //
  //     let staticImageWidth = nextProps.imageStore.staticImageWidth; // resize the image
  //     console.log('staticImageWidth>>>',staticImageWidth)
  //     let originalAspectRatio = this.props.height / this.props.width;
  //     console.log('originalAspectRatio>>>>',originalAspectRatio,staticImageWidth)
  //     let staticImageHeight = staticImageWidth * originalAspectRatio;
  //
  //     let resizedX1 = x1Cordinate * staticImageWidth;
  //     let resizedX2 = widthCordinate * staticImageWidth;
  //     let resizedY1 = y1Cordinate * staticImageHeight;
  //     let resizedY2 = heightCordinate * staticImageHeight;
  //
  //     let differenceInXPointResized = (resizedX2 - resizedX1) / 2;
  //     let differenceInYPointResized = (resizedY2 - resizedY1) / 2;
  //     console.log('differenceInYPointResized',differenceInYPointResized)
  //
  //     let centerPointXResized = resizedX1 + differenceInXPointResized;
  //     let centerPointYResized = resizedY1 + differenceInYPointResized;
  //
  //     let imageDiv = document.querySelectorAll('#imageDiv-rector') && document.querySelectorAll('#imageDiv-rector')[0] || null;
  //     if (imageDiv && imageDiv.offsetWidth) {
  //         console.log('setint backgroundSize')
  //         this.setState({
  //             imageContainerWidth: imageDiv.offsetWidth,
  //             imageContainerHeight: imageDiv.offsetHeight,
  //             backgroundSize: `${staticImageWidth}px`
  //         })
  //     }
  //
  //     //when zoom level changes :
  //     console.log('this.state.zoom',nextProps.imageStore.focusOnPoint.zoom)
  //
  //
  //
  //
  //     console.log('staticImageHeight',staticImageHeight)
  //     console.log('revised values resizedY1,resizedY2,centerPointYResized',resizedY1,resizedY2,centerPointYResized)
  //
  //
  //     //console.log('originalX1',originalX1,'originalX2',originalX2,'centerPointX',centerPointX,'this.state.imageContainerWidth',this.state.imageContainerWidth);
  //     //console.log('originalY1',originalY1,'originalY2',originalY2,'centerPointY',centerPointY,'this.state.imageContainerHeight',this.props.imageContainerHeight);
  //     //red color highlighter rectangle points
  //     let rectangle = {
  //         x: (midPointOfContainer -differenceInXPointResized),//static point of horizontal mid
  //         //y : (window.innerHeight/4) + ((heightCordinate * this.props.height) - (y1Cordinate * this.props.height))/2,
  //         y: (window.innerHeight / 4), // static point vertically (25% from top)
  //         w: ((widthCordinate * staticImageWidth) - (x1Cordinate * staticImageWidth)) + 5, //rectangle width should always be based on imageContainer width, not image width
  //         h: (heightCordinate * staticImageHeight) - (y1Cordinate * staticImageHeight),
  //     };
  //     let quadPosition = 'TL'; // top left
  //     //if X point falls between 0 to MID image container width, then TL
  //     // console.log('differenceInXPoint',differenceInXPoint)
  //     //let centerPointY = (rectangle.y + rectangle.h)/2
  //
  //     if (centerPointX < (this.state.imageContainerWidth / 2)) {
  //         quadPosition = 'TL'
  //     } else if (centerPointX >= (this.state.imageContainerWidth / 2)) {
  //         quadPosition = 'TR';
  //     }
  //     //console.log('quad position',quadPosition)
  //     //console.log('this.state.imageContainerWidth',this.state.imageContainerWidth,'rectangle.w',rectangle.w)
  //
  //     let bgX = 0;
  //     //top 25% point as base
  //     //console.log('base',window.innerHeight/4,'offset',originalY1)
  //     let bgY = (window.innerHeight / 4) - resizedY1; //25% + top offset of y1
  //     //let bgY =  ((window.innerHeight)/4);
  //     //check quad position
  //     if (quadPosition === 'TR') {
  //         //  console.log('originalX1',originalX1,'centerPointX',centerPointX,'originalX1 - centerPointX)',originalX1 - centerPointX,'midPointOfContainer',midPointOfContainer)
  //         bgX = (centerPointXResized - (midPointOfContainer));
  //         bgX = -bgX; //DO NOT CHANGE THIS
  //         //bgY = -bgY;
  //     } else if (quadPosition === 'TL') {
  //         bgX = (midPointOfContainer - centerPointXResized);
  //         //bgX =  ((this.state.imageContainerWidth/2 - (rectangle.w * 0.75)))
  //         //bgX = -bgX;
  //         //bgY = -bgY;
  //     }
  //
  //
  //     let bgPosition = `${bgX}px ${bgY}px`;
  //     console.log('bgPosition',bgPosition)
  //
  //
  //     this.updateCanvasForSelectedField(rectangle, bgPosition)
  //     //let cropperDiv = document.querySelectorAll('[data-testid="cropper"]') && document.querySelectorAll('[data-testid="cropper"]')[0] || null;
  //
  //     //console.log(imageDiv.offsetWidth, cropperWidth, "mine")
  //     if (cropperWidth < 200) {
  //         cropperWidth = 200;
  //     }
  //
  //     this.props.documentStore.setFieldMeasure(cropperHeight + 'px', cropperWidth + 'px', 'flex')
  //
  //     let temp = ((this.state.imageContainerWidth) - cropperWidth) / 2
  //     //console.log('this.state.imageContainerWidth',this.state.imageContainerWidth)
  //     //  temp = '0px'
  //     //console.log('cropperDiv.offsetTop&cropperHeight',cropperDiv.offsetTop,cropperHeight)
  //     console.log('cropperDiv.offsetTop&temp', temp)
  //     this.props.documentStore.setPosition((rectangle.y + cropperHeight + 10) + 'px', temp);
  //
  //     this.setState({
  //         crop: {
  //             x: centerPointToZoomX,
  //             y: centerPointToZoomY,
  //         },
  //         cropperStyle: {
  //             opacity: 1,
  //             borderColor: 'red'
  //         },
  //         //crop: nextProps.imageStore.focusOnPoint.crop,
  //         size: nextProps.imageStore.focusOnPoint.size,
  //         //cropSize: nextProps.imageStore.focusOnPoint.size,
  //         aspect: newAspect,
  //         zoom: 1
  //         //cropSize: nextProps.imageStore.focusOnPoint.cropSize
  //     })
  // }

  updateCanvasForSelectedField = (rect, bgPosition, bgColor) => {
    console.log('incoming values current rectangle drawn by ddefault', rect);
    this.ctx.clearRect(0, 0, 1000, 1000);
    this.ctx.beginPath();

    this.ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    this.ctx.fillStyle = bgColor;
    console.log(bgColor);
    this.ctx.strokeStyle = this.props.strokeStyle;
    this.ctx.lineWidth = this.props.lineWidth;

    //final stroke
    this.ctx.stroke();
    //requestAnimationFrame(this.updateCanvas);
    console.log('bgPosition', bgPosition);
    this.setState({
      backgroundPosition: bgPosition,
      initialBackgroundPosition: bgPosition,
      incomingFieldFromClick: true,
    });
    // document.getElementById("rector-container").scrollTo({
    //     top: rect.y - 100,
    //     behavior: 'smooth'
    // })

    this.setState(
      {
        rectangleAlreadyPresent: true,
        rectangleConfig: {
          startX: rect.x,
          startY: rect.y,
          w: rect.w,
          h: rect.h,
        },
      },
      this.drawHandles
    );
  };

  updateCanvas = () => {
    if (this.isDrag) {
      requestAnimationFrame(this.updateCanvas);
    }
    if (!this.isDirty) {
      return;
    }
    if (this.isDrag) {
      if (this.state.rectangleAlreadyPresent) {
        const rect = {
          x: this.state.rectangleConfig.startX,
          y: this.state.rectangleConfig.startY,
          w: this.state.rectangleConfig.w,
          h: this.state.rectangleConfig.h,
        };
        let mouseX = this.curX;
        let mouseY = this.curY;

        if (this.state.rectangleDragConfig.dragTop) {
          //rect.w += this.state.rectangleConfig.startX - mouseX;
          rect.h += this.state.rectangleConfig.startY - mouseY;
          //rect.x = mouseX;
          rect.y = mouseY;
        } else if (this.state.rectangleDragConfig.dragBottom) {
          rect.h = Math.abs(this.state.rectangleConfig.startY - mouseY);
          /*  if (this.props.type === "template")
                          this.props.templateStore.moveTopPosition(Math.abs(this.state.rectangleConfig.startY - mouseY));
                      else*/
          this.props.documentStore.moveTopPosition(
            Math.abs(this.state.rectangleConfig.startY - mouseY)
          );
        } else if (this.state.rectangleDragConfig.dragLeft) {
          rect.w += this.state.rectangleConfig.startX - mouseX;
          rect.x = mouseX;
        } else if (this.state.rectangleDragConfig.dragRight) {
          console.log('Dragging Right..');
          rect.w = Math.abs(this.state.rectangleConfig.startX - mouseX);
        }
        this.ctx.clearRect(0, 0, this.props.width, this.props.height);
        this.ctx.beginPath();

        this.ctx.rect(rect.x, rect.y, rect.w, rect.h);
        this.ctx.fillStyle = 'rgba(4, 114, 76, 0.36)';
        this.ctx.lineWidth = this.props.lineWidth;

        //final stroke
        this.ctx.stroke();
        console.log('final rect', rect);
        this.setState({
          rectangleConfig: {
            startX: rect.x,
            startY: rect.y,
            w: rect.w,
            h: rect.h,
          },
        });
      } else {
        const rect = {
          x: this.startX,
          y: this.startY,
          w: this.curX - this.startX,
          h: this.curY - this.startY,
        };
        this.ctx.clearRect(0, 0, this.props.width, this.props.height);
        this.ctx.beginPath();

        this.ctx.rect(rect.x, rect.y, rect.w, rect.h);
        this.ctx.strokeStyle = this.props.strokeStyle;
        this.ctx.lineWidth = this.props.lineWidth;
        this.ctx.stroke();
      }
    }

    this.isDirty = false;
  };

  addMouseEvents() {
    this.canvas.addEventListener('mousemove', this.onMouseMove, false);
    this.canvas.addEventListener('mouseup', this.onMouseUp, false);
    this.canvas.addEventListener('mousedown', this.onMouseDown, false);
  }

  removeMouseEvents() {
    this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
    this.canvas.removeEventListener('mouseup', this.onMouseUp, false);
    this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
  }

  onMouseDown = (e) => {
    const { disableCropping } = this.props.documentStore;
    if (
      disableCropping.findIndex(
        (x) => x === this.props.documentStore.currentDoc.docType
      ) > -1
    )
      return;
    console.log(
      'this.state.incomingFieldFromClick',
      this.state.incomingFieldFromClick
    );
    if (this.props.type === 'template') {
      //dont let rectangle to move if its template page
      return;
    }
    this.isDrag = true;
    let mouseX = (this.curX = this.startX = e.offsetX);
    let mouseY = (this.curY = this.startY = e.offsetY);
    this.setState({
      mouseStartConfig: {
        mouseX: e.offsetX,
        mouseY: e.offsetY,
      },
    });
    // if there isn't a rect yet
    if (this.state.rectangleAlreadyPresent) {
      if (
        this.checkCloseEnough(mouseX, this.state.rectangleConfig.startX) &&
        this.checkBetweenPoints(
          mouseY,
          this.state.rectangleConfig.startY,
          this.state.rectangleConfig.startY + this.state.rectangleConfig.h
        )
      ) {
        //console.log('On left border>>>');
        this.setState({
          tryingToExpandRectangle: true,
          rectangleDragConfig: {
            dragTop: false,
            dragBottom: false,
            dragLeft: true,
            dragRight: false,
          },
        });
      } else if (
        this.checkCloseEnough(
          mouseX,
          this.state.rectangleConfig.startX + this.state.rectangleConfig.w
        ) &&
        this.checkBetweenPoints(
          mouseY,
          this.state.rectangleConfig.startY,
          this.state.rectangleConfig.startY + this.state.rectangleConfig.h
        )
      ) {
        //console.log('on right border')
        this.setState({
          tryingToExpandRectangle: true,
          rectangleDragConfig: {
            dragTop: false,
            dragBottom: false,
            dragLeft: false,
            dragRight: true,
          },
        });
      } else if (
        this.checkCloseEnough(mouseY, this.state.rectangleConfig.startY) &&
        this.checkBetweenPoints(
          mouseX,
          this.state.rectangleConfig.startX,
          this.state.rectangleConfig.startX + this.state.rectangleConfig.w
        )
      ) {
        //console.log('on top border', this.props.cursorIcons.upDown)
        this.setState({
          tryingToExpandRectangle: true,
          rectangleDragConfig: {
            dragTop: true,
            dragBottom: false,
            dragLeft: false,
            dragRight: false,
          },
        });
      } else if (
        this.checkCloseEnough(
          mouseY,
          this.state.rectangleConfig.startY + this.state.rectangleConfig.h
        ) &&
        this.checkBetweenPoints(
          mouseX,
          this.state.rectangleConfig.startX,
          this.state.rectangleConfig.startX + this.state.rectangleConfig.w
        )
      ) {
        console.log('on bottom border');
        /*  if (this.props.type === "template")
                      this.props.templateStore.setCurrentTopValue();
                  else*/
        this.props.documentStore.setCurrentTopValue();
        this.setState({
          tryingToExpandRectangle: true,
          rectangleDragConfig: {
            dragTop: false,
            dragBottom: true,
            dragLeft: false,
            dragRight: false,
          },
        });
      } else {
        // // handle not resizing
        // this.ctx.clearRect(0, 0, this.props.width, this.props.height);
        this.setState({
          tryingToExpandRectangle: false,
          cursorIcon: 'grabbing',
          //rectangleAlreadyPresent: false
        });
        // console.log('not resizing...')
      }
    }

    requestAnimationFrame(this.updateCanvas);
  };

  checkCloseEnough = (p1, p2) => {
    //console.log('p1p2',p1,p2,(p1 - p2),this.state.closeEnough)
    return Math.abs(p1 - p2) < this.state.closeEnough;
  };

  checkBetweenPoints = (check, p1, p2) => {
    //console.log('p1p2',p1,p2,(p1 - p2),this.state.closeEnough)
    return check >= p1 && check <= p2;
  };

  drawCircle = (x, y, radius) => {
    this.ctx.fillStyle = '#1077ff';
    this.ctx.beginPath();
    this.ctx.arc(x, y, radius, 0, 2 * Math.PI);
    this.ctx.fill();
  };

  drawHandles = () => {
    /* this.drawCircle(this.state.rectangleConfig.startX, this.state.rectangleConfig.startY, this.state.closeEnough);
         this.drawCircle(this.state.rectangleConfig.startX + this.state.rectangleConfig.w, this.state.rectangleConfig.startY, this.state.closeEnough);
         this.drawCircle(this.state.rectangleConfig.startX + this.state.rectangleConfig.w, this.state.rectangleConfig.startY + this.state.rectangleConfig.h, this.state.closeEnough);
         this.drawCircle(this.state.rectangleConfig.startX, this.state.rectangleConfig.startY + this.state.rectangleConfig.h, this.state.closeEnough);*/
  };

  moveBackgroundPosition = () => {
    if (this.props.type === 'template') return;
    //Initial BG Position
    if (this.state.tryingToExpandRectangle) {
      return;
    }
    let bgX = parseInt(this.state.backgroundPosition.split('px')[0]);
    let bgY = parseInt(this.state.backgroundPosition.split('px')[1]);
    let initialBgX = parseInt(
      this.state.initialBackgroundPosition.split('px')[0]
    );
    let initialBgY = parseInt(
      this.state.initialBackgroundPosition.split('px')[1]
    );
    //New BG Position
    if (
      this.isDrag &&
      this.state.mouseStartConfig.mouseX !== 0 &&
      this.state.cursorIcon === 'grabbing'
    ) {
      let changeInMouseX =
        this.state.mouseCurrentConfig.mouseX -
        this.state.mouseStartConfig.mouseX;
      let changeInMouseY =
        this.state.mouseCurrentConfig.mouseY -
        this.state.mouseStartConfig.mouseY;
      console.log('change in mouse', changeInMouseX, changeInMouseY);
      console.log('Current bg', bgX, bgY);
      bgX = parseInt(initialBgX) + parseInt(changeInMouseX);
      bgY = parseInt(initialBgY) + parseInt(changeInMouseY);
      let bgPosition = `${bgX}px ${bgY}px`;
      if (bgPosition) {
        this.setState({
          backgroundPosition: bgPosition,
        });
      }
    }
  };

  onMouseMove = (e) => {
    this.setState({
      mouseCurrentConfig: {
        mouseX: e.offsetX,
        mouseY: e.offsetY,
      },
    });
    let mouseX = (this.curX = this.startX = e.offsetX);
    let mouseY = (this.curY = this.startY = e.offsetY);
    // if there isn't a rect yet
    if (this.state.rectangleAlreadyPresent) {
      if (
        this.checkCloseEnough(mouseX, this.state.rectangleConfig.startX) &&
        this.checkBetweenPoints(
          mouseY,
          this.state.rectangleConfig.startY,
          this.state.rectangleConfig.startY + this.state.rectangleConfig.h
        )
      ) {
        //console.log('On left border>>>');
        this.setState({
          cursorIcon: this.props.cursorIcons.leftRight,
        });
      } else if (
        this.checkCloseEnough(
          mouseX,
          this.state.rectangleConfig.startX + this.state.rectangleConfig.w
        ) &&
        this.checkBetweenPoints(
          mouseY,
          this.state.rectangleConfig.startY,
          this.state.rectangleConfig.startY + this.state.rectangleConfig.h
        )
      ) {
        //console.log('on right border')
        this.setState({
          cursorIcon: this.props.cursorIcons.leftRight,
        });
      } else if (
        this.checkCloseEnough(mouseY, this.state.rectangleConfig.startY) &&
        this.checkBetweenPoints(
          mouseX,
          this.state.rectangleConfig.startX,
          this.state.rectangleConfig.startX + this.state.rectangleConfig.w
        )
      ) {
        //console.log('on top border', this.props.cursorIcons.upDown)
        this.setState({
          cursorIcon: this.props.cursorIcons.upDown,
        });
      } else if (
        this.checkCloseEnough(
          mouseY,
          this.state.rectangleConfig.startY + this.state.rectangleConfig.h
        ) &&
        this.checkBetweenPoints(
          mouseX,
          this.state.rectangleConfig.startX,
          this.state.rectangleConfig.startX + this.state.rectangleConfig.w
        )
      ) {
        //console.log('on bottom border')
        this.setState({
          cursorIcon: this.props.cursorIcons.upDown,
        });
      } else {
        //move background here
        this.moveBackgroundPosition();
        if (this.isDrag) {
          this.setState({
            cursorIcon: this.props.cursorIcons.grabbing,
          });
        }
      }
    }

    //console.log('mouse move',e.offsetX)
    if (this.state.tryingToExpandRectangle) {
      if (!this.isDrag) {
        if (this.props.imageStore.focusOnPoint.allowFocus) {
          // const { focusOnPoint, pointsForCrop } = this.props.imageStore;
          //console.log('focusOnPoint',focusOnPoint,'pointsForCrop',pointsForCrop)
        } else {
          //console.log('allow focus false')
          //this.ctx.clearRect(0, 0, this.state.imageContainerWidth, this.props.height)
          this.ctx.beginPath();
          this.ctx.moveTo(0, 0);
        }
        return;
      } else {
        this.ctx.clearRect(
          0,
          0,
          this.state.imageContainerWidth,
          this.props.height
        );
      }
      this.curX = e.offsetX;
      this.curY = e.offsetY;
      this.isDirty = true;
    }
  };

  onMouseUp = (e) => {
    console.log(
      'this.state.incomingFieldFromClick',
      this.state.incomingFieldFromClick
    );
    // if (this.state.incomingFieldFromClick){
    //     //dont draw anything is field is selected
    //     return;
    // }
    this.setState({
      cursorIcon: 'grab',
    });
    this.isDrag = false;
    let movingBackground = !(
      this.state.backgroundPosition === this.state.initialBackgroundPosition
    );
    if (movingBackground) {
      this.setState({
        initialBackgroundPosition: this.state.backgroundPosition,
      });
    }
    if (this.state.tryingToExpandRectangle || movingBackground) {
      this.isDrag = false;
      this.isDirty = true;
      let rect = {
        x: Math.min(this.startX, this.curX),
        y: Math.min(this.startY, this.curY),
        w: Math.abs(e.offsetX - this.startX),
        h: Math.abs(e.offsetY - this.startY),
      };
      if (this.state.rectangleAlreadyPresent) {
        rect = {
          x: this.state.rectangleConfig.startX,
          y: this.state.rectangleConfig.startY,
          w: this.state.rectangleConfig.w,
          h: this.state.rectangleConfig.h,
        };
      }
      console.log('original points', this.state.originalPointsForCurrentField);
      console.log('rect', rect);

      if (rect.w > 5 && rect.h > 5) {
        // hiding snippets for now
        // this.props.commonStore.openDialog('mapper');
        let cordinates = {
          x1: rect.x,
          y1: rect.y,
          x2: rect.x + rect.w,
          y2: rect.y + rect.h,
        };
        let BackgroundX = this.state.backgroundPosition.split('px')[0];
        let BackgroundY = this.state.backgroundPosition.split('px')[1];
        console.log('BackgroundX', BackgroundX);
        console.log('BackgroundY', BackgroundY);
        console.log(
          'initial cords',
          cordinates,
          'differenceFactorBetweenResolutions',
          this.state.differenceFactorBetweenResolutions
        );

        if (BackgroundX < 0) {
          cordinates.x1 += Math.abs(BackgroundX);
        } else {
          cordinates.x1 = Math.abs(BackgroundX - cordinates.x1);
        }
        if (BackgroundY > 0) {
          cordinates.y1 -= Math.abs(BackgroundY);
        } else {
          cordinates.y1 = Math.abs(BackgroundY) + rect.y;
        }

        cordinates.x2 = cordinates.x1 + rect.w;
        cordinates.y2 = cordinates.y1 + rect.h;
        cordinates.x1 = parseInt(
          (cordinates.x1 * 100) /
            (100 - this.state.differenceFactorBetweenResolutions)
        );
        cordinates.y1 = parseInt(
          (cordinates.y1 * 100) /
            (100 - this.state.differenceFactorBetweenResolutions)
        );
        cordinates.x2 = parseInt(
          (cordinates.x2 * 100) /
            (100 - this.state.differenceFactorBetweenResolutions)
        );
        cordinates.y2 = parseInt(
          (cordinates.y2 * 100) /
            (100 - this.state.differenceFactorBetweenResolutions)
        );

        //console.log('BackgroundY',BackgroundY)

        //multiply with the difference factor to get actual points as per original image width;
        //console.log('before final cordinates',cordinates)
        //cordinates.x1 =  (cordinates.x1 * 100) / (100 - this.state.differenceFactorBetweenResolutions);
        console.log('after final cordinates', cordinates);
        let newCoordinates = [];
        newCoordinates.push(cordinates.x1 / this.props.width);
        newCoordinates.push(cordinates.y1 / this.props.height);
        newCoordinates.push(cordinates.x2 / this.props.width);
        newCoordinates.push(cordinates.y2 / this.props.height);
        this.props.imageStore.updateCoordinates(newCoordinates);
        console.log(this.props.type, 'mine');
        if (this.props.type === 'poso')
          this.props.imageStore.fetchSnippedPoso(
            this.props.imageSrc,
            cordinates
          );
        else {
          this.props.imageStore
            .fetchSnipped(this.props.imageSrc, cordinates)
            .then((response) => {
              console.log('response', response);
              if (response)
                this.props.documentStore.editCurrentFieldValue(response);
            });
        }

        if (!this.state.rectangleAlreadyPresent) {
          this.setState({
            tryingToExpandRectangle: false,
            rectangleAlreadyPresent: true,
            rectangleConfig: {
              startX: rect.x,
              startY: rect.y,
              w: rect.w,
              h: rect.h,
            },
          });
          this.drawHandles();
        } else {
          this.drawHandles();
        }
      }
      this.props.imageStore.selectAreaUpdate(rect);
      this.props.onSelected(rect);
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {};

  onZoomChange = (zoom) => {
    this.props.imageStore.zoomIn();
  };

  render() {
    console.log(
      this.ctx && this.ctx.fillStyle,
      'this.ctx && this.ctx.fillStyle'
    );
    const { focusOnPoint, staticImageWidth } =
      this.props.imageStore;
    const currentImageOriginalWidth = this.props.width;
    const { currentField } = this.props.documentStore;
    const { type } = this.props;
    if (this.ctx)
      this.ctx.fillStyle =
        this.props.imageStore.focusOnPoint.confidence === 'L'
          ? 'rgba(255, 23,23 , 0.32)'
          : 'rgba(4, 114, 76,0.36)';
    //console.log('currentImageOriginalWidth',focusOnPoint.zoom)
    return (
      <Scrollbars>
        <div
          id="imageDiv-rector"
          className="cropContainers"
          style={{
            position: 'relative',
            height: focusOnPoint.resetRector ? '100%' : this.state.size.h,
          }}
        >
          <canvas
            width={this.state.imageContainerWidth}
            id="canvas-demo"
            height={this.props.imageContainerHeight}
            ref={(c) => {
              this.canvas = c;
            }}
            style={{
              width: '100%',
              backgroundImage: `url(${this.props.imageSrc})`,
              backgroundSize:
                focusOnPoint.resetRector || focusOnPoint.zoom < 1
                  ? this.state.imageContainerWidth > currentImageOriginalWidth
                    ? '50%'
                    : '100%'
                  : `${staticImageWidth}px`,
              cursor: `${this.state.cursorIcon}`,
              position: 'absolute',
              backgroundRepeat: 'no-repeat',
              backgroundPosition:
                focusOnPoint.resetRector || focusOnPoint.zoom < 1
                  ? this.state.imageContainerWidth > currentImageOriginalWidth
                    ? '50% 5%'
                    : '100% 0%'
                  : `${this.state.backgroundPosition}`,
              imageOrientation: 'from-image',
              filter:
                currentField._id !== undefined ? 'brightness(0.7)' : 'none',
              //transform:`scale(${focusOnPoint.zoom})`
            }}
          />
          {type === 'template' ? null : (
            <FormEntryDialog
              type={this.props.type}
              cancelZoom={this.resetRector}
            ></FormEntryDialog>
          )}
        </div>
      </Scrollbars>
    );
  }

  // render() {
  //     const { focusOnPoint, pointsForCrop } = this.props.imageStore;
  //     return (
  //         <div id="imageDiv-rector" className="cropContainers" style={{ position: 'relative', height: focusOnPoint.resetRector ? '100%' : this.state.size.h }}>
  //             <Cropper
  //                 image={this.props.imageSrc}
  //                 crop={this.state.crop}
  //                 zoom={focusOnPoint.zoom}
  //                 aspect={this.state.aspect}
  //                 showGrid={false}
  //                 onCropChange={this.onCropChange}
  //                 onCropComplete={this.onCropComplete}
  //                 style={{ cropAreaStyle: this.state.cropperStyle, mediaStyle: this.state.mediaStyle, containerStyle: this.state.containerStyle }}
  //             />
  //             <FormEntryDialog cancelZoom={this.resetRector}></FormEntryDialog>
  //         </div>
  //     )
  //     //     return <canvas width={this.props.width} id="canvas-demo" height={this.props.height}
  //     //     ref={(c) => {this.canvas=c}} style={{backgroundImage: `url(${this.props.imageSrc})`, backgroundSize: {currentBackgroundSize},
  //     //     cursor: 'crosshair', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', imageOrientation: 'from-image'
  //     // }}/>
  // }
}
export default sizeMe({ monitorHeight: true })(
  inject('documentStore', 'imageStore')(observer(Rector))
);
//export default Rector;
