import { useState, useContext, useEffect, useCallback } from 'react';

import ToastNotificationContext from '@/contexts/ToastNotificationContext';

const useErrorToast = () => {
  const [errorToastRef, setErrorToastRef] = useState(null);

  const { toastRefs } = useContext(ToastNotificationContext);

  useEffect(() => {
    if (toastRefs.errorToastRef) {
      setErrorToastRef(toastRefs.errorToastRef);
    }
  }, [toastRefs]);

  const showToast = useCallback(
    ({ message }) => {
      errorToastRef.show({ message });
    },
    [errorToastRef]
  );

  return {
    showToast,
  };
};

export default useErrorToast;
