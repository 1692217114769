import React, {
  //  useEffect,
  useState,
} from 'react';
import finkraftLogowhite from '@/assets/logos/finkraftLogo-white.png';
import sapConcur from '@/assets/logos/sap-concur.svg';
import SapImg from '@/assets/images/sap_img.png';
import './sap.css';
import { getSapOrganizationGstins } from '../../apis/gstin';
import { useLocation } from 'react-router-dom';

const SapLogin = () => {
  const [showThankQ, setShowThankQ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const location = useLocation();
  const [sapOrgRes, setSapOrgRes] = useState(null);
  // const [closeIn, setCloseIn] = useState(10);

  const callApi = async () => {
    setLoading(true);
    try {
      let res = await getSapOrganizationGstins(
        location?.state?.invoice
          ? {
              user_name: email,
              sap_username: location?.state?.id,
              sap_password: location?.state?.reqToken,
              invoice: location?.state?.invoice,
            }
          : {
              user_name: email,
              sap_username: location?.state?.id,
              sap_password: location?.state?.reqToken,
            }
      );
      if (res) {
        setShowThankQ(true);
        setLoading(false);
        setSapOrgRes(res?.data);
      } else {
        setLoading(false);
        alert(JSON.stringify(res));
      }
    } catch (err) {
      setLoading(false);
      alert(JSON.stringify(err));
      setShowThankQ(false);
    }
  };

  // useEffect(() => {
  //   if (closeIn === 0) {
  //     window.opener = 'self';
  //     window.close();
  //   }
  //   let timeout = setTimeout(() => {
  //     setCloseIn((closeIn) => (closeIn ? closeIn - 1 : 10));
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [closeIn]);

  return (
    <div className="sap-login">
      <img src={finkraftLogowhite} alt="logo-white" width={200} />
      {showThankQ ? (
        !location?.state?.invoice ? (
          <>
            <h3>
              {sapOrgRes?.status ? 'Thank You' : 'Connection Unsuccessful'}
            </h3>
            <p>
              {sapOrgRes?.status
                ? 'Hurray! We are almost there. You will receive a mail to view and access the detailed report.'
                : 'Please contact Finkraft support team.'}
            </p>
            {/* {(sapOrgRes?.status || true) && (
              <>
                <br />
                <p>Closing window in {closeIn} seconds.</p>
              </>
            )} */}
          </>
        ) : (
          <>
            <h3>
              {sapOrgRes?.status ? 'Thank You' : 'Connection Unsuccessful'}
            </h3>
            <p>
              {sapOrgRes?.status
                ? 'Hurray! We are almost there to use our invoices platform.'
                : 'Please contact Finkraft support team.'}
            </p>
            {/* {sapOrgRes?.status && (
              <>
                <br />
                <p>Closing window in {closeIn} seconds.</p>
              </>
            )} */}
          </>
        )
      ) : (
        <>
          <h3>
            Welcome to Finkraft GST Software for{' '}
            {location?.state?.invoice ? 'Invoices' : 'Expenses'}
          </h3>
          <p>
            View sample of your input GST analysis in just a few seconds.
            Meanwhile let us know a little bit about your Organisation.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              callApi();
            }}
          >
            <div className="input-sec">
              <input
                name="email"
                type="email"
                required
                placeholder="Enter email address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <span>
                <input type="checkbox" />
                &nbsp;&nbsp;I agree to{' '}
                <u>
                  <a
                    href="https://finkraft.ai/termsNCondition"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Finkraft terms &amp; conditions
                  </a>
                </u>
              </span>
              <button
                className="proceed"
                type="submit"
                style={{
                  width: '40%',
                  border: 'none',
                  padding: 10,
                  borderRadius: 5,
                  marginTop: 20,
                }}
                disabled={loading}
              >
                {'Submit'}
              </button>
            </div>
          </form>
        </>
      )}
      <div className="sap-img">
        <img src={SapImg} alt="sap-img" />
        <div>
          <a href="https://finkraft.ai" target="_blank" rel="noreferrer">
            More info
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://finkraft.ai/helpNSupport"
            target="_blank"
            rel="noreferrer"
          >
            {'Support'}
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://finkraft.ai/privacyPolicy"
            target="_blank"
            rel="noreferrer"
          >
            {'Privacy Policy'}
          </a>
        </div>
      </div>
      <div className="commerical-bar">
        Finkraft connection with the account &nbsp; <b>SAP Concur</b> &nbsp;{' '}
        <img src={sapConcur} alt="sapConcur" width={20} />
      </div>
    </div>
  );
};

export default SapLogin;
