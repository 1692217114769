/*
 UploadedFiles component
 url match: / or /login
 Displays email id and password field
 with UploadedFiles button
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { uploadStyles } from '../../Styles/commonStyles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import { ReactSVG } from 'react-svg';
import Images from '../../Theme/Images';
import Loader from 'react-loader-spinner';

class UploadedFiles extends React.Component {
  onExpandFolder = (index) => {
    this.props.dashboardStore.expandUploadedFolder(index);
  };

  renderUploadedFolders = () => {
    const { uploadedFolders, errors } = this.props.dashboardStore;
    const { classes } = this.props;
    return uploadedFolders.length
      ? uploadedFolders.map((row, index) => (
          <React.Fragment>
            <TableRow key={index}>
              <TableCell className={classes.tableData} align="left">
                <div style={{ display: 'flex' }}>
                  <div>
                    <ReactSVG
                      beforeInjection={(svg) => {
                        svg.classList.add(classes.sideBarIcons);
                      }}
                      src={Images.folder}
                      onClick={(e) => this.onExpandFolder(index)}
                    />
                  </div>
                  <div style={{ paddingLeft: '1vw', display: 'flex' }}>
                    {row.folderName}{' '}
                    <ReactSVG
                      style={{ marginLeft: '1vw' }}
                      src={Images.dropDown}
                      onClick={(e) => this.onExpandFolder(index)}
                    />{' '}
                  </div>
                </div>
              </TableCell>
            </TableRow>
            {row.expanded && row.files && row.files.length
              ? row.files.map((fileRow, fileIndex) => (
                  <TableRow key={fileIndex}>
                    <TableCell className={classes.tableData} align="left">
                      <div style={{ display: 'flex', marginLeft: '2vw' }}>
                        <div>
                          <ReactSVG
                            beforeInjection={(svg) => {
                              svg.classList.add(classes.sideBarIcons);
                            }}
                            src={Images.file}
                          />
                        </div>
                        <div style={{ paddingLeft: '1vw', fontSize: '12px' }}>
                          {fileRow.name}{' '}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableData} align="right">
                      {fileRow.uploadIcon === 'right' ? (
                        <ReactSVG src={Images.done} />
                      ) : null}
                      {fileRow.uploadIcon === 'error' || errors ? (
                        <ReactSVG src={Images.error} />
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </React.Fragment>
        ))
      : null;
  };

  removeFile = (index) => {
    this.props.dashboardStore.removeFile(index);
  };

  getFileIcon = (name) => {
    let temp = 'png';
    if (name.toLowerCase().endsWith('.pdf')) temp = 'miniPdf';
    else if (name.toLowerCase().endsWith('.zip')) temp = 'folder';
    return Images[`${temp}`];
  };

  render() {
    const { files, uploadIcon, uploadedFolders, errors } =
      this.props.dashboardStore;
    const { classes } = this.props;
    let fileCount = files.length + uploadedFolders.length;
    return (
      <div>
        <div className={classes.fileCount}>
          {fileCount} {fileCount > 1 ? 'files ' : 'file '} selected
        </div>
        <TableContainer
          id="uploadTable"
          style={{ maxHeight: uploadIcon === 'close' ? '30vh' : '50vh' }}
          elevation={0}
          component={Paper}
        >
          <Table className={classes.docTable} aria-label="simple table">
            <TableBody>
              {
                <React.Fragment>
                  {files.length
                    ? files.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableData} align="left">
                            <div style={{ display: 'flex' }}>
                              <div>
                                <ReactSVG
                                  beforeInjection={(svg) => {
                                    svg.classList.add(classes.sideBarIcons);
                                  }}
                                  src={Images.file}
                                />
                              </div>
                              <div style={{ paddingLeft: '1vw' }}>
                                {row.name}{' '}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableData}
                            align="right"
                          >
                            {uploadIcon === 'close' ? (
                              <CloseIcon
                                fontSize="small"
                                onClick={(event) => {
                                  this.removeFile(index);
                                }}
                              ></CloseIcon>
                            ) : null}
                            {files[index].uploadIcon === 'right' ? (
                              <ReactSVG src={Images.done} />
                            ) : null}
                            {files[index].uploadIcon === 'error' || errors ? (
                              <ReactSVG src={Images.error} />
                            ) : null}
                            {uploadIcon === 'progress' ? (
                              <Loader
                                height={20}
                                width={20}
                                type="Oval"
                                color={this.props.theme.palette.turquoise}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                  {this.renderUploadedFolders()}
                </React.Fragment>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
export default withStyles(uploadStyles, { withTheme: true })(
  withRouter(inject('dashboardStore')(observer(UploadedFiles)))
);
