import styled from 'styled-components/macro';
import { Icon } from '@blueprintjs/core';

import Input from '@setproduct-ui/Inputs/DefaultInput';
import Button from '@setproduct-ui/Button';

import { FlexColumnContainer } from '@/components/Themed';

const FormInputWrapper = styled(FlexColumnContainer.withComponent('div'))`
  width: 275px;
  flex-wrap: nowrap;
  position: relative;

  & > label {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 7px;
    font-weight: 600;

    color: #000000;
  }

  & > input {
    height: 30px;
    background: #ffffff;
    border: 1.11195px solid #e9edf3;
    border-radius: 3.33584px;
    padding: 0 5px;
  }

  & > textarea {
    width: 100%;
    min-height: 100px;
    background: #ffffff;
    border: 1.11195px solid #e9edf3;
    border-radius: 3.33584px;
    resize: none;
  }
`;

export const ErrorMsg = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: #d90000;
  margin-top: 5px;
`;

export const FormTextInput = ({ field, form, ...props }) => {
  const meta = form.getFieldMeta(field.name);

  return (
    <FormInputWrapper className="input-wrapper">
      <Input
        {...field}
        {...props}
        type="text"
        view="outlined"
        dense={false}
        color="default"
        label={props.label}
        value={props.value || field.value}
        placeholder={'Enter ' + props.label}
        clearButton={false}
        success={false}
        error={!!meta.touched && !!meta.error}
        fill={props?.fill || false}
        errorHelperText={!!meta.touched && !!meta.error ? meta.error : null}
        onInput={(e) => field.onChange(e)}
      />
    </FormInputWrapper>
  );
};

export const FormTextAreaInput = ({ field, form, ...props }) => {
  const meta = form.getFieldMeta(field.name);

  return (
    <FormInputWrapper className="input-wrapper">
      <label htmlFor={field.name}>{props.label}</label>
      <textarea
        {...field}
        {...props}
        style={{
          ...props.style,
          border: meta.touched && meta.error ? '1px solid #d90000' : '',
        }}
      />
      {meta.touched && meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
    </FormInputWrapper>
  );
};

export const Delete = ({ idx, remove }) => {
  return (
    <Icon
      style={{
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 3,
        cursor: 'pointer',
        color: 'var(--red50)',
      }}
      icon="trash"
      iconSize={28}
      onClick={() => remove(idx)}
    />
  );
};

export const AddButton = ({
  idx,
  insert,
  btnText,
  rowObj,
  type = 'default',
  view = 'smooth',
  color = 'primary',
}) => {
  return (
    <Button
      type={type}
      view={view}
      color={color}
      dense={true}
      icon="plus"
      onClick={() => insert(idx + 1, rowObj)}
    >
      {btnText}
    </Button>
  );
};

export const SubmitButton = ({ onClick, btnText = 'Submit', ...props }) => {
  return (
    <Button
      {...props}
      style={{ width: 200, ...props.style }}
      type="default"
      view="smooth"
      color="primary"
      onClick={onClick}
    >
      {btnText}
    </Button>
  );
};
