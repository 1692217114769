
export const dialogStyles = theme => ({
    submitBackdrop: {
        backdropFilter: 'transparent'
    },
    submitDialog: {
        width: '24.5vw',
    },
    overlayBackdrop: {
        background: 'rgba(0, 0, 0, 0.5)',
        backdropFilter:' blur(5px)'
    },
    overlayDialog: {
        width: '58vw'
    },
    overlay: {
        fontSize: '17px',
        display: 'flex'

    },
    overlayError: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    button: {
        backgroundColor: theme.palette.blue,
        color: theme.palette.white,
        textTransform: 'unset',
        height: '44px',
        borderRadius: '6px',
        fontSize: '16px',
        width: '120px',
        marginTop: 0,
        marginBottom: '20px',
        marginRight: '1vw',
    },
    dialogHeading: {
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center'
    },
    dialogSubHeading: {
        fontSize: '13px',
        color: theme.palette.slateGray,
        textAlign: 'center',
        padding: '2vh 0'
    },
    backdrop: {
        backdropFilter: 'blur(10px)'
    },
})

export const helpStyles = theme => ({
    backdrop: {
        backdropFilter: 'blur(10px)'
    },
    stepActive: {
        color: 'white !important'
    },
    stepInactive: {
        color: `${theme.palette.slateGray}`
    },
    completed: {
        color: `${theme.palette.blue} !important`
    },
    helpDialog: {
        width: '45vw',
        backgroundColor: theme.palette.dark,
        minHeight: '50vh'
    },
    button: {
        backgroundColor: theme.palette.blue,
        color: theme.palette.white,
        textTransform: 'unset',
        height: '44px',
        borderRadius: '6px',
        fontSize: '16px',
        width: '120px',
        marginTop: 0,
        marginBottom: '20px',
        marginRight: '1vw',
    },
    dialogHeading: {
        fontSize: '20px',
        fontWeight: '700',
        color: theme.palette.white
    },
    dialogSubHeading: {
        fontSize: '13px',
        color: theme.palette.slateGray
    },
    stepHeading: {
        fontSize: '16px',
        color: theme.palette.white
    }
})
