import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider,createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import App from './App';
import './index.scss';
//Mobx stores
import commonStore from './mobx-stores/commonStore';
import dashboardStore from './mobx-stores/dashboardStore';
import documentStore from './mobx-stores/documentStore';
import imageStore from './mobx-stores/imageStore';
import websocketStore from './mobx-stores/websocketStore';

const stores = {
  commonStore,
  documentStore,
  imageStore,
  dashboardStore,
  websocketStore,
};

configure({ enforceActions: 'observed' });
const GBCode = () => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#0f7fd3',
      },
      lightGray: '#F0F0F0',
      tableHeader: '#2F2D51',
      tableData: '#525353',
      orange: '#E28F30',
      red: '#F0221D',
      green: '#47B553',
      purple: '#474186',
      white: '#FFFFFF',
      turquoise: '#04bfa8',
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      useNextVariants: true,
      fontFamily: 'proxima_nova_rgregular',
    },
    overrides: {
      MuiInputLabel: {
        // Name of the component ⚛️ / style sheet
        root: {
          // Name of the rule
          color: '#00000',
          '&$focused': {
            // increase the specificity for the pseudo class
            color: '#00000',
          },
        },
      },
      MuiFilledInput: {
        root: {
          color: '#00000',
          borderRadius: '4px',
          backgroundColor: 'transparent',
          border: 'solid 1px #c5c1c0',
          paddingRight: '0px',
          paddingTop: '0px',
          '&$focused': {
            // increase the specificity for the pseudo class
            backgroundColor: '#fff',
          },
          '&$disabled': {
            backgroundColor: '#fff',
            color: 'black',
          },
        },
      },
      MuiList: {
        root: {
          padding: '0px',
        },
      },
    },
  });
  
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      style={{ background: 'white' }}
    >
      <MuiThemeProvider theme={theme}>
        <Provider {...stores}>
          <App />
        </Provider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default GBCode;
