import * as Yup from 'yup';
import { startCase, camelCase } from 'lodash';

import { unofficialEmailProvidersPattern } from './regexPatterns';
import { checkUserExists } from '@/apis/users';

const nameSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .min(1, `${msgFieldName} is too short`)
    .max(255, `${msgFieldName} is too long`)
    .required(`${msgFieldName} is required`);
};

const emailSchemaWithoutDuplicateCheck = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .email(`${msgFieldName} must be valid`)
    .required(`${msgFieldName} is required`)
    .test(
      'Official Email',
      'Enter your official email address',
      (value) =>
        value && !unofficialEmailProvidersPattern.test(value.split('@')[1])
    );
};

const emailSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .email(`${msgFieldName} must be valid`)
    .required(`${msgFieldName} is required`)
    .test(
      'Official Email',
      'Enter your official email address',
      (value) =>
        value && !unofficialEmailProvidersPattern.test(value.split('@')[1])
    )
    .test('duplicateCheck', `${msgFieldName} already registered`, (value) => {
      return value && value
        ? checkUserExists({ email: value }).then(({ data }) => {
            return !data;
          })
        : true;
    });
};

const mobileNumberSchemaWithoutDuplicateCheck = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(10, `${msgFieldName} should be 10 digits in length`)
    .required(`${msgFieldName} is required`);
};

const mobileNumberSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(10, `${msgFieldName} should be 10 digits in length`)
    .required(`${msgFieldName} is required`)
    .test('duplicateCheck', `${msgFieldName} already registered`, (value) => {
      return value && value.length === 10
        ? checkUserExists({ mobileNumber: value }).then(({ data }) => {
            return !data;
          })
        : true;
    });
};

// const websiteSchema = ({ fieldName }) => {
//   const msgFieldName = startCase(camelCase(fieldName));
//   return Yup.string()
//     .min(5, `${msgFieldName} cannot be less than 5 characters`)
//     .max(255, `${msgFieldName} cannot be more than 255 characters`)
//     .url(`${msgFieldName} must be a valid URL`)
//     .required(`${msgFieldName} is required`);
// };

const gstnSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(15, `${msgFieldName} should be 15 characters long`)
    .required(`${msgFieldName} is required`);
};

export const userSignupValidationSchema = Yup.object()
  .shape({
    fullName: nameSchema({ fieldName: 'name' }),
    department: nameSchema({ fieldName: 'department' }),
    emailId: emailSchema({ fieldName: 'emailId' }),
    mobileNumber: mobileNumberSchema({
      fieldName: 'mobileNumber',
    }),
    organisationName: nameSchema({ fieldName: 'organizationName' }),
    gstin: gstnSchema({ fieldName: 'gstin' }),
  })
  .required();

export const contactValidationSchema = Yup.object()
  .shape({
    uuid: Yup.string().uuid().required(),
    name: nameSchema({ fieldName: 'fullName' }),
    designation: nameSchema({ fieldName: 'designation' }),
    // mobileNumber: mobileNumberSchema({
    //   fieldName: 'mobileNumber',
    // }),
    // email: emailSchema({ fieldName: 'email' }),
    new: Yup.boolean().required(),
  })
  .required();

export const contactsValidationSchema = Yup.object()
  .shape({
    contacts: Yup.array(contactValidationSchema).required(),
  })
  .required();

export const gstinValidationSchema = Yup.object()
  .shape({
    uuid: Yup.string().uuid().required(),
    name: nameSchema({ fieldName: 'entityName' }),
    domain: nameSchema({
      fieldName: 'domain',
    }),
    gstin: gstnSchema({ fieldName: 'gstin' }),
    password: nameSchema({ fieldName: 'password' }),
    new: Yup.boolean().required(),
  })
  .required();

export const gstinsValidationSchema = Yup.object()
  .shape({
    gstins: Yup.array(gstinValidationSchema).required(),
  })
  .required();

export const gstrPortalLoginCredsFormValidationSchema = Yup.object().shape({
  username: nameSchema({ fieldName: 'Username' }),
  password: nameSchema({ fieldName: 'Password' }),
});

export const tmcContactFormValidationSchema = Yup.object().shape({
  tmcContactName: nameSchema({ fieldName: 'name' }),
  tmcContactEmail: emailSchemaWithoutDuplicateCheck({ fieldName: 'tmcEmail' }),
  tmcContactNumber: mobileNumberSchemaWithoutDuplicateCheck({
    fieldName: 'tmcContactNumber',
  }),
});

export const accountsContactFormValidationSchema = Yup.object().shape({
  accountsContactName: nameSchema({ fieldName: 'name' }),
  accountsContactEmail: emailSchemaWithoutDuplicateCheck({
    fieldName: 'accountsEmail',
  }),
  accountsContactNumber: mobileNumberSchemaWithoutDuplicateCheck({
    fieldName: 'accountsContactNumber',
  }),
});

export const airIndiaLoginFormValidationSchema = Yup.object().shape({
  logins: Yup.array(
    Yup.object()
      .shape({
        credentialsExist: Yup.boolean().required(),
        username: Yup.string().when('credentialsExist', {
          is: true,
          then: nameSchema({ fieldName: 'Username' }),
        }),
        password: Yup.string().when('credentialsExist', {
          is: true,
          then: nameSchema({ fieldName: 'Password' }),
        }),
        isAuthorized: Yup.boolean().when('credentialsExist', {
          is: false,
          then: Yup.boolean().oneOf([true], 'Field must be checked').required(),
        }),
      })
      .required()
  )
    .min(1)
    .required(),
});

export const validateEmailField = (isNew = false) => async (value) => {
  if (isNew) {
    const result = await emailSchema({ fieldName: 'email' })
      .validate(value)
      .catch((err) => {
        return err.errors[0];
      });
    return result !== value ? result : false;
  } else {
    return undefined;
  }
};

export const validateMobileNumberField = (isNew = false) => async (value) => {
  if (isNew) {
    const result = await mobileNumberSchema({ fieldName: 'mobileNumber' })
      .validate(value)
      .catch((err) => {
        return err.errors[0];
      });
    return result !== value ? result : false;
  } else {
    return undefined;
  }
};
