/*
 SimpleButton component
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { buttonStyles } from './componentStyles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Loader from 'react-loader-spinner'


class SimpleButton extends React.Component {

    render() {
        const { classes, label, variant, progress, disabled, style } = this.props;
        return (
            <Button disabled={disabled ? disabled : false} style={style} classes={{disabled: classes.disabled}} onClick={progress ? null : this.props.handleClick} className={clsx({ [classes.button]: true, [classes.cancelButton]: variant === 'cancel' , [classes.delete]: variant === 'delete' })}>
                {progress ? <Loader type={"ThreeDots"} height={15} width={15} color={this.props.theme.palette.white} /> : label}
            </Button>
        );
    }
}
export default withStyles(buttonStyles, { withTheme: true })(withRouter(inject('commonStore')(observer(SimpleButton))));
