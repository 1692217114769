/*
 FormEntryDialog.js component
 url match: / or /login
 Displays email id and password field
 with FormEntryDialog button
*/
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { formStyles } from '../../Styles/documentStyles';
import Images from '../../Theme/Images';
import InvoiceField from './PO-SO/InvoiceField';
import LabelField from './PO-SO/LabelField';

class FormEntryDialog extends React.Component {
  handleFormFieldChange = (value, index, fieldId, type) => {
    if (this.props.type === 'poso')
      this.props.documentStore.updatePosoField(value, index, fieldId);
    else this.props.documentStore.updateField(value, index, fieldId, type);
  };

  handleFormLabelChange = (value, index, fieldId, type) => {
    this.props.documentStore.onLabelChange(value, index, fieldId);
  };

  updateForm = () => {
    const { disableCropping } = this.props.documentStore;
    if (
      disableCropping.findIndex(
        (x) => x === this.props.documentStore.currentDoc.docType
      ) > -1
    )
      return;
    // const { currentField } = this.props.documentStore;
    if (this.props.type === 'poso')
      this.props.documentStore.updatePosoDocument('REVIEW_PENDING');
    else this.props.documentStore.updateForm();
    this.props.documentStore.resetCurrentField();
    this.props.cancelZoom();
  };

  onCancel = (index) => {
    const { disableCropping } = this.props.documentStore;
    if (
      disableCropping.findIndex(
        (x) => x === this.props.documentStore.currentDoc.docType
      ) > -1
    )
      return;
    if (this.props.type === 'poso')
      this.props.documentStore.undoPosoChanges(index);
    else this.props.documentStore.undoChanges(index);
    this.props.documentStore.resetCurrentField();
    this.props.cancelZoom();
  };

  getValue = () => {
    const {
      currentField,
      updatedValues,
      updatedNonTabularFields,
      // updatedTabularFields,
    } = this.props.documentStore;
    let i = -1;
    if (this.props.type === 'poso') {
      if (currentField.page !== undefined)
        i = updatedNonTabularFields.findIndex(
          (x) => x.fieldId === currentField._id
        );
      else
        i = updatedNonTabularFields.findIndex(
          (x) => x.fieldId === currentField._id
        );

      if (i !== -1) return currentField.editedValue;
      else if (
        currentField.editedValue !== '' &&
        currentField.editedValue !== undefined
      )
        return currentField.editedValue;
      else return currentField.aiValue;
    } else {
      i = updatedValues.findIndex((x) => x.fieldId === currentField._id);
      if (i !== -1) return currentField.editedValue;
      else if (
        currentField.editedValue !== '' &&
        currentField.editedValue !== undefined
      )
        return currentField.editedValue;
      else return currentField.aiValue;
    }
  };

  render() {
    const {
      classes,
      // type
    } = this.props;
    const { currentField, currentIndex, currentDoc } = this.props.documentStore;
    return currentField._id !== undefined ? (
      <div
        className={classes.fieldDiv}
        style={{
          display: currentField.style.display,
          top: currentField.style.top,
          left: currentField.style.left,
        }}
      >
        <div id="fieldIconContainer" className={classes.fieldIconContainer}>
          <ReactSVG
            onClick={(e) => this.onCancel(currentIndex)}
            src={Images.smallClose}
            beforeInjection={(svg) => {
              svg.classList.add(classes.formIcons);
            }}
          />
        </div>
        <div id="hiddenText" className={classes.hiddenText}>
          {currentField.editedKey}
        </div>
        <LabelField
          from="dialog"
          value={currentField.editedKey}
          label="Label"
          onChange={(event) =>
            this.handleFormLabelChange(
              event.target.value,
              currentIndex,
              currentField._id,
              currentField.editedKey !== '' &&
                currentField.editedKey !== undefined
                ? 'editedKey'
                : 'aiKey'
            )
          }
          options={currentDoc.ocrDataKeys}
        />

        <InvoiceField
          from="dialog"
          value={currentField.editedValue}
          key={currentField.editedValue}
          label={'Extracted Text'}
          onChange={(event) =>
            this.handleFormFieldChange(
              event.target.value,
              currentIndex,
              currentField._id,
              currentField.editedValue
            )
          }
        />
        <InvoiceField
          from="dialog"
          value={currentField.qrValue}
          key={currentIndex}
          disabled={true}
          label={'QR Text'}
        />
        <InvoiceField
          from="dialog"
          value={currentField.text_zoho_2a}
          key={'GST2A'}
          disabled={true}
          label={'GST2A'}
        />
        <InvoiceField
          from="dialog"
          value={currentField.text_zoho_pr}
          key={'PR-ERP'}
          disabled={true}
          label={'PR-ERP'}
        />
        <InvoiceField
          from="dialog"
          value={currentField.text_zoho_eway}
          key={'E-Way'}
          disabled={true}
          label={'E-Way'}
        />
        <div id="fieldIconContainer" className={classes.fieldIconContainer}>
          <button
            className="cancel-btn"
            onClick={(e) => this.onCancel(currentIndex)}
            src={Images.cancel}
            beforeInjection={(svg) => {
              svg.classList.add(classes.formIcons);
            }}
          >
            Cancel
          </button>
          <button
            className="apply-btn"
            onClick={(e) => this.updateForm()}
            src={Images.greenTickBig}
            beforeInjection={(svg) => {
              svg.classList.add(classes.formIcons);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    ) : null;
  }
}
export default withStyles(formStyles, { withTheme: true })(
  withRouter(inject('documentStore')(observer(FormEntryDialog)))
);
