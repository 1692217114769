import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from '../../Styles/commonStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  colorCodes,
  inProgressStatus,
  scanStatus,
  tableHeaders,
} from '../../utils/utils';
import Images from '../../Theme/Images';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactSVG } from 'react-svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import InputBase from '@material-ui/core/InputBase';
import Loader from 'react-loader-spinner';
import { Tooltip } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteDialog from '../../Common/DeleteDialog';
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.dullGray,
    color: theme.palette.white,
    boxShadow: theme.shadows[1],
    fontSize: '13px',
  },
  arrow: {
    color: theme.palette.dullGray,
  },
}))(Tooltip);

class InvoiceTable extends Component {
  state = {
    showSelectAllMessage: false,
    currentInvoice: undefined,
    deleteDialog: false,
    currentIndex: -1,
  };

  onCheckboxChange = (event, index, value) => {
    event.stopPropagation();
    if (index === undefined) {
      this.setState({ showSelectAllMessage: value });
      this.props.dashboardStore.handleSelectAll(value);
      return;
    }
    if (!value)
      this.setState({
        selectAll: false,
        showSelectAllMessage: false,
      });
    this.props.dashboardStore.setSelectedDocs(index, value);
  };

  renderAutoComplete = (type, options, index) => {
    const { classes } = this.props;
    const { filterAnchors } = this.props.dashboardStore;
    return (
      <Popper
        open={filterAnchors[index] != null}
        anchorEl={filterAnchors[index]}
        placement="bottom-end"
        className={classes.popper}
      >
        <div className={classes.popperBorder}>
          {' '}
          <Autocomplete
            onClose={(e) => this.handleFilterChange(index, type)}
            open={true}
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            //    value={this.props[`${type}`]}
            onChange={(event, newValue) => {
              this.handleFilterChange(index, type, newValue);
            }}
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            renderOption={(option, { selected }) => (
              <div
                className={
                  option.name === this.props[`${type}`].name
                    ? classes.selectedOption
                    : classes.nOption
                }
              >
                {option.name}
              </div>
            )}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                className={classes.inputBase}
              />
            )}
          />
        </div>
      </Popper>
    );
  };

  renderCheckbox = (index) => {
    const { classes } = this.props;
    const { selectedDocs, selectAllDocs } = this.props.dashboardStore;
    return (
      <Checkbox
        onChange={(event, value) => this.onCheckboxChange(event, index, value)}
        onClick={(e) => e.stopPropagation()}
        checked={index === undefined ? selectAllDocs : selectedDocs[index]}
        className={classes.checkbox}
        classes={{ root: classes.checkbox, checked: classes.checkbox }}
        color="primary"
      />
    );
  };

  openFile = (file) => {
    if (file.preventOpening)
      this.props.commonStore.handleOpenSnackBar(
        'Unable to open invoice',
        'info'
      );
    else if (inProgressStatus.includes(file.scanStatus))
      this.props.commonStore.handleOpenSnackBar(
        'Please wait for the scan to complete',
        'info'
      );
    else this.props.history.push(`/document/${file._id}`);
  };

  handleTotalDocs = (value) => {
    this.props.dashboardStore.handleTotalDocs(value);
    if (!value) {
      this.setState({ showSelectAllMessage: false });
      this.props.dashboardStore.handleSelectAll(false);
    }
  };

  downloadFile = (e, url) => {
    e.stopPropagation();
    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  onClickDelete = (e, invoice, index) => {
    e.stopPropagation();
    this.setState({
      currentInvoice: invoice,
      deleteDialog: true,
      currentIndex: index,
    });
  };

  renderTableBody = () => {
    const { classes, commonStore, theme } = this.props;
    const {
      documentList,
      totalDocs,
      selectAllDocs,
      selectTotalDocs,
      inDocProgress,
    } = this.props.dashboardStore;

    console.log(
      'documentList.length,inDocProgress',
      documentList.length,
      inDocProgress
    );

    return inDocProgress && documentList.length <= 0 ? (
      <div className="loading">
        <Loader
          height={100}
          width={100}
          type="Oval"
          color={theme.palette.purple}
          className="fullHeight"
        />
      </div>
    ) : documentList.length > 0 ? (
      <TableBody>
        {selectAllDocs ? (
          <TableRow classes={{ root: classes.tableRow }} key={'selectAll'}>
            <TableCell
              align="center"
              colSpan={tableHeaders.length + 1}
              className={classes.tableData}
            >
              {selectAllDocs && !selectTotalDocs ? (
                <div>
                  All 6 invoices in this page are selected.{' '}
                  <span
                    className={classes.selectAll}
                    onClick={(e) => this.handleTotalDocs(true)}
                  >
                    Select all {totalDocs} invoices
                  </span>
                </div>
              ) : selectTotalDocs ? (
                <div>
                  All {totalDocs} invoices are selected.{' '}
                  <span
                    className={classes.selectAll}
                    onClick={(e) => this.handleTotalDocs(false)}
                  >
                    Clear selection
                  </span>
                </div>
              ) : null}
            </TableCell>
          </TableRow>
        ) : null}
        {documentList.map((row, index) => (
          <TableRow
            classes={{ root: classes.tableRow }}
            key={'tableHeader' + index}
            onClick={(e) => this.openFile(row)}
          >
            <TableCell align="center" className={classes.tableData}>
              {' '}
              <div className="rowFlex flexCentered">
                {' '}
                <div
                  style={{
                    borderColor: colorCodes.scanStatus[`${row.scanStatus}`],
                  }}
                  className={classes.docStatus}
                ></div>
                {this.renderCheckbox(index)}
              </div>
            </TableCell>
            <TableCell
              align="left"
              className={`${classes.tableData} ${classes.fileName}`}
            >
              <LightTooltip arrow title={row.originalFileName}>
                <div className={classes.fileName}>{row.originalFileName}</div>
              </LightTooltip>
            </TableCell>
            <TableCell
              align="center"
              className={`${classes.tableData} ${classes.companyData}`}
            >
              {row.companyName}
            </TableCell>
            <TableCell align="center" className={classes.tableData}>
              {' '}
              {row.invoiceNo ? row.invoiceNo : '-'}
            </TableCell>
            <TableCell align="center" className={classes.tableData}>
              {' '}
              {row.invoiceDate ? row.invoiceDate : '-'}
            </TableCell>
            <TableCell align="center" className={classes.tableData}>
              {' '}
              {row.sellerGstin ? row.sellerGstin : '-'}
            </TableCell>
            <TableCell align="center" className={classes.tableData}>
              {' '}
              {row.amount ? row.amount : '-'}
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableData}
              style={{ color: colorCodes.scanStatus[`${row.scanStatus}`] }}
            >
              {' '}
              {scanStatus[row.scanStatus]}
            </TableCell>
            {/* <TableCell align="center" className={classes.tableData} style={{ color: colorCodes.eInvoice[`${row.eInvoice}`] }}>{row.eInvoice && commonStore.modifyText(row.eInvoice)}</TableCell>
                            <TableCell align="center" className={classes.tableData} style={{ color: row.confidenceScore ? colorCodes.confidenceScore[`${row.confidenceScore}`] : 'initial' }}> {row.confidenceScore ? commonStore.modifyText(row.confidenceScore) : '-'}</TableCell>*/}
            <TableCell
              align="center"
              className={classes.tableData}
              style={{
                color: row.reviewStatus
                  ? colorCodes.reviewStatus[`${row.reviewStatus}`]
                  : 'initial',
              }}
            >
              {' '}
              {row.reviewStatus
                ? commonStore.modifyText(row.reviewStatus)
                : '-'}
            </TableCell>
            <TableCell align="center" className={classes.tableData}>
              {' '}
              {row.qrDetected ? 'Yes' : 'No'}
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableData}
              style={{
                color: row.reviewStatus
                  ? colorCodes.reviewStatus[`${row.reviewStatus}`]
                  : 'initial',
              }}
            >
              <div className="rowFlex">
                <GetAppIcon
                  fontSize="small"
                  className={classes.delIcon}
                  onClick={(e) => this.downloadFile(e, row.s3Link)}
                />
                <DeleteOutlineIcon
                  fontSize="small"
                  className={classes.delIcon}
                  onClick={(e) => this.onClickDelete(e, row, index)}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow>
          <TableCell colspan="11" classes={{ root: classes.emptyList }}>
            <div className={classes.emptyList}>
              No invoices found, click on 'Upload Invoice' to begin
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  deleteInvoice = () => {
    const { currentIndex, currentInvoice } = this.state;
    this.props.dashboardStore.deleteDocument(currentIndex, currentInvoice._id);
  };

  cancelDelete = () => {
    this.setState({ currentInvoice: undefined, deleteDialog: false });
  };

  handleOpenMenu = (e, index) => {
    let temp = this.props.dashboardStore.filterAnchors.slice();
    temp[index] = e.currentTarget;
    this.props.dashboardStore.setFilterAnchor(temp);
  };

  handleFilterChange = (index, type, value) => {
    let temp = this.props.dashboardStore.filterAnchors.slice();
    temp[index] = null;
    this.props.dashboardStore.setFilterAnchor(temp);
    if (value) this.props.handleFilterChange(type, value);
  };

  renderTableHead = () => {
    const { classes } = this.props;
    const { filterAnchors } = this.props.dashboardStore;
    return (
      <TableHead>
        <TableRow className={classes.tableHeaderRow}>
          <TableCell align="left" className={classes.tableHeader}>
            {' '}
            {this.renderCheckbox()}
          </TableCell>

          {tableHeaders.map((row, index) => (
            <TableCell
              key={'subscriptionCell' + index}
              className={classes.tableHeader}
              align={index === 0 ? 'left' : 'center'}
            >
              <div className={index === 0 ? 'rowFlex' : 'rowFlex flexCentered'}>
                {row.name}&nbsp;&nbsp;
                {row.sort ? (
                  <ReactSVG
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                    src={Images.filter}
                    onClick={(e) => this.handleOpenMenu(e, index)}
                  />
                ) : null}
              </div>
              <Popper
                open={filterAnchors[index] != null}
                anchorEl={filterAnchors[index]}
                className={classes.popper}
                placement="bottom-end"
              >
                <div className={classes.filterMenuContainer}>
                  {this.renderAutoComplete(row.filter, row.filters, index)}
                </div>
              </Popper>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  render() {
    const { classes } = this.props;
    const { deleteDialog, currentInvoice } = this.state;

    return (
      <Table className={classes.table} aria-label="simple table">
        {this.renderTableHead()}

        {this.renderTableBody()}

        <DeleteDialog
          open={deleteDialog}
          title="Delete invoice"
          message={`Are you sure you want to delete ${
            currentInvoice && currentInvoice.originalFileName
          }?`}
          delete={this.deleteInvoice}
          cancelDelete={this.cancelDelete}
        />
      </Table>
    );
  }
}
export default withStyles(tableStyles, { withTheme: true })(
  withRouter(inject('dashboardStore', 'commonStore')(observer(InvoiceTable)))
);
