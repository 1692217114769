import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import constants from './constants';
import commonStore from './mobx-stores/commonStore';
const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = constants.API_ROOT;
const handleErrors = (err, res) => {
  if (err && err.response && err.response.status === 401) {
    //userStore.goToLogin();
    // commonStore.handleOpenSnackBar('Access token expired, please log on again to continue', 'error')
    commonStore.setToken(undefined);
  }
  return err;
};

const responseBody = (res) => res.body;
const responseFull = (res) => res;

const tokenPlugin = (req) => {
  if (commonStore.token) {
    req.set('Authorization', `Bearer ${commonStore.token}`);
  }
  // req.set('Authorization', `Bearer abc`);
};

const requests = {
  del: (url, body) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .send(body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  getFull: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseFull),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  postAttachments: (url, files) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .accept('application/json')
      .attach('files', files[0])
      .attach('files', files[1])
      .attach('files', files[2])
      .attach('files', files[3])
      .attach('files', files[4])
      .attach('files', files[5])
      .attach('files', files[6])
      .attach('files', files[7])
      .attach('files', files[8])
      .attach('files', files[9])
      .attach('files', files[10])
      .end(handleErrors)
      .then(responseBody),
};

const Documents = {
  getDocumentList: (pageNo, data) =>
    requests.get(
      `/upload/getDocumentListFinkraft?pageNo=${pageNo}&limit=6` +
        (data.scanStatus.value !== 'ALL'
          ? `&scanStatus=${data.scanStatus.value}`
          : ``) +
        (data.eInvoice.value !== 'ALL'
          ? `&eInvoice=${data.eInvoice.value}`
          : ``) +
        (data.gstnFilter !== 'ALL' ? `&sellerGstin=${data.gstnFilter}` : ``) +
        (data.confidenceScore.value !== 'ALL'
          ? `&confidenceScore=${data.confidenceScore.value}`
          : ``) +
        (data.reviewStatus.value !== 'ALL'
          ? `&reviewStatus=${data.reviewStatus.value}`
          : ``) +
        (data.searchQuery !== undefined && data.searchQuery !== ''
          ? `&searchQuery=${data.searchQuery}`
          : ``) +
        `&sortField=${data.sortField}&sortDirection=-1`
    ),
  uploadFiles: (files) => {
    return requests.postAttachments(`/upload/uploadDocsFinkraft`, files);
  },
  exportToExcel: (data) => requests.post(`/upload/createQrDataXls`, data),
  getDocumentDetails: (documentId) =>
    requests.get(`/upload/getDocumentDetailsFinkraft?documentId=${documentId}`),
  getExtDocumentDetails: (documentId) =>
    requests.get(
      `/upload/getDocumentDetailsFinkraftNonAuth?documentId=${documentId}`
    ),
  downloadDocument: (fileName) =>
    requests.get(`/upload/downloadFile?fileName=${fileName}`),
  getDownloadTokenForDoc: (documentId) =>
    requests.post(`/upload/getDownloadTokenForDocFinkraft`, { documentId }),
  updateDocStatus: (uploadId, reviewStatus) =>
    requests.put(`/upload/updateReviewStatusFinkraft`, {
      uploadId,
      reviewStatus,
    }),
  deleteDocument: (uploadIdArray) =>
    requests.del(`/upload/deleteDocFinkraft`, { uploadIdArray }),
  updateDocument: (data) =>
    requests.put(`/upload/editDocumentFieldsFinkraft`, data),
};
// eslint-disable-next-line
export default {
  Documents,
};
