import { useState } from 'react';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import Axios from 'axios';
import './styles/uploadlogs.css';
import { Close } from '@material-ui/icons';

const SelectedFiles = styled.div``;
const ErrorDiv = styled.div`
  color: red;
`;
const DropZone = styled.div`
  width: 100%;
  min-height: 360px;
  background: #f5f7fa;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p.drag {
    color: #58666e;
  }

  p.or {
    color: #236489;
    font-size: 13px;
  }
`;

const Invoice = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const SelectInvoices = (acceptedFiles) => {
    setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    setError('');
  };

  const getFileSize = (size) => {
    if (size <= 1024) {
      return size + ' B';
    } else if (size > 1024 && size <= 1024 * 1024 * 5) {
      if (size > 10000) {
        return parseFloat(size / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        return parseFloat(size / 1024).toFixed(2) + ' KB';
      }
    }
  };

  const uploadInvoice = async () => {
    try {
      const formData = new FormData();
      if (!selectedFiles.length) {
        return setError('Select Files to upload');
      }
      const pdfFiles = selectedFiles.filter((file) =>
        file.type.includes('pdf')
      );
      const zipFiles = selectedFiles.filter((file) =>
        file.type.includes('zip')
      );

      if (pdfFiles.length && zipFiles.length) {
        setError('Cannot select PDF and Zip files');
        return;
      }
      if (zipFiles.length > 1) {
        setError('Cannot select multiple zip files');
        return;
      }
      if (!pdfFiles.length && zipFiles.length === 1) {
        formData.append('attachment', zipFiles[0]);
      }

      if (pdfFiles.length && !zipFiles.length) {
        for (let x = 0; x < pdfFiles.length; x++) {
          formData.append(`attachments[]`, pdfFiles[x]);
        }
      }

      setLoading(true);
      setIsSubmitting(true);

      const { data: uploadResponse } = await Axios.post(
        'https://api.finkraft.ai/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total));
          },
        }
      );

      if (uploadResponse && !uploadResponse.status) {
        alert(uploadResponse.message);
        setIsSubmitting(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert(JSON.stringify(error) || error);
    }
    setError('');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: SelectInvoices,
    multiple: true,
    accept: 'application/pdf,.zip',
  });

  return (
    <div>
      <div>
        <DropZone {...getRootProps()}>
          <PublishIcon />
          <br />
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <p className="drag">Drag &amp; Drop Receipt to upload JPG/PDF </p>
              <p className="or">Or click here to attach</p>
            </>
          )}
          <SelectedFiles>
            {selectedFiles.map((file, idx) => {
              return <p>{file.name}</p>;
            })}
          </SelectedFiles>
          {error ? <ErrorDiv>{error}</ErrorDiv> : null}
        </DropZone>

        <button
          className="clear-btn"
          disabled={!selectedFiles.length}
          onClick={async () => {
            await setSelectedFiles([]);
            await setError('');
          }}
        >
          Clear
        </button>
        <button
          onClick={() => uploadInvoice()}
          className="upload-invoice"
          disabled={isSubmitting}
        >
          {' '}
          Upload invoice
        </button>
      </div>
      {isSubmitting && (
        <div className={'uploading-card'}>
          <Close
            className="close-icon"
            onClick={() => {
              setIsSubmitting(false);
              setSelectedFiles([]);
              setProgress(0);
            }}
          />
          <h3>
            {loading ? 'Uploading' : 'Uploaded'} {selectedFiles.length} receipt…
            {/* <span>View</span> */}
            <br />
            <br />
            {selectedFiles.map((item) => {
              return (
                <span className="infodwn">
                  {item.name} ({getFileSize(item.size)})
                </span>
              );
            })}
          </h3>
          <br />
          <div className="upload-progress">
            <span
              style={{
                display: 'block',
                width: `${progress}%`,
                background: '#42A743',
                height: '5px',
                borderRadius: '6px',
              }}
            ></span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Invoice;
