import { action, makeAutoObservable } from 'mobx';
import agent from '../agent';
import React from 'react';
import Images from '../Theme/Images';
import { ReactSVG } from 'react-svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import commonStore from './commonStore';
import imageStore from './imageStore';
import constants from '../constants';

export class DocumentStore {
  inProgress = false;
  statusText;
  errors;
  currentPage = {};
  openDrawer = false;
  currentTopValue = 0;
  currentDoc = {};
  updatedValues = [];
  updateProgress = false;
  currentField = {
    style: {
      height: '0px',
      width: '0px',
      display: 'none',
      top: '0px',
      left: '0px',
    },
  };
  currentIndex = 0;
  updatedTabularFields = [];
  updatedNonTabularFields = [];
  errorProgress = false;
  reviewProgress = false;
  tableHeaders = [];
  financeDocs = [
    'PO',
    'MEDIASSIST',
    'NEW_INVOICE',
    'WRITERCORP',
    'HAND_WRITTEN',
    'RENT_AGREEMENT',
  ];
  disableCropping = ['HAND_WRITTEN'];

  constructor() {
    makeAutoObservable(this);
  }

  updateField(value, index, fieldId, type) {
    this.currentPage.formData[index].editedValue = value;
    let temp = {};
    temp.fieldId = fieldId;
    temp.fieldValue = value;
    let currentIndex = this.updatedValues.findIndex(
      (x) => x.fieldId === fieldId
    );
    if (currentIndex !== -1) this.updatedValues[currentIndex] = temp;
    else this.updatedValues.push(temp);
  }

  onLabelChange(value, index, fieldId, type) {
    let newIndex = this.currentDoc.nonTabularData.findIndex(
      (x) => x.editedKey === value
    );
    if (newIndex > -1) {
      /*   this.currentDoc.nonTabularData[newIndex].editedKey = value
            this.currentDoc.nonTabularData[newIndex].pts = this.currentDoc.nonTabularData[index].pts
            this.currentDoc.nonTabularData[newIndex].editedValue = this.currentDoc.nonTabularData[index].editedValue
            this.currentDoc.nonTabularData[newIndex].aiValue = this.currentDoc.nonTabularData[index].aiValue
            this.currentDoc.nonTabularData[newIndex].qrValue = this.currentDoc.nonTabularData[index].qrValue
            this.currentField =  this.currentDoc.nonTabularData[newIndex]
            this.currentIndex = newIndex
            console.log(this.currentField.editedValue , this.currentField.aiValue, 'editedValue')
            this.currentDoc.nonTabularData[index].editedKey = '';
            this.currentDoc.nonTabularData[index].pts = []
            this.currentDoc.nonTabularData[index].editedValue = ''
            this.currentDoc.nonTabularData[index].aiValue = ''
            this.currentDoc.nonTabularData[index].qrValue = ''*/
      let temp2 = {
        fieldId: this.currentDoc.nonTabularData[newIndex]._id,
        fieldValue: this.currentDoc.nonTabularData[index].aiValue,
        pts: this.currentDoc.nonTabularData[index].pts,
      };
      let currentIndex2 = this.updatedNonTabularFields.findIndex(
        (x) => this.currentDoc.nonTabularData[newIndex]._id === fieldId
      );
      if (currentIndex2 > -1)
        this.updatedNonTabularFields[currentIndex2] = temp2;
      else this.updatedNonTabularFields.push(temp2);
    }
    this.currentField.editedKey = value;
    let temp1 = {};
    temp1.fieldId = fieldId;
    temp1.fieldValue = '';
    temp1.pts = [];
    let currentIndex = this.updatedNonTabularFields.findIndex(
      (x) => x.fieldId === fieldId
    );
    if (currentIndex > -1) this.updatedNonTabularFields[currentIndex] = temp1;
    else this.updatedNonTabularFields.push(temp1);
  }

  updatePosoField(value, index, fieldId, coordinates) {
    if (this.currentField.page !== undefined)
      this.updateNonTabularField(value, index, fieldId, coordinates);
    else this.updateTabularField(value, index, fieldId, coordinates);
  }

  updateNonTabularField(value, index, fieldId, coordinates) {
    this.currentDoc.nonTabularData[index].editedValue = value;
    if (coordinates !== undefined)
      this.currentDoc.nonTabularData[index].pts = coordinates;
    this.currentField.editedValue = value;
    let temp = {};
    temp.fieldId = fieldId;
    temp.fieldValue = value;
    if (coordinates !== undefined) temp.pts = coordinates;
    let currentIndex = this.updatedNonTabularFields.findIndex(
      (x) => x.fieldId === fieldId
    );
    if (currentIndex !== -1) this.updatedNonTabularFields[currentIndex] = temp;
    else this.updatedNonTabularFields.push(temp);
  }

  updateTabularField(value, index, fieldId, coordinates) {
    this.currentPage.tableData[index].editedValue = value;
    let temp = {};
    temp.fieldId = fieldId;
    temp.fieldValue = value;
    temp.pageId = this.currentPage._id;
    if (coordinates !== undefined) temp.pts = coordinates;
    this.currentField.editedValue = value;
    let currentIndex = this.updatedTabularFields.findIndex(
      (x) => x.fieldId === fieldId
    );
    if (currentIndex !== -1) this.updatedTabularFields[currentIndex] = temp;
    else this.updatedTabularFields.push(temp);
  }

  updateSnippedField(value, newCoordinates) {
    this.currentField.editedValue = value;
    let temp = {};
    temp.fieldId = this.currentField._id;
    temp.fieldValue = value;
    temp.pts = newCoordinates;
    let indexInPage = this.currentPage.formData.findIndex(
      (x) => x._id === this.currentField._id
    );
    if (indexInPage !== -1)
      this.currentPage.formData[indexInPage].pts = newCoordinates;
    let currentIndex = this.updatedValues.findIndex(
      (x) => x.fieldId === this.currentField._id
    );
    if (currentIndex !== -1) this.updatedValues[currentIndex] = temp;
    else this.updatedValues.push(temp);
  }

  updateSnippedFieldPoso(value, newCoordinates) {
    this.currentField.editedValue = value;
    if (this.currentField.page !== undefined) {
      let index = -1;
      index = this.currentDoc.nonTabularData.findIndex(
        (x) => x._id === this.currentField._id
      );
      this.updateNonTabularField(
        value,
        index,
        this.currentField._id,
        newCoordinates
      );
    } else {
      let index = -1;
      let pageIndex = -1;
      pageIndex = this.currentDoc.pageArray.findIndex(
        (x) => x._id === this.currentPage._id
      );
      if (pageIndex > -1)
        index = this.currentDoc.pageArray[pageIndex].tableData.findIndex(
          (x) => x._id === this.currentField._id
        );
      this.currentDoc.pageArray[pageIndex].tableData[index].pts =
        newCoordinates;
      console.log(
        this.currentDoc.pageArray[pageIndex].tableData[index],
        'mine'
      );
      this.updateTabularField(
        value,
        index,
        this.currentField._id,
        newCoordinates
      );
    }
  }

  resetCurrentField() {
    this.currentField = {
      style: {
        height: '0px',
        width: '0px',
        display: 'none',
        top: '0px',
        left: '0px',
      },
    };
    imageStore.eraseDrawPoint();
  }

  undoChanges(index) {
    this.currentPage.formData[index].editedValue =
      this.currentPage.formData[index].aiValue;
    this.currentField.editedValue = this.currentPage.formData[index].aiValue;
    let i = this.updatedValues.findIndex(
      (x) => x.fieldId === this.currentField._id
    );
    if (i > -1) this.updatedValues.splice(i, 1);
  }

  undoPosoChanges() {
    let index = -1;
    if (this.currentField.page !== undefined) {
      /*index = this.currentDoc.nonTabularData.findIndex(x => x._id == this.currentField._id)
            if (index > -1) {
                this.currentField.editedValue = this.currentField.aiValue;
                this.currentField.pts = this.currentField.tempPts
                this.currentDoc.nonTabularData[index].editedValue = this.currentField.aiValue;
                this.currentDoc.nonTabularData[index].pts = this.currentField.tempPts
                let i = this.updatedNonTabularFields.findIndex(x => x.fieldId === this.currentField._id);
                if (i > -1)
                    this.updatedNonTabularFields.splice(i, 1);
            }*/
      this.updatedNonTabularFields = [];
      this.currentDoc.nonTabularData.forEach(function (v) {
        if (v.pts === null) v.pts = [];
        v.tempPts = v.pts;
        v.editedValue = v.aiValue;
        if (v.aiKey) {
          v.editedKey = v.aiKey;
        }
      });
    } else {
      let pageIndex = this.currentDoc.pageArray.findIndex(
        (x) => x._id === this.currentPage._id
      );
      index = this.currentDoc.pageArray[pageIndex].tableData.findIndex(
        (x) => x._id === this.currentField._id
      );
      if (index > -1) {
        this.currentField.editedValue = this.currentField.aiValue;
        this.currentField.pts = this.currentField.tempPts;
        this.currentDoc.pageArray[pageIndex].tableData[index].editedValue =
          this.currentField.aiValue;
        this.currentDoc.pageArray[pageIndex].tableData[index].pts =
          this.currentField.pts;
        let i = this.updatedTabularFields.findIndex(
          (x) => x.fieldId === this.currentField._id
        );
        if (i > -1) this.updatedTabularFields.splice(i, 1);
      }
    }
  }

  setCurrentField(item, index) {
    this.currentField = item;
    this.currentField.style = { height: '0px', width: '0px' };
    this.currentIndex = index;
  }

  editCurrentFieldValue(text) {
    console.log(text, 'hii', this.currentField);
    this.currentField.editedValue = text;
    //this.updateDocument(false,true)
  }

  setPosition(top, left) {
    this.currentField.style.top = top;
    this.currentField.style.left = left;
  }

  setCurrentTopValue() {
    this.currentTopValue = this.currentField.style.top;
  }

  moveTopPosition(top) {
    this.currentField.style.top =
      parseInt(this.currentTopValue) + parseInt(top) + 'px';
  }

  setFieldMeasure(h, w, display) {
    this.currentField.style = { height: h, width: w, display: display };
  }

  setCurrentImage(currentPage, pageNo) {
    this.currentPage = currentPage;
    this.currentPage.formData.forEach(function (v) {
      if (v.editedValue && v.editedValue.length) v.aiValue = v.editedValue;
    });
    this.resetCurrentField();
  }

  setCurrentPage(index) {
    this.currentPage = this.currentDoc.pageArray[index];
  }

  setCurrentImagePoSo(currentPage, pageNo) {
    this.currentPage = currentPage;
    this.currentDoc.nonTabularData.forEach(function (v) {
      if (v.editedValue && v.editedValue.length) v.aiValue = v.editedValue;
    });
    this.resetCurrentField();
  }

  openDocumentDrawer() {
    this.openDrawer = true;
  }

  closeDocumentDrawer() {
    this.openDrawer = false;
  }

  setupUpdatePayload(finalSubmit) {
    let payload = {};
    for (let i = 0; i < this.currentPage.formData.length; i++) {
      for (let j = 0; j < this.updatedValues.length; j++) {
        if (
          this.currentPage.formData[i]._id === this.updatedValues[j].fieldId
        ) {
          this.currentPage.formData[i].updateProgress = true;
          this.currentPage.formData[i].progressIcon = (
            <CircularProgress size={30} thickness={5} />
          );
        }
      }
    }
    payload.fieldArray = this.updatedValues;
    payload.documentId = this.currentDoc._id;
    payload.finalFlag = finalSubmit;
    payload.pageId = this.currentPage._id;
    return payload;
  }

  setProgressIcon(index) {
    this.currentPage.formData[index].progressIcon = <ReactSVG src="" />;
  }

  updateForm() {
    let payload = this.setupUpdatePayload(false);
    let currentIndex = this.currentDoc.pageArray.findIndex(
      (x) => x._id === this.currentPage._id
    );
    return agent.Documents.updateDocument(payload)
      .then(
        action((response) => {
          this.updateProgress = false;
          if (response.statusCode === 200) {
            this.currentPage.formData.forEach(function (v) {
              if (v.editedValue.length) v.aiValue = v.editedValue;
            });
            for (
              let i = 0;
              i < this.currentDoc.pageArray[currentIndex].formData.length;
              i++
            ) {
              for (let j = 0; j < this.updatedValues.length; j++) {
                if (
                  this.currentDoc.pageArray[currentIndex].formData[i]._id ===
                  this.updatedValues[j].fieldId
                ) {
                  this.currentDoc.pageArray[currentIndex].formData[
                    i
                  ].progressIcon = <ReactSVG src={Images['fieldRight']} />;
                  setTimeout(() => {
                    this.setProgressIcon(i);
                  }, 3000);
                }
              }
            }
            this.updatedValues = [];
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          //this.inProgress = false;
        })
      );
  }

  downloadOriginalDoc(documentId) {
    this.statusText = '';
    this.errors = undefined;
    this.downloadProgress = true;
    let temp = [documentId];
    return agent.Documents.getDownloadTokenForDoc(temp)
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            let link = document.createElement('a');
            link.href =
              constants.API_ROOT +
              `/upload/downloadOriginalDocFinkraft?downloadToken=${response.data.downloadToken}&fileName=${response.data.fileName}`;
            link.click();
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.downloadProgress = false;
        })
      );
  }

  updateDocStatus(documentId, status) {
    this.statusText = '';
    this.errors = undefined;
    if (status === 'REVIEWED') this.reviewProgress = true;
    else this.errorProgress = true;
    return agent.Documents.updateDocStatus(documentId, status)
      .then(
        action((response) => {
          if (response.statusCode === 200)
            commonStore.handleOpenSnackBar(
              'Document updated successfully!',
              'success'
            );
          else commonStore.handleOpenSnackBar(response.message, 'error');
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.message;
          commonStore.handleOpenSnackBar(
            err.response && err.response.body && err.response.body.message,
            'error'
          );
        })
      )
      .finally(
        action(() => {
          this.reviewProgress = false;
          this.errorProgress = false;
        })
      );
  }

  updateDocument(finalSubmit, next) {
    let currentIndex = this.currentDoc.pageArray.findIndex(
      (x) => x._id === this.currentPage._id
    );
    this.statusText = '';
    this.errors = undefined;
    if (finalSubmit) this.updateProgress = true;
    if (this.updatedValues.length === 0) {
      if (!finalSubmit && !next) return;
    }

    let payload = this.setupUpdatePayload(finalSubmit);
    if (next) {
      if (currentIndex > -1)
        this.setCurrentImage(
          this.currentDoc.pageArray[currentIndex + 1],
          currentIndex + 1
        );
    }
    return agent.Documents.updateDocument(payload)
      .then(
        action((response) => {
          this.updateProgress = false;
          if (response.statusCode === 200) {
            if (finalSubmit)
              this.currentDoc.pageArray[currentIndex].reviewed = true;
            for (
              let i = 0;
              i < this.currentDoc.pageArray[currentIndex].formData.length;
              i++
            ) {
              for (let j = 0; j < this.updatedValues.length; j++) {
                if (
                  this.currentDoc.pageArray[currentIndex].formData[i]._id ===
                  this.updatedValues[j].fieldId
                ) {
                  this.currentDoc.pageArray[currentIndex].formData[
                    i
                  ].progressIcon = <ReactSVG src={Images['fieldRight']} />;
                  setTimeout(() => {
                    this.setProgressIcon(i);
                  }, 3000);
                }
              }
            }
            this.updatedValues = [];
            if (finalSubmit)
              commonStore.handleOpenSnackBar(
                'Document updated successfully!',
                'success'
              );
          } else {
            commonStore.handleOpenSnackBar(response.message, 'error');
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          //this.inProgress = false;
        })
      );
  }

  setupUpdatePosoPayload(review, type) {
    let payload = {};
    if (this.updatedTabularFields.length) {
      this.currentDoc.pageArray.forEach((page) => {
        for (let i = 0; i < page.tableData.length; i++) {
          for (let j = 0; j < this.updatedTabularFields.length; j++) {
            if (
              page.tableData[i]._id === this.updatedTabularFields[j].fieldId
            ) {
              page.tableData[i].updateProgress = true;
              if (type === 'right') {
                page.tableData[i].progressIcon = (
                  <ReactSVG src={Images['fieldRight']} />
                );
              } else if (type === 'end')
                page.tableData[i].progressIcon = <ReactSVG src="" />;
              else
                page.tableData[i].progressIcon = (
                  <CircularProgress size={30} thickness={5} />
                );
            }
          }
        }
      });
    }
    if (this.updatedNonTabularFields.length) {
      for (let i = 0; i < this.currentDoc.nonTabularData.length; i++) {
        for (let j = 0; j < this.updatedNonTabularFields.length; j++) {
          if (
            this.currentDoc.nonTabularData[i]._id ===
            this.updatedNonTabularFields[j].fieldId
          ) {
            this.currentDoc.nonTabularData[i].updateProgress = true;
            if (type === 'right')
              this.currentDoc.nonTabularData[i].progressIcon = (
                <ReactSVG src={Images['fieldRight']} />
              );
            else if (type === 'end')
              this.currentDoc.nonTabularData[i].progressIcon = (
                <ReactSVG src="" />
              );
            else
              this.currentDoc.nonTabularData[i].progressIcon = (
                <CircularProgress size={30} thickness={5} />
              );
          }
        }
      }
    }

    // payload.tabularDataArray = this.updatedTabularFields;
    payload.nonTabularDataArray = this.updatedNonTabularFields;
    payload.documentId = this.currentDoc._id;
    payload.reviewStatus = review;
    return payload;
  }

  updateTextField(value, index, fieldId, coordinates) {
    this.currentDoc.nonTabularData[index].aiValue = value;
    this.currentDoc.nonTabularData[index].editedValue = value;
    if (coordinates !== undefined)
      this.currentDoc.nonTabularData[index].pts = coordinates;
    this.currentField.aiValue = value;
    this.currentField.editedValue = value;
    let temp = {};
    temp.fieldId = fieldId;
    temp.fieldValue = value;
    if (coordinates !== undefined) temp.pts = coordinates;
    let currentIndex = this.updatedNonTabularFields.findIndex(
      (x) => x.fieldId === fieldId
    );
    if (currentIndex !== -1) this.updatedNonTabularFields[currentIndex] = temp;
    else this.updatedNonTabularFields.push(temp);
  }

  setupPayloadForPosoField(reviewStatus) {
    let payload = {};
    /* let obj = { fieldId: field._id, fieldValue: field.editedValue, pts: field.pts }
            let temp = []
            temp.push(obj)
            let index = this.updatedNonTabularFields.findIndex(x => x.fieldId == field._id)
            if (index > -1)
                this.updatedNonTabularFields.splice(index, 1)*/
    payload.nonTabularDataArray = this.updatedNonTabularFields;
    payload.documentId = this.currentDoc._id;
    payload.reviewStatus = reviewStatus;
    return payload;
  }

  setProgressIconPoso(field) {
    this.setupUpdatePosoPayload('end');
    if (field === undefined) {
      this.updatedNonTabularFields = [];
      this.updatedTabularFields = [];
    }
  }

  reviewDocument() {}

  updatePosoDocument(review, autoSave) {
    // let currentIndex = this.currentDoc.pageArray.findIndex(
    //   (x) => x._id == this.currentPage._id
    // );
    this.statusText = '';
    this.errors = undefined;
    if (!autoSave || autoSave === undefined) this.updateProgress = true;
    let payload = {};
    if (review) this.reviewProgress = true;
    payload = this.setupUpdatePosoPayload(review);
    return agent.Documents.updateDocument(payload)
      .then(
        action((response) => {
          this.updateProgress = false;
          if (response.statusCode === 200) {
            // this.setupUpdatePosoPayload('right')
            this.currentDoc = response.data;
            this.currentPage = this.currentDoc.pageArray[0];
            if (this.currentDoc.nonTabularData.length) {
              this.currentIndex = 0;
              this.currentDoc.nonTabularData.forEach(function (v) {
                if (v.pts === null) v.pts = [];
                v.tempPts = v.pts;
                v.editedValue = v.aiValue;
                if (v.aiKey) {
                  v.editedKey = v.aiKey;
                }
              });
              this.resetCurrentField();
            }
            //   setTimeout(() => { this.setProgressIconPoso(field) }, 3000)
            commonStore.handleOpenSnackBar(
              'Document updated successfully!',
              'success'
            );
          } else {
            commonStore.handleOpenSnackBar(response.message, 'error');
            this.setupUpdatePosoPayload('end');
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.reviewProgress = false;
        })
      );
  }

  headerDisplayed(header) {
    this.tableHeaders.push(header);
    var set = new Set(this.tableHeaders);
    this.tableHeaders = Array.from(set);
  }

  updateMediassistDocument(field, autoSave) {
    // let currentIndex = this.currentDoc.pageArray.findIndex(
    //   (x) => x._id == this.currentPage._id
    // );
    this.statusText = '';
    this.errors = undefined;
    if (!autoSave || autoSave === undefined) this.updateProgress = true;
    let payload = {};
    if (field !== undefined) payload = this.setupPayloadForPosoField(field);
    else payload = this.setupUpdatePosoPayload();
    delete payload.tabularDataArray;
    return agent.Documents.updateMediassistDocument(payload)
      .then(
        action((response) => {
          this.updateProgress = false;
          if (response.statusCode === 200) {
            this.setupUpdatePosoPayload('right');
            setTimeout(() => {
              this.setProgressIconPoso(field);
            }, 3000);
            commonStore.handleOpenSnackBar(
              'Document updated successfully!',
              'success'
            );
          } else {
            commonStore.handleOpenSnackBar(response.message, 'error');
            this.setupUpdatePosoPayload('end');
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.updateProgress = false;
        })
      );
  }

  updateWritercorpDocument(field, autoSave) {
    // let currentIndex = this.currentDoc.pageArray.findIndex(
    //   (x) => x._id == this.currentPage._id
    // );
    this.statusText = '';
    this.errors = undefined;
    if (!autoSave || autoSave === undefined) this.updateProgress = true;
    let payload = {};
    if (field !== undefined) payload = this.setupPayloadForPosoField(field);
    else payload = this.setupUpdatePosoPayload();

    return agent.Documents.updateWritercorpDocument(payload)
      .then(
        action((response) => {
          this.updateProgress = false;
          if (response.statusCode === 200) {
            this.setupUpdatePosoPayload('right');
            setTimeout(() => {
              this.setProgressIconPoso(field);
            }, 3000);
            commonStore.handleOpenSnackBar(
              'Document updated successfully!',
              'success'
            );
          } else {
            commonStore.handleOpenSnackBar(response.message, 'error');
            this.setupUpdatePosoPayload('end');
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.updateProgress = false;
        })
      );
  }

  setProgress() {
    this.inProgress = true;
  }

  getDocumentDetails(docId) {
    this.statusText = '';
    this.errors = undefined;
    this.inProgress = true;
    return agent.Documents.getDocumentDetails(docId)
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.currentDoc = response.documentDetails;
            this.currentPage = this.currentDoc.pageArray[0];
            if (
              this.financeDocs.findIndex(
                (x) => x === this.currentDoc.docType
              ) === -1
            ) {
              if (this.currentPage.formData.length) {
                this.currentIndex = 0;
                this.currentPage.formData.forEach(function (v) {
                  v.tempPts = v.pts;
                  v.editedValue = v.aiValue;
                  if (v.aiKey) {
                    v.editedKey = v.aiKey;
                  }
                });
                this.resetCurrentField();
              }
            } else {
              if (this.currentDoc.nonTabularData.length) {
                this.currentIndex = 0;
                this.currentDoc.nonTabularData.forEach(function (v) {
                  if (v.pts === null) v.pts = [];
                  v.tempPts = v.pts;
                  v.editedValue = v.aiValue;
                  if (v.aiKey) {
                    v.editedKey = v.aiKey;
                  }
                });
                this.resetCurrentField();
              }
              this.currentDoc.pageArray.forEach((page) => {
                if (page.tableData.length) {
                  this.currentIndex = 0;
                  page.tableData.forEach(function (v) {
                    v.tempPts = v.pts;
                    if (v.editedValue && v.editedValue.length)
                      v.aiValue = v.editedValue;
                    else if (v.aiValue === null) v.aiValue = '';
                  });
                }
              });
              this.resetCurrentField();
            }
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }

  getExtDocumentDetails(docId) {
    this.statusText = '';
    this.errors = undefined;
    this.inProgress = true;
    return agent.Documents.getExtDocumentDetails(docId)
      .then(
        action((response) => {
          if (response.statusCode === 200) {
            this.currentDoc = response.documentDetails;
            this.currentPage = this.currentDoc.pageArray[0];
            if (
              this.financeDocs.findIndex(
                (x) => x === this.currentDoc.docType
              ) === -1
            ) {
              if (this.currentPage.formData.length) {
                this.currentIndex = 0;
                this.currentPage.formData.forEach(function (v) {
                  v.tempPts = v.pts;
                  v.editedValue = v.aiValue;
                  if (v.aiKey) {
                    v.editedKey = v.aiKey;
                  }
                });
                this.resetCurrentField();
              }
            } else {
              if (this.currentDoc.nonTabularData.length) {
                this.currentIndex = 0;
                this.currentDoc.nonTabularData.forEach(function (v) {
                  if (v.pts === null) v.pts = [];
                  v.tempPts = v.pts;
                  v.editedValue = v.aiValue;
                  if (v.aiKey) {
                    v.editedKey = v.aiKey;
                  }
                });
                this.resetCurrentField();
              }
              this.currentDoc.pageArray.forEach((page) => {
                if (page.tableData.length) {
                  this.currentIndex = 0;
                  page.tableData.forEach(function (v) {
                    v.tempPts = v.pts;
                    if (v.editedValue && v.editedValue.length)
                      v.aiValue = v.editedValue;
                    else if (v.aiValue === null) v.aiValue = '';
                  });
                }
              });
              this.resetCurrentField();
            }
          }
        })
      )
      .catch(
        action((err) => {
          this.errors =
            err.response && err.response.body && err.response.body.errors;
        })
      )
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  }
}

export default new DocumentStore();
