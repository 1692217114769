import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { commonStyles } from '../Styles/commonStyles';
import InvoiceListing from './InvoiceListing/InvoiceListing';
import CurrentDocumentPoso from './Documents/PO-SO/CurrentDocumentPoSo';
// import Loader from 'react-loader-spinner';
class Home extends Component {
  state = {
    reload: false,
  };

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState(this.setLocalStorage()),
      10
    );
  }

  componentWillUnmount() {
    if (
      window.localStorage.getItem('accessToken') === this.props.commonStore.token
    )
      clearInterval(this.interval);
  }

  setLocalStorage = () => {
    this.props.websocketStore.connect();
    if (window.localStorage.getItem('accessToken'))
      this.props.commonStore.setToken(
        window.localStorage.getItem('accessToken')
      );
    else {
      window.localStorage.setItem('accessToken', 'unset');
      this.props.commonStore.setToken('unset');
    }
    if (window.initial_data && window.initial_data.token) {
      window.localStorage.setItem('accessToken', window.initial_data.token);
      this.setState({ reload: true });
      this.props.commonStore.setToken(window.initial_data.token);
      clearInterval(this.interval);
      this.props.websocketStore.connect();
    }
  };

  render() {
    return (
      <div className="pageContainer">
        <Switch>
          <Route
            path={'/document/:documentId'}
            component={() => <CurrentDocumentPoso />}
          />
          <Route
            path={'/extDocument/:docId'}
            component={() => <CurrentDocumentPoso />}
          />

          <Route path={'/'} component={() => <InvoiceListing />} />
        </Switch>
      </div>
    );
  }
}
export default withStyles(commonStyles, { withTheme: true })(
  withRouter(inject('commonStore', 'websocketStore')(observer(Home)))
);
