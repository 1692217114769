import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { hideLoader, showLoader } from '@/actions/loaderActions';
import { setLoggedInUser } from '@/actions/authActions';

import auth from '@/services/authService';

const setLocalStorageItems = ({
  accessToken = null,
  expiresIn = null,
  email = null,
  organizationId = null,
  organizationName = null,
}) => {
  const expiresAt = new Date().getTime() + Number(expiresIn) * 1000 + 1000;

  accessToken && localStorage.setItem('accessToken', accessToken);
  expiresIn && localStorage.setItem('expiresAt', expiresAt);
  email && localStorage.setItem('email', email);
  organizationId && localStorage.setItem('organizationId', organizationId);
  organizationName &&
    localStorage.setItem('organizationName', organizationName);
};

const CallbackContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const auth0CallbackFunction = async () => {
      try {
        dispatch(showLoader());
        const authResult = await auth.getAuthResult();
        const { accessToken, expiresIn } = authResult;
        const user = await auth.getUserInfo({
          accessToken,
        });
        const { email } = user;
        setLocalStorageItems({
          accessToken,
          expiresIn,
          email,
        });

        dispatch(
          setLoggedInUser({
            accessToken: accessToken,
            emailId: email,
            auth0Result: authResult,
            auth0User: user,
          })
        );
        const referrer = localStorage.getItem('referrer');
        if (referrer) {
          localStorage.removeItem('referrer');
          props.history.replace(referrer);
        } else {
          props.history.replace(`/dashboards`);
        }
      } catch (error) {
        props.history.replace('/login');
      } finally {
        dispatch(hideLoader());
      }
    };
    auth0CallbackFunction();
  }, [dispatch, props.history]);

  return null;
};

export default CallbackContainer;
