import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class StaticRector extends Component {
  state = {
    zoom: 1,
    backgroundPosition: '0px 0px',
  };
  canvas = null;
  ctx = null;

  constructor(props) {
    super(props);
    this.state.image = this.props.imageSrc;
    this.state.imageContainerWidth = this.props.imageContainerWidth;
    this.state.imageContainerHeight = this.props.imageContainerHeight;
  }

  componentDidMount() {
    this.setState({
      size: {
        h: this.props.height,
        w: this.props.width,
      },
      crop: {
        x: 0,
        y: 0,
      },
    });
    this.ctx = this.canvas.getContext('2d');
    this.ctx.lineWidth = this.props.lineWidth;
    setTimeout(() => {
      this.highlightAllFields();
    }, 1000);
  }

  highlightAllFields = () => {
    this.ctx.fillStyle = 'rgba(4, 114, 76, 0.36)';
    const { currentDoc } = this.props.documentStore;
    let fields = [];
    currentDoc.nonTabularData &&
      currentDoc.nonTabularData.forEach((row, index) => {
        let x1Cordinate = currentDoc.nonTabularData[index].pts[0]; // original points coming from AI x1
        let y1Cordinate = currentDoc.nonTabularData[index].pts[1]; // original points coming from AI y1
        let widthCordinate = currentDoc.nonTabularData[index].pts[2]; // original points coming from AI width point
        let heightCordinate = currentDoc.nonTabularData[index].pts[3];
        let rectangle = {
          x: x1Cordinate * this.props.imageContainerWidth,
          y: y1Cordinate * this.props.imageContainerHeight,
          w:
            widthCordinate * this.props.imageContainerWidth -
            x1Cordinate * this.props.imageContainerWidth,
          h:
            heightCordinate * this.props.imageContainerHeight -
            y1Cordinate * this.props.imageContainerHeight,
          bgColor:
            row.confidence === 'L'
              ? 'rgba(255, 23,23 , 0.32)'
              : 'rgba(4, 114, 76,0.36)',
        };
        fields.push(rectangle);
      });

    var hash = [...new Set(fields.map((v) => JSON.stringify(v)))].map((v) =>
      JSON.parse(v)
    );

    hash.forEach((row) => {
      this.updateCanvasForSelectedField(row);
    });
  };
  updateCanvasForSelectedField = (rect) => {
    this.ctx.beginPath();
    this.setState({ backgroundPosition: '0px 0px' });
    this.ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
    this.ctx.fillStyle = rect.bgColor;
    this.ctx.strokeStyle = this.props.strokeStyle;
    this.ctx.lineWidth = this.props.lineWidth;
  };

  render() {
    const { focusOnPoint } = this.props.imageStore;
    const { backgroundPosition } = this.state;

    return (
      <canvas
        width={this.props.imageContainerWidth}
        height={this.props.imageContainerHeight}
        ref={(c) => {
          this.canvas = c;
        }}
        style={{
          width: '100%',
          backgroundImage: `url(${this.props.imageSrc})`,
          backgroundSize: '100%',
          cursor: 'pointer',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: backgroundPosition,
          imageOrientation: 'from-image',
          transform: `scale(${focusOnPoint.zoom})`,
        }}
      />
    );
  }
}

export default inject('documentStore', 'imageStore')(observer(StaticRector));
