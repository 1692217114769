import { useContext, useCallback } from 'react';
import Toaster from '@setproduct-ui/Toast/Toaster';

import ToastNotificationContext, {
  defaultToastProps,
} from '@/contexts/ToastNotificationContext';

const ToastNotification = () => {
  const { setToastRefs } = useContext(ToastNotificationContext);

  const setErrorToastRef = useCallback(
    (ref) => {
      setToastRefs((prevState) => ({
        ...prevState,
        errorToastRef: ref,
      }));
    },
    [setToastRefs]
  );

  const setSuccessToastRef = useCallback(
    (ref) => {
      setToastRefs((prevState) => ({
        ...prevState,
        successToastRef: ref,
      }));
    },
    [setToastRefs]
  );

  const setWarningToastRef = useCallback(
    (ref) => {
      setToastRefs((prevState) => ({
        ...prevState,
        warningToastRef: ref,
      }));
    },
    [setToastRefs]
  );

  return (
    <>
      <Toaster
        {...defaultToastProps}
        type="def"
        color="danger"
        ref={setErrorToastRef}
        maxToasts={1}
        usePortal={true}
      />
      <Toaster
        {...defaultToastProps}
        type="def"
        color="success"
        ref={setSuccessToastRef}
        maxToasts={1}
        usePortal={true}
      />
      <Toaster
        {...defaultToastProps}
        type="def"
        color="warning"
        ref={setWarningToastRef}
        maxToasts={1}
        usePortal={true}
      />
    </>
  );
};

export default ToastNotification;
