import axios from 'axios';
import { axiosInstance } from './axiosInstance';

export const getGstinInfo = ({ gstin }) => {
  return axiosInstance.get(`/iris-gst-api/gstins/${gstin}`);
};

export const getOrganizationGstins = (user_name) => {
  return axios.get(
    `https://api.finkraft.ai/api/organizations/gstn?user_name=${user_name}`
  );
};

export const getSapOrganizationGstins = (obj) => {
  return axios.get(`https://api.finkraft.ai/api/organizations/gstn`, {
    params: obj,
  });
};

export const setOrganizationGstins = ({ form }) => {
  return axiosInstance.post('/gstin', form);
};
