import { useFormikContext, Field } from 'formik';

import Button from '@setproduct-ui/Button';

import { FormRow } from '@/components/FormUIElements';
import { FormTextInput } from '@/components/FormComponents';
import Verified from '@/components/Verified';

const GstinVerification = ({
  verifyingGstin,
  gstinVerified,
  handleVerifyGstin,
  ...props
}) => {
  const { values } = useFormikContext();

  return (
    <FormRow {...props}>
      <Field
        name="organisationName"
        label="Organisation Name"
        component={FormTextInput}
      />
      <Field name="gstin" label="GSTIN" component={FormTextInput} />
      {!gstinVerified && (
        <Button
          type="default"
          view="smooth"
          color="primary"
          loading={verifyingGstin}
          onClick={() => handleVerifyGstin(values.gstin)}
          disabled={!values.gstin || !values.gstin.length === 15}
        >
          Verify GSTIN
        </Button>
      )}
      {gstinVerified && <Verified />}
    </FormRow>
  );
};

export default GstinVerification;
