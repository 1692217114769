/*
  InvoiceField component
*/
import { withRouter } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { inputStyles } from '../../../Styles/documentStyles';
import { TextField, Tooltip } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import tick from '../../../Assets/greenTick.svg';
import cancel_dim from '../../../Assets/cancel_outline.svg';

import clsx from 'clsx';
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#151515',
    color: theme.palette.white,
    boxShadow: 'none',
    fontSize: '12px',
  },
  arrow: {
    color: '#151515',
  },
}))(Tooltip);

class InvoiceField extends React.Component {
  render() {
    const {
      classes,
      label,
      value,
      disabled,
      endAdornment,
      onClick,
      from,
      confidence,
      key,
    } = this.props;

    return (
      <div className="fullWidth">
        <div className={classes.invoiceLabel}>{label}</div>
        <div
          className={clsx({
            [`${classes.inputContainer}`]: true,
            [`${classes.fieldIpContainer}`]: from === 'dialog',
            [`${classes.lowConfidence}`]: confidence === 'L',
          })}
        >
          <LightTooltip
            title={confidence === 'L' ? 'Low confidence score' : ''}
            placement="right"
            arrow
          >
            <div className={'rowFlex fullWidth'}>
              <TextField
                className={classes.input}
                value={value}
                multiline
                key={key}
                onClick={onClick}
                disabled={disabled}
                InputProps={{
                  disableUnderline: true,
                  //  classes: { input: classes.textFieldInputSansLabel, root: classes.textFieldRoot, disabled: classes.disabledText },
                  //   endAdornment: <InputAdornment position="end" > {endAdornment} </InputAdornment>
                }}
                onChange={this.props.onChange}
              />
              {endAdornment ? (
                <div className="colFlex flexCentered">
                  {' '}
                  <ReactSVG src={endAdornment} />{' '}
                </div>
              ) : null}
            </div>
          </LightTooltip>
          {from === 'dialog' && (
            <div className="rowFlex flexCentered">
              <img
                src={cancel_dim}
                alt="remove"
                width={16}
                style={{ marginRight: 5 }}
              />
              <img
                src={tick}
                alt="tick"
                width={16}
                style={{ marginRight: 5 }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(inputStyles, { withTheme: true })(
  withRouter(observer(InvoiceField))
);
