import { useState } from 'react';
import Axios from 'axios';
// import Loader from 'react-loader-spinner';
import './styles/uploadlogs.css';
import { Close } from '@material-ui/icons';
const BulkUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [progress, setProgress] = useState(0);

  const validate = async () => {
    if (selectedFiles.length <= 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const reset = () => {
    setIsError(false);
    setLoading(false);
  };

  const uploadBulk = async () => {
    await validate();
    if (selectedFiles.length > 0) {
      await setIsSubmitting(true);
      await setLoading(true);
      try {
        const formData = new FormData();
        formData.append('doc_type', props.fileType);
        formData.append('file', selectedFiles[0]);
        const { data: uploadResponse } = await Axios.post(
          'https://api.finkraft.ai/api/documents/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (data) => {
              setProgress(Math.round((100 * data.loaded) / data.total));
            },
          }
        );

        if (!uploadResponse?.status) {
          alert(uploadResponse?.message);
          setIsSubmitting(false);
        }
        await reset();
      } catch (error) {
        console.error(error);
        reset();
        alert(JSON.stringify(error) || error);
      }
    }
  };
  const selectFile = async (files) => {
    setSelectedFiles([...selectedFiles, ...files]);
    setIsError(false);
  };

  // if (isSubmitting) {
  //   return <Loader type="TailSpin" color="#87cefa" height={100} width={100} />;
  // }
  const getFileSize = (size) => {
    if (size <= 1024) {
      return size + ' B';
    } else if (size > 1024 && size <= 1024 * 1024 * 5) {
      if (size > 10000) {
        return parseFloat(size / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        return parseFloat(size / 1024).toFixed(2) + ' KB';
      }
    }
  };
  return (
    <div>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          uploadBulk();
        }}
        className="m-1 bulk-upload-form"
      >
        {props.label && <h2>{props.label} Upload</h2>}
        <div className="form-groups1 mb-2">
          <div className="form-dwnGroup">
            <a className="sample-btn" href={props.url} download>
              Sample download file
            </a>
            <span className="infodwn">
              Note: Sample file are the pain template without the data
            </span>
          </div>
          <p className="infodwn2 mt-1">
            Download a sample .csv format or .xls format file and compare it to
            your import file to ensure that the file is ready to import.
          </p>
        </div>
        <div className="form-groups1">
          <label className="label">Upload File *</label>
          <div className="form-groups1A">
            <label className="filewrap mt-1" htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{
                  display: 'none',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => selectFile(e.target.files)}
                multiple="multiple"
              />
              <input
                value={selectedFiles.map((file) => {
                  return file.name;
                })}
                disabled={true}
              />
              <button
                type="button"
                onClick={() => {
                  document.getElementById('btn-upload').value = '';
                  document.getElementById('btn-upload').click();
                }}
              >
                Browse Files
              </button>
              <p className="err">
                {isError && 'Please select a file to upload'}
              </p>
            </label>
          </div>
        </div>
        <p className="infodwn">
          Maximum File Size: 5 MB | File Format: CSV or TSV or XLS
        </p>
        <div className="form-groups1B mt-3">
          <button
            className="cancel-btn"
            type="button"
            onClick={() => {
              setIsError(false);
            }}
          >
            Cancel
          </button>
          <button className="submit-btn" type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </div>
      </form>
      {isSubmitting && (
        <div className={'uploading-card'}>
          <Close
            className="close-icon"
            onClick={() => {
              setIsSubmitting(false);
              setSelectedFiles([]);
              setProgress(0);
            }}
          />
          <h3>
            {loading ? 'Uploading' : 'Uploaded'} {selectedFiles.length} receipt…
            {/* <span>View</span> */}
            <br />
            <br />
            {selectedFiles.map((item) => {
              return (
                <span className="infodwn">
                  {item.name} ({getFileSize(item.size)})
                </span>
              );
            })}
          </h3>
          <br />
          <div className="upload-progress">
            <span
              style={{
                display: 'block',
                width: `${progress}%`,
                background: '#42A743',
                height: '5px',
                borderRadius: '6px',
              }}
            ></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkUpload;
