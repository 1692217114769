/**
 * Images should be stored in the `Assets` directory and referenced using variables defined here.
 */
// eslint-disable-next-line
export default {
    //Listin page
    dropDown: require('../Assets/dropDown.svg'),
    uploadDropDown: require('../Assets/uploadDropDown.svg'),
    sort: require('../Assets/sort.svg'),
    search: require('../Assets/search.svg'),
    filter: require('../Assets/filter.svg'),
    download: require('../Assets/download.svg'),
    next: require('../Assets/next.svg'),
    prev: require('../Assets/prev.svg'),
    upload: require('../Assets/upload.svg'),
    error: require('../Assets/error.svg'),
    done: require('../Assets/done.svg'),
    close: require('../Assets/close.svg'),
    folder: require('../Assets/folder.svg'),
    file: require('../Assets/file.svg'),
    //Document page
    scanDone: require('../Assets/scanDone.svg'),
    scanFailed: require('../Assets/scanFailed.svg'),
    scanError: require('../Assets/scanError.svg'),
    reviewPending: require('../Assets/reviewPending.svg'),
    greenTick: require('../Assets/greenTick.svg'),
    greenTickBig: require('../Assets/greenTickBig.svg'),
    smallClose: require('../Assets/smallClose.svg'),
    zoomIn: require('../Assets/zoomIn.svg'),
    zoomOut: require('../Assets/zoomOut.svg'),
    docDownload: require('../Assets/docDownload.svg'),
}