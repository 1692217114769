import styled from 'styled-components/macro';

export const FlexContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
`;

export const FlexRowContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const FlexColumnContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  width: 280px;
  height: 56px;
  font-family: 'Inter-Bold';
  font-size: 20px;
  line-height: 24px;
  color: #2b2b2b;
  background-color: #92f9ff;
  border-radius: 6px;
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
`;

export const AnchorButton = styled.a`
  width: 280px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter-Bold';
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #2b2b2b;
  background-color: #92f9ff;
  border-radius: 6px;
  cursor: pointer;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  text-decoration: none;
`;
