import { useFormikContext, Field } from 'formik';

import Button from '@setproduct-ui/Button';

import { FormRow } from '@/components/FormUIElements';
import { FormTextInput } from '@/components/FormComponents';
import Verified from '@/components/Verified';

const MobileNumberVerification = ({
  sendingSmsOtp,
  smsOtpSent,
  verifyingSmsOtp,
  smsOtpVerified,
  handleGetSmsOtp,
  handleVerifySmsOtp,
}) => {
  const { values } = useFormikContext();

  return (
    <FormRow>
      <Field
        name="mobileNumber"
        label="Mobile Number"
        component={FormTextInput}
      />
      {!smsOtpSent && !smsOtpVerified && (
        <Button
          type="default"
          view="smooth"
          color="primary"
          loading={sendingSmsOtp}
          onClick={() => handleGetSmsOtp(values.mobileNumber)}
          disabled={values.mobileNumber.length !== 10}
        >
          Request OTP
        </Button>
      )}
      {smsOtpSent && !smsOtpVerified && (
        <Field name="smsOtp" label="OTP" component={FormTextInput} />
      )}
      {smsOtpSent && !smsOtpVerified && (
        <Button
          type="default"
          view="smooth"
          color="primary"
          loading={verifyingSmsOtp}
          onClick={() => handleVerifySmsOtp(values.mobileNumber, values.smsOtp)}
          disabled={!values.smsOtp || !values.smsOtp.length === 6}
        >
          Verify
        </Button>
      )}
      {smsOtpSent && smsOtpVerified && <Verified />}
    </FormRow>
  );
};

export default MobileNumberVerification;
