import { useFormikContext, Field } from 'formik';
import * as Yup from 'yup';

import Button from '@setproduct-ui/Button';

import { FormRow } from '@/components/FormUIElements';
import { FormTextInput } from '@/components/FormComponents';
import Verified from '@/components/Verified';

const isValidEmail = (email) => {
  const schema = Yup.string().email().required();
  return schema.isValidSync(email);
};

const EmailVerification = ({
  sendingEmailOtp,
  emailOtpSent,
  verifyingEmailOtp,
  emailOtpVerified,
  handleGetEmailOtp,
  handleVerifyEmailOtp,
}) => {
  const { values } = useFormikContext();

  return (
    <FormRow>
      <Field name="emailId" label="Email Address" component={FormTextInput} />
      {!emailOtpSent && !emailOtpVerified && (
        <Button
          type="default"
          view="smooth"
          color="primary"
          loading={sendingEmailOtp}
          onClick={() => handleGetEmailOtp(values.emailId)}
          disabled={!isValidEmail(values.emailId)}
        >
          Request OTP
        </Button>
      )}
      {emailOtpSent && !emailOtpVerified && (
        <Field name="emailOtp" label="OTP" component={FormTextInput} />
      )}
      {emailOtpSent && !emailOtpVerified && (
        <Button
          type="default"
          view="smooth"
          color="primary"
          loading={verifyingEmailOtp}
          onClick={() => handleVerifyEmailOtp(values.emailId, values.emailOtp)}
          disabled={!values.emailOtp || !values.emailOtp.length === 6}
        >
          Verify
        </Button>
      )}
      {emailOtpSent && emailOtpVerified && <Verified />}
    </FormRow>
  );
};

export default EmailVerification;
