import React from 'react';
import finkraftLogowhite from '@/assets/logos/finkraftLogo-white.png';
import './sap.css';
import { useParams } from 'react-router-dom';

const SapLoginAfter = () => {
  const params = useParams();
  return (
    <div className="sap-after-login">
      <nav>
        <img src={finkraftLogowhite} alt="logo-white" width={200} />
      </nav>
      {params.gstin_id ? (
        <iframe
          title="Sap Dashboard"
          src={`https://analytics.zoho.in/open-view/103074000011224418/0990bb24adfad706b7aed7b012543cfb?ZOHO_CRITERIA=%22Customer_GSTIN%22=%27${params.gstin_id}%27`}
          style={{
            border: 'none',
            width: '100%',
            height: '100vh',
            marginTop: '2rem',
          }}
        ></iframe>
      ) : (
        <div></div>
      )}
      <div className="rect">
        <div>
          <p className="ques">Do you have your Invoices for reconciliation?</p>
          <p className="note">
            Access complete GST Report along with the Reconciliation with GST2A
            data. Ensure your organisation claims maximum GST input tax credit
            using Finkraft.
          </p>
        </div>
        <div className="btns">
          <a
            className="access"
            href="https://qatar-finkraft.typeform.com/to/nn2sR8yH"
            target="_blank"
            rel="noreferrer"
          >
            Book a free consultation
          </a>
          {/* <button className="link">Book a free consultation</button> */}
        </div>
      </div>
    </div>
  );
};

export default SapLoginAfter;
