import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import auth from '@/services/authService';
import * as Cookies from 'js-cookie';

const ProtectedRoute = (props) => {
  if (!auth.isTokenValid()) {
    localStorage.setItem('referrer', props.location.pathname);
  }

  return auth.isTokenValid() ||
    (Cookies.get('accessToken') &&
      Cookies.get('email') &&
      Cookies.get('expiresAt') &&
      Cookies.get('isSSOLogin')) ? (
    <Route {...props} />
  ) : (
    <Redirect to="/" />
  );
};

export default ProtectedRoute;
