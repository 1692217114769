import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';
import down_arrow from '../../../Assets/down_arrow.svg';
// import up_arrow from "../../../Assets/up_arrow.svg";
import tick from '../../../Assets/greenTick.svg';
// import tick_dim from '../../../Assets/tick_dim.svg';
import information from '../../../Assets/information.svg';
//import { isObjectEmpty } from '../../utils';
import { withStyles } from '@material-ui/core/styles';
import { documentStyles } from '../../../Styles/documentStyles';
import Images from '../../../Theme/Images';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ReactSVG } from 'react-svg';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import IdleTimer from 'react-idle-timer';
import SubmitDialog from '../SubmitDialog';
import { Scrollbars } from 'react-custom-scrollbars';
import TabularData from './TabularData';
import SimpleButton from '../../../UIComponents/SimpleButton';
import InvoiceField from './InvoiceField';
// import QRData from './QRData';
const tabList = ['Non-tabular data', 'Tabular data'];
const writerCorpTabs = ['Tabular data', 'Non-tabular data'];

const keyStrings = {
  invoice_number: 'Invoice number',
  date: 'Date',
  buyer_goods_services_tax_identification_number: 'Buyer GSTIN',
  supplier_goods_services_tax_identification_number: 'Seller GSTIN',
  supplier_name: 'Seller name',
  invoice_reference_number: 'IRN',
  tax_collected_at_source: 'Tax collected at source',
  integrated_goods_services_tax: 'Integrated goods tax',
  central_goods_services_tax: 'Central goods tax',
  state_goods_services_tax: 'State goods tax',
  taxable_amount: 'Taxable amount',
  total_tax: 'Total tax',
  invoice_amount: 'Invoice amount',
};

class PoSoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationErrors: {},
      qrExpanded: true,
      openSubmitDialog: false,
      currentField: '',
      activeTab:
        props.documentStore.currentDoc.docType === 'WRITERCORP'
          ? 'Tabular data'
          : 'Non-tabular data',
      formHeight: '0px',
    };
    this._areaElement = new Map();
    this._areaElementBack = new Map();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    let elem = document.getElementById('qrFieldForm');
    if (elem)
      this.setState({ formHeight: elem.clientHeight - 128 - 64 + 'px' });
  };

  handleTabChange = (event, value) => {
    console.log(event, 'event');
    if (this.props.documentStore.currentDoc.docType === 'WRITERCORP')
      this.setState({ activeTab: writerCorpTabs[value] });
    else this.setState({ activeTab: tabList[value] });
  };

  a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  handleFormFieldChange = (value, index, fieldId) => {
    this.props.documentStore.updateTextField(value, index, fieldId);
  };

  ntn = () => {};

  getConfidenceScore = (score) => {
    switch (score) {
      case 'H':
        return Images.greenTick;
      case 'L':
        return Images.scanError;
      default:
        return '';
    }
  };

  getFormFields = (item, index) => {
    const { validationErrors } = this.state;
    // const { classes } = this.props;

    // const { imageFacingSide, currentPage, currentDoc } =
    //   this.props.documentStore;
    switch ('text') {
      case 'text':
        return (
          <div className="fullWidth">
            <InvoiceField
              coords={item.pts && item.pts.slice()}
              ref={(c) => this._areaElement.set(index, c)}
              id={item.aiKey}
              onClick={(e) => this.setCurrentField(e, index, item)}
              fullWidth={true}
              key={item._id}
              label={item.aiKey}
              endAdornment={this.getConfidenceScore(item.confidence)}
              value={item.aiValue}
              onChange={(event) =>
                this.handleFormFieldChange(
                  event.target.value,
                  index,
                  item._id,
                  item.aiValue
                )
              }
              confidence={item.confidence}
            />

            {validationErrors[item.label] && (
              <FormHelperText id={item.label}>
                {validationErrors[item.label]}
              </FormHelperText>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  openDropDown = () => {
    this.props.documentStore.openDocumentDrawer();
  };

  onSubmit = (finalSubmit, autoSave, next) => {
    console.log(finalSubmit, 'finalSubmit');
    if (!next && !autoSave) this.setState({ openSubmitDialog: true });
    else {
      if (this.props.documentStore.currentDoc.docType === 'MEDIASSIST')
        this.props.documentStore.updateMediassistDocument(undefined, true);
      else if (this.props.documentStore.currentDoc.docType === 'WRITERCORP')
        this.props.documentStore.updateWritercorpDocument(undefined, true);
      else this.props.documentStore.updatePosoDocument('REVIEWED', false);
    }
  };

  cancelSubmit = () => {
    this.setState({ openSubmitDialog: false });
  };

  downloadDoc = (doc) => {
    this.props.documentStore.exportXls(doc._id);
  };

  onCancel = () => {
    this.props.documentStore.resetCurrentField();
    this.props.history.push('/report/OCR');
  };

  save = () => {
    const { updatedTabularFields, updatedNonTabularFields } =
      this.props.documentStore;
    if (updatedNonTabularFields.length || updatedTabularFields.length)
      this.onSubmit(false, true, false);
    else return;
  };

  setCurrentField = (e, index, item) => {
    this.setState({
      currentField: index,
    });
    this.props.documentStore.setCurrentField(item, index);

    this.props.documentStore.setCurrentPage(item.page !== null ? item.page : 0);
    this.props.highlight(e, index);
  };

  exportDoc = () => {
    const { currentDoc } = this.props.documentStore;
    let temp = [];
    temp.push(currentDoc._id);
    this.props.dashboardStore.exportXls(temp, 'EXPORTED');
  };

  componentWillUnmount() {
    this.props.removeHighlight(
      undefined,
      this.props.documentStore.currentIndex
    );
    window.addEventListener('resize', null);
  }

  changePage = (type) => {
    const { currentPage, currentDoc } = this.props.documentStore;
    let index = currentDoc.pageArray.findIndex(
      (x) => x._id === currentPage._id
    );
    this.props.resetImage();
    if (type === '-')
      this.props.documentStore.setCurrentImagePoSo(
        currentDoc.pageArray[index - 1],
        index - 1
      );
    else
      this.props.documentStore.setCurrentImagePoSo(
        currentDoc.pageArray[index + 1],
        index + 1
      );
  };

  finalSubmit = () => {
    if (this.props.documentStore.currentDoc.docType === 'MEDIASSIST')
      this.props.documentStore.updateMediassistDocument().then((resp) => {
        if (!this.props.documentStore.updateProgress) this.onCancel();
      });
    else if (this.props.documentStore.currentDoc.docType === 'WRITERCORP')
      this.props.documentStore
        .updateWritercorpDocument(undefined, true)
        .then((resp) => {
          if (!this.props.documentStore.updateProgress) this.onCancel();
        });
    else
      this.props.documentStore.updatePosoDocument().then((resp) => {
        if (!this.props.documentStore.updateProgress) this.onCancel();
      });
  };

  renderStages = () => {
    const { classes } = this.props;
    const { currentDoc } = this.props.documentStore;
    return (
      <div className={classes.stageContainer}>
        <div className={classes.stageName}>
          <ReactSVG
            src={currentDoc.qrScanFlag ? Images.scanDone : Images.scanFailed}
          />
          <span>&nbsp;&nbsp;QR Scan&nbsp;&nbsp;&nbsp;</span>&#8213;
        </div>
        <div className={classes.stageName}>
          <ReactSVG
            src={currentDoc.ocrScanFlag ? Images.scanDone : Images.scanFailed}
          />
          <span>&nbsp;OCR Scan&nbsp;&nbsp;&nbsp;</span>&#8213;
        </div>
        <div className={classes.stageName}>
          <ReactSVG
            src={
              currentDoc.reviewStatus === 'REVIEWED'
                ? Images.scanDone
                : Images.reviewPending
            }
          />
          <span>
            &nbsp;&nbsp;{' '}
            {currentDoc.reviewStatus === 'REVIEWED'
              ? 'Reviewed'
              : 'Review Pending'}
            &nbsp;&nbsp;&nbsp;
          </span>
        </div>
        {/*<div className={classes.stageName}>
                    <ReactSVG src={Images.reviewPending} />
                    <span>&nbsp;&nbsp;Approved</span>
    </div>*/}
      </div>
    );
  };

  renderErrors = () => {
    const { classes } = this.props;
    return (
      <div className={classes.errorContainer}>
        <div className="rowFlex">
          <ReactSVG src={Images.scanError} /> &nbsp;&nbsp;
          <div>QR not detected</div>
        </div>
      </div>
    );
  };

  updateDocStatus = (id, type) => {
    this.props.documentStore.updatePosoDocument('REVIEWED');
  };

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;
    const { currentDoc, reviewProgress, errorProgress } =
      this.props.documentStore;

    return (
      <div
        // className={classes.formContainer}
        className="form-container"
      >
        {/* {this.renderStages()} */}
        <form
          noValidate
          autoComplete="off"
          className={classes.formStyle}
          id="qrFieldForm"
        >
          {activeTab === 'Non-tabular data' ? (
            <IdleTimer
              ref="saveTimer"
              debounce={5000}
              timeout={10000}
              startOnLoad={false}
            >
              <div className={classes.accContainer}>
                {currentDoc.qrData === null ? this.renderErrors() : null}

                {
                  <Accordion
                  // classes={{ root: classes.accRoot }}
                  >
                    <AccordionSummary
                      // classes={{ root: classes.accSummary }}
                      className="red-heading"
                      expandIcon={<img src={down_arrow} alt="down_arrow" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className={classes.accHeading}>Incorrect Fields</div>
                    </AccordionSummary>
                    <AccordionDetails
                    // classes={{ root: classes.accDetails }}
                    >
                      {currentDoc.nonTabularData.map((item, index) => {
                        return (
                          item.confidence === 'L' && (
                            <>
                              <div className="section-card">
                                <div>
                                  <ul>
                                    <li>
                                      <span>{keyStrings[item.aiKey]}</span>
                                    </li>
                                    <li className="flex-center">
                                      <span className="mr-2 red">
                                        {item.aiValue}
                                      </span>
                                      <img
                                        className="cursor"
                                        src={information}
                                        alt="information"
                                        width={16}
                                        onClick={(e) =>
                                          this.setCurrentField(e, index, item)
                                        }
                                      />
                                    </li>
                                  </ul>
                                  <div className="content-grid">
                                    <div className="item">
                                      <span>QR Text</span>
                                      <span className="blue">
                                        {item.qrValue}
                                      </span>
                                    </div>
                                    <div className="item">
                                      <span>GST2A</span>
                                      <span className="blue">
                                        {item.text_zoho_2a}
                                      </span>
                                    </div>
                                    <div className="item">
                                      <span>PR-ERP</span>
                                      <span className="blue">
                                        {item.text_zoho_pr}
                                      </span>
                                    </div>
                                    <div className="item">
                                      <span>E-Way</span>
                                      <span className="black">
                                        {item.text_zoho_eway}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </>
                          )
                        );
                      })}
                      {/* <br />
                      <div className="section-card">
                        <div>
                          <ul>
                            <li>
                              <span>Seller Gstin</span>
                            </li>
                            <li className="flex-center">
                              <span className="mr-2 red">27AADCM4220G1ZL</span>
                              <img
                                src={information}
                                alt="information"
                                width={16}
                              />
                            </li>
                          </ul>
                          <div className="content-grid">
                            <div className="item">
                              <span>QR Text</span>
                              <span className="blue">27AADCM4220G1ZT</span>
                            </div>
                            <div className="item">
                              <span>GST2A</span>
                              <span className="blue">27AADCM4220G1ZT</span>
                            </div>
                            <div className="item">
                              <span>PR-ERP</span>
                              <span className="blue">27AADCM4220G1ZT</span>
                            </div>
                            <div className="item">
                              <span>E-Way</span>
                              <span className="black">27AADCM4220G1ZT</span>
                            </div>
                          </div>
                        </div>
                      </div>  */}

                      {/* <div
                        className={classes.accFormContainer}
                        style={{ height: `calc(${formHeight} - 4vh)` }}
                      >
                        <div className={classes.accForm}>
                          {currentDoc.nonTabularData.map((item, index) => {
                            return this.getFormFields(item, index);
                          })}
                        </div>
                      </div> */}
                    </AccordionDetails>
                  </Accordion>
                }
                {currentDoc.qrData ? (
                  <Accordion
                  // classes={{ root: classes.accRoot }}
                  >
                    <AccordionSummary
                      // classes={{ root: classes.accSummary }}
                      className="green-heading"
                      expandIcon={<img src={down_arrow} alt="down_arrow" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className={classes.accHeading}>Correct Fields</div>
                    </AccordionSummary>
                    <AccordionDetails
                      // classes={{ root: classes.accDetails }}
                      className="correct-details"
                    >
                      {/* <div
                        className={classes.accFormContainer}
                        style={{ height: `calc(${formHeight} - 4vh)` }}
                      >
                        <div className={classes.accForm}>
                          <QRData />
                        </div>
                      </div> */}
                      <ul>
                        {currentDoc.nonTabularData.map(
                          (item, index) =>
                            item.confidence === 'H' && (
                              <li>
                                <span>{keyStrings[item.aiKey]}</span>
                                <div className="flex-center">
                                  <span className={`mr-2 ${'dgreen'}`}>
                                    {item.aiValue}
                                  </span>

                                  <img
                                    className="cursor"
                                    src={tick}
                                    alt="tick"
                                    width={16}
                                    onClick={(e) =>
                                      this.setCurrentField(e, index, item)
                                    }
                                  />
                                </div>
                              </li>
                            )
                        )}
                        {/* {currentDoc.qrData &&
                        Object.keys(currentDoc.qrData).length ? (
                          Object.keys(currentDoc.qrData).map((row, index) => (
                            // <div key={"qrData" + index} className={classes.data}>
                            //   <div className={classes.dataLabel}>
                            //     {row && row.replace(/([a-z])([A-Z])/g, "$1 $2")}{" "}
                            //   </div>
                            //   <div>
                            //     {row === "QR_DETECTED"
                            //       ? currentDoc.qrData[`${row}`] === true
                            //         ? "Yes"
                            //         : "No"
                            //       : currentDoc.qrData[`${row}`]}{" "}
                            //   </div>
                            // </div>
                            <li>
                              <span>
                                {row && row.replace(/([a-z])([A-Z])/g, "$1 $2")}{" "}
                              </span>
                              <div className="flex-center">
                                <span
                                  className={`mr-2 ${
                                    row === "BuyerGstin" || row === "TotInvVal"
                                      ? "dgreen"
                                      : ""
                                  }`}
                                >
                                  {currentDoc.qrData[`${row}`]}
                                </span>
                                {row === "BuyerGstin" || row === "TotInvVal" ? (
                                  <img src={tick} alt="tick" width={16} />
                                ) : (
                                  <img
                                    src={tick_dim}
                                    alt="information"
                                    width={16}
                                  />
                                )}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li className={classes.dataLabel}>
                            No valid QR code detected.
                          </li>
                        )} */}
                      </ul>

                      {/* <ul>
                        <li>
                          <span>Invoice date</span>
                          <div className="flex-center">
                            <span className="mr-2 dgreen">27AADCM4220G1ZL</span>
                            <img src={tick} alt="tick" width={16} />
                          </div>
                        </li>
                        <li>
                          <span>Invoice date</span>
                          <div className="flex-center">
                            <span className="mr-2 black">27AADCM4220G1ZL</span>
                            <img src={tick_dim} alt="information" width={16} />
                          </div>
                        </li>
                        <li>
                          <span>Buyer GSTIN</span>
                          <div className="flex-center">
                            <span className="mr-2 red">27AADCM4220G1ZL</span>
                            <img
                              src={information}
                              alt="information"
                              width={16}
                            />
                          </div>
                        </li>
                      </ul>
                     */}
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                <Accordion
                // classes={{ root: classes.accRoot }}
                >
                  <AccordionSummary
                    // classes={{ root: classes.accSummary }}
                    className="grey-heading"
                    expandIcon={<img src={down_arrow} alt="down_arrow" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={classes.accHeading}>Log History</div>
                  </AccordionSummary>
                  <AccordionDetails
                    // classes={{ root: classes.accDetails }}
                    className="log-history"
                  >
                    {/* <div
                        className={classes.accFormContainer}
                        style={{ height: `calc(${formHeight} - 4vh)` }}
                      >
                        <div className={classes.accForm}>
                          <QRData />
                        </div>
                      </div> */}
                    <ul>
                      <li>
                        <span>{new Date().toLocaleDateString()} - </span>
                        <span>Reviewed Extracted data</span>
                      </li>
                      <li>
                        <span>{new Date().toLocaleDateString()} - </span>
                        <span>Reviewed Extracted data</span>
                      </li>
                      <li>
                        <span>{new Date().toLocaleDateString()} - </span>
                        <span>Reviewed Extracted data</span>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </div>
            </IdleTimer>
          ) : (
            <IdleTimer
              ref="saveTimer"
              //onAction={this.save}
              debounce={1000}
              timeout={10000}
              startOnLoad={false}
            >
              <Scrollbars>
                {currentDoc.pageArray && currentDoc.pageArray.length
                  ? currentDoc.pageArray.map((row, pageIndex) => (
                      <TabularData
                        docType={this.props.documentStore.currentDoc.docType}
                        key={'tabularData' + pageIndex}
                        pageIndex={pageIndex}
                        highlight={this.props.highlightTabularData}
                      />
                    ))
                  : null}
              </Scrollbars>
            </IdleTimer>
          )}
        </form>
        {!this.props.fromDoc && (
          <div className={classes.formSubmitContainer}>
            <SimpleButton
              variant="cancel"
              label="Go Back"
              handleClick={(event) => this.onCancel()}
            />
            {currentDoc.reviewStatus !== 'REVIEWED' ? (
              <SimpleButton
                progress={reviewProgress}
                label="Review"
                handleClick={(event) =>
                  this.updateDocStatus(currentDoc._id, 'REVIEWED')
                }
              />
            ) : null}
            <SimpleButton
              progress={errorProgress}
              label="QR Failed"
              handleClick={(event) =>
                this.updateDocStatus(currentDoc._id, 'ATTENTION')
              }
            />
            {/*<SimpleButton progress={updateProgress} label='Approve' handleClick={(event) => this.onSubmit(true, false, false)} />*/}
          </div>
        )}
        <SubmitDialog
          open={this.state.openSubmitDialog}
          cancelSubmit={this.cancelSubmit}
          submit={this.finalSubmit}
        ></SubmitDialog>
      </div>
    );
  }
}
export default withStyles(documentStyles, { withTheme: true })(
  withRouter(inject('documentStore', 'dashboardStore')(observer(PoSoForm)))
);
